export const followUpTemplates = {
  FIRST_CONTACT: {
    title: 'Penawaran Awal',
    template: `Halo Kak {name},
Perkenalkan saya {agent} dari Katsikat Laundry.
Kami menawarkan jasa laundry dengan kualitas premium:
• Free Pick up & Delivery
• Proses Cuci Higienis
• Pengerjaan Cepat

Tertarik untuk mencoba layanan kami, Kak?`
  },
  FOLLOW_UP: {
    title: 'Follow Up',
    template: `Halo Kak {name},
Bagaimana kabarnya? Saya {agent} dari Katsikat Laundry.
Apakah sudah sempat melihat penawaran kami sebelumnya?
Kami sedang ada promo menarik nih Kak.`
  },
  PROMO: {
    title: 'Penawaran Promo',
    template: `Halo Kak {name},
Saya {agent} dari Katsikat Laundry.
Kami ada PROMO SPESIAL:
• Diskon 20% untuk order pertama
• Free Pick up & Delivery
• Bonus pewangi premium

Mau diambil sekarang cuciannya, Kak?`
  }
};