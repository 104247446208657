import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import SignIn from "./pages/SignIn";
import LogOut from "./pages/LogOut";
import Main from "./components/layout/Main";
import Transaction from "./pages/Transaction";
import Invoice from './pages/component/pdf/Invoice';
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Delivery from './pages/Delivery';
import Treatment from './pages/Treatment';
import Item from './pages/Item';
import Discount from './pages/Discount';
import Employee from './pages/Employee';
import Customer from './pages/Customer';
import Shift from './pages/Shift';
import Absent from './pages/Absent';
import Expense from './pages/Expense';
import CapitalRecord from './pages/CapitalRecord';
// import ProcessingTime from './pages/ProcessingTime';
import BluetoothComponent from './pages/Printer';
import Product from './pages/Product';
import ProductTransaction from './pages/ProductTransaction';
import Collaboration from './pages/Collaboration';
import Business from './pages/Business';
import Permission from './pages/Absent/Permission';
import CustomerHistory from './pages/Customer/CustomerHistory';
import CustomerPattern from './pages/Customer/CustomerPattern';
import Leads from './pages/Leads';
import ShiftDetail from './pages/Shift/ShiftDetail';
import ShiftList from './pages/Shift/ShiftList';

const Routes = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

    useEffect(() => {
        const handleStorageChange = () => {
            setIsAuthenticated(!!localStorage.getItem('token'));
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (
        <div>
            <Switch>
                <Route path="/sign-in">
                    {!isAuthenticated ? (
                        <SignIn onLogin={() => setIsAuthenticated(true)} />
                    ) : (
                        <Redirect to="/transaction" />
                    )}
                </Route>
                <Route path="/logout" exact component={LogOut} />
                <Route path="/invoice" exact component={Invoice} />
                <Route path="/printer" component={BluetoothComponent} />
                {isAuthenticated ? (
                    <>
                        <Main>
                            <Route exact path="/dashboard" component={Dashboard} />
                            <Route exact path="/shift" component={Shift} />
                            <Route exact path="/shift-list" component={ShiftList} />
                            <Route exact path="/transaction" component={Transaction} />
                            <Route exact path="/delivery" component={Delivery} />
                            <Route exact path="/treatment" component={Treatment} />
                            <Route exact path="/item" component={Item} />
                            <Route exact path="/discount" component={Discount} />
                            <Route exact path="/customer" component={Customer} />
                            <Route exact path="/customer-pattern/:customerId" component={CustomerPattern} />
                            <Route exact path="/employee" component={Employee} />
                            <Route exact path="/absent" component={Absent} />
                            <Route exact path="/permissions" component={Permission} />
                            <Route exact path="/expense" component={Expense} />
                            <Route exact path="/product" component={Product} />
                            <Route exact path="/product-transaction" component={ProductTransaction} />
                            <Route exact path="/capital-records" component={CapitalRecord} />
                            {/* <Route exact path="/processing-time" component={ProcessingTime} /> */}
                            <Route exact path="/collaboration" component={Collaboration} />
                            <Route exact path="/business" component={Business} />
                            <Route exact path="/customer-history/:customerId" component={CustomerHistory} />
                            <Route exact path="/leads" component={Leads} />
                            <Route exact path="/shift/:id" component={ShiftDetail} />
                        </Main>
                        <Redirect to="/transaction" />
                    </>
                ) : (
                    <Redirect from="*" to="/sign-in" />
                )}
            </Switch>
        </div>
    );
};

export default Routes;