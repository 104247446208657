import { useEffect, useRef, useState } from 'react';
import { Table, Button, Input, Select, Tag, Space, Modal, Timeline, Avatar, Tooltip, message } from 'antd';
import { PhoneOutlined, CheckCircleFilled, MinusCircleFilled, CloseCircleFilled, QuestionCircleFilled } from '@ant-design/icons';
import { useLeads } from '../hooks/useLeads';
import LeadForm from './LeadForm';
import { statusColors } from '../constants';
import FollowUpPopup from './FollowUpPopup';
import { createFollowUp, fetchLeads } from '../api';
import { useQuery } from '../../../helper/useQuery';

const FollowUpTracker = ({ lead, onFollowUp, currentUser }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const maxFollowUps = 7;
  const currentFollowUps = lead.lead_records || [];

  const handleFollowUpClick = (index) => {
    setSelectedIndex(index);
    setShowPopup(true);
  };

  const handleSubmitFollowUp = async (values) => {
    await onFollowUp(lead.id, values);
    setShowPopup(false);
  };




  

  return (
    <>
      <div className="flex gap-2 items-center">
        {[...Array(maxFollowUps)].map((_, index) => {
          const followUp = currentFollowUps[index];
          const isCompleted = followUp !== undefined;

          // Default styles and components
          let backgroundColor = "bg-gray-200 hover:bg-gray-300";
          let IconComponent = MinusCircleFilled;
          let iconColor = "#666";

          // Determine styles and icons based on status
          if (isCompleted) {
            switch (followUp.status) {
              case "Berhasil":
                backgroundColor = "bg-green-500";
                IconComponent = CheckCircleFilled;
                iconColor = "white";
                break;
              case "Tidak Berminat":
                backgroundColor = "bg-red-500";
                IconComponent = CloseCircleFilled;
                iconColor = "white";
                break;
              case "Tidak Respon":
                backgroundColor = "bg-yellow-500";
                IconComponent = QuestionCircleFilled;
                iconColor = "white";
                break;
              default:
                backgroundColor = "bg-yellow-500";
                IconComponent = QuestionCircleFilled;
                iconColor = "white";
                break;
            }
          }

          return (
            <Tooltip
              key={index}
              title={
                isCompleted ? (
                  <div>
                    <p><strong>Status:</strong> {followUp.status}</p>
                    <p><strong>Oleh:</strong> {followUp.followUpBy?.name}</p>
                    <p><strong>Tanggal:</strong> {new Date(followUp.follow_up_at).toLocaleString()}</p>
                    <p><strong>Catatan:</strong> {followUp.notes}</p>
                  </div>
                ) : (
                  `Follow Up ${index + 1}`
                )
              }
            >
              <div
                className={`w-8 h-8 rounded-full flex items-center justify-center cursor-pointer ${backgroundColor}`}
              >
                <IconComponent style={{ color: iconColor }} />
              </div>
            </Tooltip>
          );
        })}
      </div>

      <FollowUpPopup
        visible={showPopup}
        onCancel={() => setShowPopup(false)}
        onSubmit={handleSubmitFollowUp}
        lead={lead}
        currentUser={currentUser}
      />
    </>
  );
};



const LeadDetails = ({ lead }) => {
  if (!lead) return null;

  return (
    <div className="p-4">
      <div className="mb-4 flex items-center gap-4">
        <Avatar size={64} style={{ backgroundColor: '#1890ff' }}>
          {lead.name[0].toUpperCase()}
        </Avatar>
        <div>
          <h3 className="text-lg font-bold">{lead.name}</h3>
          <p className="text-gray-500">
            <PhoneOutlined className="mr-2" />
            {lead.phone}
          </p>
          <p className="text-gray-500">
            Dibuat oleh: {lead.createdBy?.name} pada {new Date(lead.createdAt).toLocaleString()}
          </p>
        </div>
      </div>

      <Timeline
        items={lead.lead_records?.map(record => ({
          color: statusColors[record.status],
          children: (
            <div>
              <p className="font-bold">{record.status}</p>
              <p>{record.notes}</p>
              <p className="text-sm text-gray-500">
                Oleh: {record.followUpBy?.name} - {new Date(record.follow_up_at).toLocaleString()}
              </p>
            </div>
          )
        })) || []}
      />

      {lead.next_follow_up_date && (
        <div className="mt-4 p-3 bg-blue-50 rounded-lg">
          <p className="font-bold">Follow Up Selanjutnya:</p>
          <p>{new Date(lead.next_follow_up_date).toLocaleString()}</p>
          <p>Oleh: {lead.next_follow_up_by?.name || 'Belum ditentukan'}</p>
        </div>
      )}
    </div>
  );
};

const LeadsList = () => {
  const query = useQuery();
  const [searchText, setSearchText] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  const { leads, loading, createLead, updateLead, deleteLead, addFollowUp, currentUser } = useLeads();

  const handleFollowUp = async (leadId, values) => {
    try {
      await addFollowUp(leadId, values);
      message.success('Follow up berhasil disimpan');
      fetchLeads();
    } catch (error) {
      message.error('Gagal menyimpan follow up');
    }
  };

  // const query = useQuery();
  const prevTab = useRef(null);

  useEffect(() => {
    const activeTab = query.get('tab') || '1';

    // Cek apakah tab berubah dari nilai sebelumnya
    if (activeTab !== prevTab.current) {
      prevTab.current = activeTab; // Update nilai sebelumnya

      if (activeTab === '1') {
        fetchLeads();
      }
    }
  }, [query]);

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <p onClick={() => {
          setSelectedLead(record);
          setShowDetails(true);
        }}>
          {text}
        </p>
      ),
    },
    {
      title: 'Telepon',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Sumber',
      dataIndex: 'source',
      key: 'source',
      render: (source) => (
        <Tag color="blue">{source}</Tag>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={statusColors[status]}>{status}</Tag>
      ),
    },
    {
      title: 'Progress Follow Up',
      key: 'follow_up_tracker',
      render: (_, record) => <FollowUpTracker lead={record} onFollowUp={handleFollowUp} currentUser={currentUser} />,
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button type="link" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => handleDelete(record.id)}>
            Hapus
          </Button>
        </Space>
      ),
    },
  ];

  const handleAddLead = () => {
    setSelectedLead(null);
    setIsModalVisible(true);
  };

  const handleEdit = (lead) => {
    setSelectedLead(lead);
    setIsModalVisible(true);
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: 'Hapus Lead',
      content: 'Apakah Anda yakin ingin menghapus lead ini?',
      onOk: () => deleteLead(id),
    });
  };


  const filteredLeads = leads.filter(lead => {
    const matchesSearch = lead.name.toLowerCase().includes(searchText.toLowerCase()) ||
      lead.phone.includes(searchText);
    const matchesStatus = !statusFilter || lead.status === statusFilter;
    return matchesSearch && matchesStatus;
  });

  return (
    <div>
      <div className="mb-4 flex justify-between">
        <Space>
          <Input.Search
            placeholder="Cari nama atau telepon"
            onSearch={value => setSearchText(value)}
            style={{ width: 300 }}
          />
          <Select
            placeholder="Filter Status"
            allowClear
            onChange={value => setStatusFilter(value)}
            style={{ width: 200 }}
            options={Object.keys(statusColors).map(status => ({
              label: status,
              value: status,
            }))}
          />
        </Space>
        <Button type="primary" onClick={() => handleAddLead()}>
          Tambah Lead
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={filteredLeads}
        loading={loading.leads}
        rowKey="id"
      />

      <LeadForm
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setSelectedLead(null);
        }}
        onSubmit={async (values) => {
          if (selectedLead) {
            await updateLead(selectedLead.id, values);
          } else {
            await createLead(values);
          }
          setIsModalVisible(false);
          setSelectedLead(null);
        }}
        initialValues={selectedLead}
      />

      <Modal
        title="Detail Lead"
        open={showDetails}
        onCancel={() => {
          setShowDetails(false);
          setSelectedLead(null);
        }}
        footer={null}
        width={600}
      >
        <LeadDetails lead={selectedLead} />
      </Modal>
    </div>
  );
};

export default LeadsList; 