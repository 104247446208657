import React, { useEffect, useState } from 'react';
import { Table, Empty, Card, Input, Button, Popover, Pagination, Image, Row, Col, Typography } from 'antd';
import { IoIosAddCircle } from "react-icons/io";
import { fetchData, putData } from '../../helper/api';
import { formatCurrency } from '../../helper/formatCurrency';
import ModalAddCapitalRecords from './ModalAddCapitalRecords.js';
import ActionCapitalRecords from './ActionCapitalRecords';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { useQuery } from '../../helper/useQuery';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import { toast } from 'react-toastify';
import { FaMoneyBillWave } from 'react-icons/fa';

function Index(props) {
    const [dataSource, setDataSource] = useState([]);
    const [trigerFetchData, setTrigerFetchData] = useState(false);

    const location = useLocation();
    const history = useHistory();

    const [openModalAdd, setOpenModalAdd] = useState(false);

    const { Title } = Typography;

    // Pagination & Filter Date
    const query = useQuery();
    const page = query.get('page') || 1;
    const pageSize = query.get('pageSize') || 10;
    const startDate = query.get('startDate') || moment().startOf('month').format('YYYY-MM-DD');
    const endDate = query.get('endDate') || moment().endOf('month').format('YYYY-MM-DD');

    // search
    const [searchValue, setSearchValue] = useState('');

    // PAGINATION
    const [pagination, setPagination] = useState({
        totalItems: 0,
        totalPages: 0,
        currentPage: 1,
        pageSize: 10,
    });

    // OptionData
    const [nameOption, setNameOption] = useState([]);
    const [categoryOption, setCategoryOption] = useState([]);

    const handleOpenModalAdd = () => {
        setOpenModalAdd(true);
    }

    const onSearch = (value) => {
        setSearchValue(value);
    }

    const mappingDataToDataSource = (data) => {
        return data.map((item, index) => {
            return {
                ...item,
                no: index + 1,
                username: item.user.name
            };
        });
    }

    useEffect(() => {
        const searchLocation = location.search;
        const newSearchLocation = searchLocation.replace(/&add_update=\d/, "");
        history.push({
            ...location,
            search: newSearchLocation,
        });
        // Fetch data
        let url = `/capital-records?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}&search=${encodeURIComponent(searchValue)}`;

        fetchData(url)
            .then((res) => {
                const data = res.data.data;
                console.log(data);
                const dataSource = mappingDataToDataSource(data.data);
                setDataSource(dataSource);
                setPagination({
                    ...pagination,
                    totalItems: data.pagination.totalItems,
                    totalPages: data.pagination.totalPages,
                    currentPage: parseInt(data.pagination.currentPage),
                });
            })
            .catch((err) => {
                console.log(err);
            });

        // fetchData(`/capital-records-option`)
        //     .then((res) => {
        //         const data = res.data.data;
        //         setNameOption(data.names);
        //         setCategoryOption(data.categories);
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     }
        //     );


    }, [location.search, trigerFetchData, searchValue])

    const handleEndWork = (id) => {
        putData(`/CapitalRecords/${id}`, { clock_out: moment().format('HH:mm:ss') })
            .then((response) => {
                if (response.status === 200) {
                    setTrigerFetchData(!trigerFetchData);
                    toast.success('CapitalRecords updated successfully');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const column = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Financier',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => (
                <p style={{ color: "black" }}>{moment(record.date).format('DD MMM YYYY')}</p>
            ),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => (
                <p style={{ color: "black" }}>{formatCurrency(parseInt(record.amount))}</p>
            ),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Photos',
            dataIndex: 'photos',
            key: 'photos',
            render: (text, record) => (
                <Image
                    width={100}
                    src={record.photos}
                />
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <ActionCapitalRecords record={record} trigerFetchData={trigerFetchData} setTrigerFetchData={setTrigerFetchData} />
            ),
        },
    ]

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);

    const handleFilterTanggal = (item) => {
        setState([item.selection]);
        const startDateParam = moment(item.selection.startDate).format('YYYY-MM-DD');
        const endDateParam = moment(item.selection.endDate).format('YYYY-MM-DD');

        if (page !== 1) {
            history.push({
                pathname: location.pathname,
                search: `?page=1&pageSize=${pageSize}&startDate=${startDateParam}&endDate=${endDateParam}`
            });
        } else {
            // Mengupdate parameter URL
            history.push({
                pathname: location.pathname,
                search: `?page=${page}&pageSize=${pageSize}&startDate=${startDateParam}&endDate=${endDateParam}`
            });
        }
    };

    const content = (
        <DateRangePicker
            onChange={handleFilterTanggal} // Menggunakan fungsi handleFilterTanggal yang telah dibuat
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
        />
    );

    const handlePageChange = (page, pageSize) => {
        setPagination({
            ...pagination,
            currentPage: page,
            pageSize: pageSize
        });

        history.push({
            pathname: location.pathname,
            search: `?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`
        });
    };

    const handlePageSizeChange = (current, size) => {
        setPagination({
            ...pagination,
            currentPage: current,
            pageSize: size
        });
        history.push({
            pathname: location.pathname,
            search: `?page=${current}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`
        });
    }

    const [financier, setFinancier] = useState([]);

    useEffect(() => {
        fetchData('/total-capitals')
            .then((res) => {
                const data = res.data.data;
                console.log(data);
                let dataBoxList = [];
                data.map((item, index) => {
                    dataBoxList.push({
                        name: item?.user?.name,
                        value: formatCurrency(parseInt(item.total_amount || 0)),
                        icon: <FaMoneyBillWave />,
                    });
                });
                setFinancier(dataBoxList);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [location.search, trigerFetchData, searchValue]);

    return (
        <>
            <Title level={4}>Financier Capital</Title>
            <Row className="rowgap-vbox" gutter={[24, 0]}>
                {financier.map((c, index) => (
                    <Col
                        key={index}
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}
                        xl={8}
                        className="mb-4"
                    >
                        <Card bordered={false} className="criclebox ">
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col xs={18}>
                                        <p className='whitespace-nowrap text-gray-500 font-bold'>{c.name}</p>
                                        <Title level={3}>
                                            {c.value ? c.value : 0}
                                        </Title>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="icon-box flex flex-col items-center justify-center" style={{ fontSize: '20px' }}>{c.icon}</div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
            <ModalAddCapitalRecords nameOption={nameOption} categoryOption={categoryOption} open={openModalAdd} setOpen={setOpenModalAdd} trigerFetchData={trigerFetchData} setTrigerFetchData={setTrigerFetchData} />
            <Card
                bordered={false}
                className="criclebox tablespace mb-4"
                title={
                    <div style={{ display: "flex", gap: "20px" }}>
                        <h4>Capital Records</h4>
                        <Input
                            style={{ width: "200px", fontSize: "12px" }}
                            placeholder="Search Name / Description / Payment Method"
                            size="small"
                            onPressEnter={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch(e.target.value); // Gantilah dengan nama fungsi pencarian Anda
                                }
                            }}
                        />
                    </div>
                }
                extra={
                    <div style={{ display: "flex", gap: "20px" }}>
                        <Popover content={content} placement="leftTop" title="Filter Tanggal" trigger="click">
                            <Button>Entry Date Filter : {moment(startDate).format('DD MMM YYYY')} - {moment(endDate).format('DD MMM YYYY')}</Button>
                        </Popover>
                        <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => handleOpenModalAdd()}>
                            <IoIosAddCircle style={{ fontSize: "20px" }} />
                            Add CapitalRecords
                        </Button>
                    </div>
                }
            >
                <div className="table-responsive">
                    <Table
                        columns={column}
                        dataSource={dataSource}
                        className="ant-border-space"
                        pagination={false}
                        locale={{
                            emptyText: <Empty description="Data not found" />,
                        }}
                    />
                </div>
                <div style={{ padding: "20px", display: 'flex', justifyContent: "flex-end" }}>
                    <Pagination
                        current={pagination.currentPage}
                        total={pagination.totalItems}
                        pageSize={pagination.pageSize}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageSizeChange}
                        showSizeChanger
                        pageSizeOptions={['10', '20', '50', '100']}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    />
                </div>
            </Card>
        </>
    );
}

export default Index;