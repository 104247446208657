import { useEffect, useState } from "react";
import { API_URL, fetchData } from "../../helper/api";
import { Button, InputNumber, Modal } from "antd";
import { toast } from "react-toastify";

const ModalChooseProduct = ({ open, setOpen, formik }) => {
    const [dataProduct, setDataProduct] = useState([]);
    const [quantities, setQuantities] = useState({});

    useEffect(() => {
        fetchData('/products')
            .then((response) => {
                const data = response.data.data;
                setDataProduct(data);
                // Initialize quantities with current formik values
                const initialQuantities = data.reduce((acc, product) => {
                    const existingProduct = formik.values.order_product_details.find(item => item.product_id === product.id);
                    acc[product.id] = existingProduct ? existingProduct.quantity : 0;
                    return acc;
                }, {});
                setQuantities(initialQuantities);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [formik.values.order_product_details]);

    const handleQuantityChange = (productId, value) => {
        setQuantities({
            ...quantities,
            [productId]: value,
        });
    };

    const handleAddAllToCart = () => {
        const newOrderProductDetails = [...formik.values.order_product_details];

        Object.keys(quantities).forEach(productId => {
            const quantity = quantities[productId];
            const product = dataProduct.find(p => p.id === parseInt(productId, 10));

            if (quantity > 0) {
                const existingProductIndex = newOrderProductDetails.findIndex(item => item.product_id === product.id);

                if (existingProductIndex >= 0) {
                    // Update quantity if product exists
                    newOrderProductDetails[existingProductIndex].quantity = quantity;
                } else {
                    // Add new product if it doesn't exist
                    const newProductDetail = {
                        id: 0,
                        order_product_id: 0,
                        product_id: product.id,
                        product: product,
                        discount_id: '',
                        discount: {
                            id: '',
                            code: '',
                            discount: '',
                            description: '',
                        },
                        quantity: quantity,
                        total_price_per_category: product.price * quantity,
                        date: new Date().toISOString().split('T')[0],
                    };
                    newOrderProductDetails.push(newProductDetail);
                }
            } else {
                // Remove product if quantity is 0
                const productIndex = newOrderProductDetails.findIndex(item => item.product_id === product.id);
                if (productIndex >= 0) {
                    newOrderProductDetails.splice(productIndex, 1);
                }
            }
        });

        formik.setFieldValue('order_product_details', newOrderProductDetails);

        toast.success('Produk berhasil ditambahkan ke keranjang');
        setOpen(false);
    };

    return (
        <Modal
            title="Choose Product"
            open={open}
            onCancel={() => setOpen(false)}
            footer={[
                <Button key="add" onClick={handleAddAllToCart}>
                    Tambah
                </Button>,
                <Button key="finish" onClick={() => setOpen(false)}>
                    Selesai
                </Button>
            ]}
        >
            {dataProduct.map((product) => (
                <div key={product.id} className='flex grid grid-cols-5 gap-2 items-center mb-4'>
                    <div className="flex gap-3 items-center col-span-4">
                        <img src={`${API_URL}/${product.images}`} alt='product' className='w-14 h-14 object-cover' />
                        <div>
                            <p className='text-sm font-bold'>{product.name}</p>
                            <p className='text-sm'>Rp. {product.price.toLocaleString('id-ID')}</p>
                        </div>
                    </div>
                    <InputNumber
                        min={0}
                        className="w-full"
                        value={quantities[product.id]}
                        onChange={(value) => handleQuantityChange(product.id, value)}
                    />
                </div>
            ))}
        </Modal>
    );
};

export default ModalChooseProduct;
