import React, { useEffect, useState } from 'react';
import { FaMoneyBillWave } from 'react-icons/fa';
import { formatCurrency } from '../../../helper/formatCurrency';
import { Card, Col, Row, Typography } from 'antd';
import { GrServices } from "react-icons/gr";

function RowBox(props) {
    const { Title } = Typography;
    const { data, aiData } = props;

    const [DataBox, setDataBox] = useState(data);
    const [DataAi, setDataAi] = useState(aiData);

    useEffect(() => {
        setDataBox(data);
    }, [data]);

    useEffect(() => {
        setDataAi(aiData);
    }, [aiData]);


    const dataBoxList = [
        {
            name: `Total Orders (${DataBox?.total_order?.count || 0})`,
            value: formatCurrency(parseInt(DataBox?.total_order?.amount || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: `Total Income (${DataBox?.total_income?.count || 0})`,
            value: formatCurrency(parseInt(DataBox?.total_income?.amount || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: `Expense (${DataBox?.total_expense?.count || 0})`,
            value: formatCurrency(parseInt(DataBox?.total_expense?.amount || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: `Cash (${DataBox?.payment_method?.cash?.count || 0})`,
            value: formatCurrency(parseInt(DataBox?.payment_method?.cash?.amount || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: `Transfer (${DataBox?.payment_method?.bank_transfer?.count || 0})`,
            value: formatCurrency(parseInt(DataBox?.payment_method?.bank_transfer?.amount || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: `Qris (${DataBox?.payment_method?.qris?.count || 0})`,
            value: formatCurrency(parseInt(DataBox?.payment_method?.qris?.amount || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: "Items",
            value: DataBox?.total_item || 0,
            icon: <FaMoneyBillWave />,
        },
        {
            name: "Service",
            value: DataBox?.total_service_order || 0,
            icon: <FaMoneyBillWave />,
        }
    ];

    const dataBoxList2 = [
        {
            name: "New Customer",
            value: DataBox?.customer_overview?.new_customer || 0,
            icon: <FaMoneyBillWave />,
        },
        {
            name: "Repeat Order",
            value: DataBox?.customer_overview?.repeat_customer || 0,
            icon: <FaMoneyBillWave />,
        },
        {
            name: `Online`,
            value: DataBox?.customer_overview?.online_order || 0,
            icon: <FaMoneyBillWave />,
        },
        {
            name: "Offline",
            value: DataBox?.customer_overview?.offline_order || 0,
            icon: <FaMoneyBillWave />,
        },
    ];



    return (
        <div>
            <Row className="rowgap-vbox" gutter={[24, 0]}>
                {dataBoxList.map((c, index) => (
                    <Col
                        key={index}
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}
                        xl={8}
                        className="mb-4"
                    >
                        <Card bordered={false} className="criclebox ">
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col xs={18}>
                                        <span>{c.name}</span>
                                        <Title level={3}>
                                            {c.value ? c.value : 0}
                                        </Title>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="icon-box flex flex-col items-center justify-center" style={{ fontSize: '20px' }}>{c.icon}</div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>

            <Row className="rowgap-vbox" gutter={[24, 0]}>
                {dataBoxList2.map((c, index) => (
                    <Col
                        key={index}
                        xs={24}
                        sm={24}
                        md={12}
                        lg={6}
                        xl={6}
                        className="mb-4"
                    >
                        <Card bordered={false} className="criclebox ">
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col xs={18}>
                                        <span>{c.name}</span>
                                        <Title level={3}>
                                            {c.value ? c.value : 0}
                                        </Title>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="icon-box flex flex-col items-center justify-center" style={{ fontSize: '20px' }}>{c.icon}</div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>

        </div>
    );
}

export default RowBox;