import React, { useEffect, useState } from "react";
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameDay } from "date-fns";
import { fetchData } from "../../helper/api";
import dayjs from "dayjs";
import CalenderViewOrderModal from './CalenderViewOrderModal'; // Modal Component

const CalendarViewOrder = () => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); // Mengontrol modal visibility
    const [transactionData, setTransactionData] = useState([]);

    useEffect(() => {
        fetchData('/calendar-view', {
            params: {
                month: dayjs(currentMonth).format('MM'),
                year: dayjs(currentMonth).format('YYYY'),
            },
        }).then((res) => {
            const data = res.data.data;
            setTransactionData(data.status_report);
        });
    }, [currentMonth]);

    const daysInMonth = eachDayOfInterval({
        start: startOfMonth(currentMonth),
        end: endOfMonth(currentMonth),
    });

    const handlePrevMonth = () => {
        setCurrentMonth((prev) => new Date(prev.getFullYear(), prev.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setCurrentMonth((prev) => new Date(prev.getFullYear(), prev.getMonth() + 1, 1));
    };

    const handleDateClick = (date) => {
        setSelectedDate(date);
        setIsModalOpen(true); // Menampilkan modal ketika salah satu kotak diklik
    };

    const handleModalOk = () => {
        setIsModalOpen(false); // Menutup modal ketika OK ditekan
    };

    const handleModalCancel = () => {
        setIsModalOpen(false); // Menutup modal ketika Cancel ditekan
    };

    const isDoneAndPaid = (transactions) => {
        if (!transactions) return false;

        const { paid, selesai, transaction_count } = transactions;

        return paid === selesai && paid === transaction_count;
    }

    return (
        <div className="mx-auto p-4 border rounded-lg bg-white shadow-md">
            <header className="flex justify-between items-center mb-4">
                <button className="px-3 py-1 bg-gray-200 rounded hover:bg-gray-300" onClick={handlePrevMonth}>&lt;</button>
                <h2 className="text-xl font-bold">{format(currentMonth, "MMMM yyyy")}</h2>
                <button className="px-3 py-1 bg-gray-200 rounded hover:bg-gray-300" onClick={handleNextMonth}>&gt;</button>
            </header>
            <div className="flex flex-col md:flex-row gap-4">
                {/* Bagian Kalender */}
                <div className="w-full">
                    <div className="grid lg:grid-cols-5 md:grid-cols-4 grid-cols-2 gap-4">
                        {daysInMonth.map((day) => {
                            const dateKey = format(day, "yyyy-MM-dd");
                            const transactions = transactionData.find(item => item.date === dateKey);

                            let totalItems = 0;

                            if (transactions) {
                                totalItems = transactions.antrian + transactions.process + transactions.siap_diambil +
                                    transactions.selesai + transactions.batal;
                            }

                            return (
                                <div
                                    key={day}
                                    className={`p-6 border rounded-lg text-center cursor-pointer transition-all duration-300 
                                ${isSameDay(day, selectedDate) ? "bg-blue-500 text-white scale-110 shadow-lg" :
                                            isDoneAndPaid(transactions) ? "bg-green-500 text-white shadow-lg" :
                                                "hover:bg-gray-100"} `}
                                    onClick={() => handleDateClick(day)}
                                >
                                    <span className="block font-bold text-2xl">{format(day, "d")}</span>
                                    {transactions ? (
                                        <div className="text-sm mt-2 space-y-1">
                                            <div className="flex items-center gap-2 text-yellow-500"><span>💼</span>Antrian: {transactions.antrian}</div>
                                            <div className="flex items-center gap-2 text-blue-800"><span>🔄</span>Proses: {transactions.process}</div>
                                            <div className="flex items-center gap-2 text-green-500"><span>✅</span>Siap Antar: {transactions.siap_diambil}</div>
                                            <div className="flex items-center gap-2 text-gray-800"><span>✔️</span>Selesai: {transactions.selesai}</div>
                                            <div className="flex items-center gap-2 text-blue-500"><span>📥</span>DP: {transactions.dp}</div>
                                            <div className="flex items-center gap-2 text-orange-500"><span>💰</span>Paid: {transactions.paid}</div>
                                            <div className="flex items-center gap-2 text-red-500"><span>❌</span>Batal: {transactions.batal}</div>
                                            <div className="text-gray-700 font-bold mt-2">Total Items: {totalItems}</div>
                                        </div>
                                    ) : (
                                        <div className="text-sm text-gray-400 mt-2">Tidak ada transaksi</div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>

            </div>

            {/* Modal */}
            {selectedDate && (
                <CalenderViewOrderModal
                    isModalOpen={isModalOpen}
                    handleOk={handleModalOk}
                    handleCancel={handleModalCancel}
                    data={transactionData.find(item => item.date === format(selectedDate, "yyyy-MM-dd")) || {}}
                />
            )}
        </div>
    );
};

export default CalendarViewOrder;
