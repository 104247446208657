import { useState, useEffect } from 'react';
import { message } from 'antd';
import { fetchFollowUps, createFollowUp as createFollowUpApi } from '../api';

export const useFollowUps = () => {
  const [followUps, setFollowUps] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadFollowUps = async () => {
    try {
      setLoading(true);
      const response = await fetchFollowUps();
      setFollowUps(response.data);
    } catch (error) {
      message.error('Gagal memuat data follow up');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadFollowUps();
  }, []);

  const createFollowUp = async (leadId, values) => {
    try {
      await createFollowUpApi(leadId, values);
      message.success('Follow up berhasil ditambahkan');
      loadFollowUps();
    } catch (error) {
      message.error(error.response?.data?.error?.message || 'Gagal menambahkan follow up');
      throw error;
    }
  };

  return {
    followUps,
    loading,
    createFollowUp,
    refreshFollowUps: loadFollowUps
  };
}; 