import React, { useState } from 'react';

function BluetoothComponent() {
  const [printer, setPrinter] = useState(null);

  const connectToBluetoothPrinter = async () => {
    try {
      const options = {
        filters: [{ name: 'RPP02' }], // Sesuaikan dengan nama printer Anda
        optionalServices: ['e7810a71-73ae-499d-8c15-faa9aef0c3f2'], // Ganti dengan UUID layanan Bluetooth printer Anda
      };

      const device = await navigator.bluetooth.requestDevice(options);
      const server = await device.gatt.connect();
      const service = await server.getPrimaryService('e7810a71-73ae-499d-8c15-faa9aef0c3f2'); // Ganti dengan UUID layanan Bluetooth printer Anda
      const characteristic = await service.getCharacteristic('bef8d6c9-9c21-4c9e-b632-bd58c1009f9f'); // Ganti dengan UUID karakteristik Bluetooth printer Anda

      setPrinter(characteristic);
    } catch (error) {
      console.error('Failed to connect to Bluetooth printer:', error);
    }
  };

  const printData = async () => {
    if (!printer) {
      console.error('Printer is not connected');
      return;
    }

    try {
      const header = `
Katsikat
Jl Grindulu No 24, Bunulrejo, Kec. Blimbing, Kota Malang, Jawa Timur.
0877-9545-2475
--------------------------------
KAT323023023923
30-Apr-2024 15.31

Pelanggan : Jodik
Alamat : Jl. Kaliurang KM 5,5
--------------------------------
`;
  
const orders = `
Shoes - Deep Clean - Regular
1x 40.000                 40.000
(diskon 10%)              -5.000     
Catatan:
Sepatu aman

Bag Clean - Regular
1x 30.000                 30.000
Catatan:
Tas aman

--------------------------------
Total (Belum Lunas)       65.000
--------------------------------
`;
  
      const footer1 = `
Tanggal Masuk 
30-Apr-2024 15.31

Estimasi Selesai 
31-Apr-2024 15.31

[Barcode]

Terima kasih telah mempercayakan Katsikat sebagai jasa cuci terbaik.
`;
  
      const encoder = new TextEncoder();
  
      const clearData = async () => {
          await printer.writeValue(encoder.encode('')); // Mengirim nilai kosong ke printer untuk membersihkan data sebelumnya
      };
  
      const printData = async (data) => {
          await clearData(); // Membersihkan data sebelumnya sebelum mencetak
          await printer.writeValue(encoder.encode(data)); // Mengirim data baru ke printer
      };
  
      await printData(header);
      // await printData(orders);
      // await printData(footer1);
  
  } catch (error) {
      console.error('Failed to print:', error);
  }
  



  };

  const printDataKosong = async () => {
    if (!printer) {
      console.error('Printer is not connected');
      return;
    }

    try {
      const data = "test"; // Data yang akan dicetak

      printer.writeValue(new TextEncoder().encode(data))
        .then(() => console.log('Data sent to printer'))
        .catch(error => console.error('Failed to send data to printer:', error));
    } catch (error) {
      console.error('Failed to print:', error);
    }
  }


  return (
    <div className='flex flex-col'>
      <button onClick={connectToBluetoothPrinter}>Connect to Printer</button>
      <button onClick={printData}>Print</button>
      <button onClick={printDataKosong}>Print Kosong</button>
    </div>
  );
}

export default BluetoothComponent;
