import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchData } from '../../../helper/api';

const TransactionContext = createContext();

export function TransactionProvider({ children }) {
    const [getProfile, setGetProfile] = useState(null);
    const [discountData, setDiscountData] = useState([]);
    const [OptionDiscount, setOptionDiscount] = useState([]);
    const [treatmentData, setTreatmentData] = useState([]);
    const [OptionTreatment, setOptionTreatment] = useState([]);
    const [itemData, setItemData] = useState([]);
    const [OptionItem, setOptionItem] = useState([]);
    const [financeAccounts, setFinanceAccounts] = useState([]);
    const [DataCustomerOption, setDataCustomerOption] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                setIsLoading(true);
                
                // Fetch semua data secara parallel menggunakan Promise.all
                const [userResponse, discountResponse, treatmentResponse, itemResponse, financeAccountsResponse, customerResponse] = await Promise.all([
                    fetchData('/get-profile'),
                    fetchData('/discounts'),
                    fetchData('/treatments'),
                    fetchData('/items'),
                    fetchData('/finance-accounts'),
                    fetchData('/customers')
                ]);

             

                // Set user data
                setGetProfile(userResponse.data.data.user);
                setDiscountData(discountResponse.data.data);
                setTreatmentData(treatmentResponse.data.data);
                setItemData(itemResponse.data.data);
                setFinanceAccounts(financeAccountsResponse.data.data);
                setDataCustomerOption(customerResponse.data.data);
                // Set discount options
                const discountOptions = discountResponse.data.data.map(item => ({
                    label: item.name,
                    value: item.id,
                    ...item,
                }));
                setOptionDiscount(discountOptions);

                // Set treatment options
                const treatmentOptions = treatmentResponse.data.data.map(item => ({
                    label: item.name,
                    value: item.id,
                    ...item,
                }));
                setOptionTreatment(treatmentOptions);

                // Set item options
                const itemOptions = itemResponse.data.data.map(item => ({
                    label: `${item.brand} - ${item.name} - ${item.color}`,
                    value: item.id,
                    ...item,
                }));
                setOptionItem(itemOptions);

                // Set customer options
                const customerOptions = customerResponse.data.data.map(item => ({
                    id: item.id,
                    name: item.name,
                    phone: item.phone,
                    ...item,
                }));
                setDataCustomerOption(customerOptions);

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        
        fetchAllData();
    }, []);

    const loadItemData = async () => {
        const itemResponse = await fetchData('/items');
        setItemData(itemResponse.data.data);
        setOptionItem(itemResponse.data.data.map(item => ({
            label: `${item.brand} - ${item.name} - ${item.color}`,
            value: item.id,
            ...item,
        })));
    };

    const loadCustomerData = async () => {
        const customerResponse = await fetchData('/customers');
        setDataCustomerOption(customerResponse.data.data);
    };



    const value = {
        getProfile,
        OptionDiscount,
        OptionTreatment,
        OptionItem,
        discountData,
        treatmentData,
        itemData,
        financeAccounts,
        DataCustomerOption,
        loadItemData,
        loadCustomerData,
        isLoading
    };

    return (
        <TransactionContext.Provider value={value}>
            {children}
        </TransactionContext.Provider>
    );
}

export function useTransaction() {
    const context = useContext(TransactionContext);
    if (context === undefined) {
        throw new Error('useTransaction must be used within a TransactionProvider');
    }
    return context;
}