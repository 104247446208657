import { Form, Input, Select, Modal } from 'antd';
import { useEffect, useState } from 'react';

const FollowUpForm = ({ visible, onCancel, onSubmit, lead }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const statusOptions = [
    { label: 'Berhasil', value: 'Berhasil' },
    { label: 'Tidak Berminat', value: 'Tidak Berminat' },
    { label: 'Tidak Respon', value: 'Tidak Respon' }
  ];

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(lead);
    }
  }, [visible, lead, form]);

  return (
    <Modal
      title={`Follow Up - ${lead?.name}`}
      open={visible}
      onCancel={onCancel}
      onOk={() => form.submit()}
      confirmLoading={loading}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={async (values) => {
          setLoading(true);
          try {
            await onSubmit(values);
            form.resetFields();
          } finally {
            setLoading(false);
          }
        }}
      >
        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: 'Status harus dipilih' }]}
        >
          <Select options={statusOptions} />
        </Form.Item>

        <Form.Item
          name="notes"
          label="Catatan Follow Up"
          rules={[{ required: true, message: 'Catatan harus diisi' }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FollowUpForm; 