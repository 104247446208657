import React, { useEffect, useState } from 'react';
import { DatePicker, Card, Table, Empty } from 'antd';
import 'antd/dist/antd.css';
import dayjs from 'dayjs';
import { fetchData } from '../../../helper/api';
import { formatCurrency } from '../../../helper/formatCurrency';
import { Popover, Button } from 'antd';
import { FaEye } from "react-icons/fa";

const { MonthPicker } = DatePicker;

function Index(props) {
    const [dataOperasional, setDataOperasional] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(dayjs());
    const [summary, setSummary] = useState([]);
    const handleMonthChange = (date, dateString) => {
        if (date) {
            setSelectedMonth(date.toISOString());
        } else {
            console.error("Invalid date selected");
        }
    };


    const renderDetail = (text) => {
        return (
            <div className='flex flex-col gap-4'>
                {text.antrian_data?.map((item, index) => (
                    <div>
                        <p>Nama : {item.customer.name}</p>
                    </div>
                ))}
            </div>
        );
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text) => <div>
                {text === 'Total' ? <p className='font-bold text-black'>{text}</p> : dayjs(text).format('dddd, DD/MM/YYYY')}
            </div>,
        },
        {
            title: 'Antrian',
            key: 'antrian',
            render: (text) => 
            <>
                 <span>{text.antrian}</span>
                <Popover content={renderDetail(text)} title="Detail">
                    <Button className='scale-75' type="link" icon={<FaEye />} />
                </Popover>
            </>,
        },
        {
            title: 'Process',
            dataIndex: 'process',
            key: 'process',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Pengeringan',
            dataIndex: 'pengeringan',
            key: 'pengeringan',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Siap Diambil',
            dataIndex: 'siap_diambil',
            key: 'siap_diambil',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Selesai',
            dataIndex: 'selesai',
            key: 'selesai',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Batal',
            dataIndex: 'batal',
            key: 'batal',
            render: (text) => <span>{text}</span>,
        }
    ];

    useEffect(() => {
        fetchData('/genereteDashboardOperasionalReport', {
            params: {
                month: dayjs(selectedMonth).format('MM'),
                year: dayjs(selectedMonth).format('YYYY'),
            },
        }).then((res) => {
            const data = res.data.data;
            setDataOperasional(data.status_report);
            setSummary(data?.summarized_data);
        });
    }, [selectedMonth]);

    return (
        <div>
            <div className=''>
                <MonthPicker
                    className='rounded-lg w-60'
                    onChange={handleMonthChange}
                    placeholder="Select month"
                    format="MMMM YYYY"
                    picker="month"
                    defaultValue={dayjs()}
                />
            </div>
            <div className='mt-5 p-10 bg-white rounded-lg shadow-lg mb-10'>
                <div className='flex justify-between'>
                    <div>
                        <p> Total Items: </p>
                        <p> Total Antrian: </p>
                        <p> Total Process: </p>
                        <p> Total Pengeringan: </p>
                        <p> Total Siap Diambil: </p>
                        <p> Total Selesai: </p>
                        <p> Total Batal: </p>
                    </div>
                    <p className='font-bold'>
                        <p> {summary?.totalItems}</p>
                        <p> {summary?.totalAntrian}</p>
                        <p> {summary?.totalProcess}</p>
                        <p> {summary?.totalPengeringan}</p>
                        <p> {summary?.totalSiapDiambil}</p>
                        <p> {summary?.totalSelesai}</p>
                        <p> {summary?.totalBatal}</p>
                    </p>
                </div>

            </div>
            <Card className='mt-5 mb-10'>
                <div className="table-responsive">
                    <Table
                        columns={columns}
                        dataSource={dataOperasional}
                        pagination={false}
                        // footer={() =>
                        //     <div className="flex gap-3 w-full">
                        //         <div className="font-semibold min-w-[180px] mr-[40px]">Total</div>
                        //         <div className='flex w-full'>
                        //             <p className="font-semibold min-w-[60px] w-100 px-[20px]">{formatCurrency(parseInt(dataOperasional?.total?.revenue))}</p>
                        //             <p className="font-semibold min-w-[60px] w-100 px-[20px]">{formatCurrency(parseInt(dataOperasional?.total?.paid))}</p>
                        //             <p className="font-semibold min-w-[60px] w-100 px-[20px]">{formatCurrency(parseInt(dataOperasional?.total?.unpaid))}</p>
                        //             <p className="font-semibold min-w-[60px] w-100 px-[20px]">{formatCurrency(parseInt(dataOperasional?.total?.income))}</p>
                        //             <p className="font-semibold min-w-[60px] w-100 px-[20px]">{formatCurrency(parseInt(dataOperasional?.total?.expense))}</p>
                        //             <p className="font-semibold min-w-[60px] w-100 px-[20px]">{formatCurrency(parseInt(dataOperasional?.total?.capital_in))}</p>
                        //         </div>
                        //     </div>
                        // }
                        className="ant-border-space"
                        locale={{
                            emptyText: <Empty description="Data not found" />,
                        }}
                    />
                </div>
            </Card>
           
        </div>
    );
}

export default Index;