import React, { useEffect } from 'react';
import { Modal, Form, Input, Button, InputNumber, Select, DatePicker, Card } from 'antd';
import { useFormik } from 'formik';
import { fetchData, postData, putData } from '../../helper/api';
import { SELECT_DELIVERY_DETAIL_OPTION, SELECT_DELIVERY_OPTION } from './helper';
import { set } from 'date-fns';
import dayjs from 'dayjs';
import { formatCurrency } from '../../helper/formatCurrency';
import AddDeliveryDetail from './AddDeliveryDetail';
import CustomerInfo from '../component/CustomerInfo';
import CardItemService from '../Transaction/CardItemService';
import { MdDelete } from 'react-icons/md';
import { EditOutlined } from '@ant-design/icons';
import { AllDataToFormikDataDeliveryData, FormikDataToPostDeliveryData, FormikDataToPutDeliveryData } from '../../models/deliveryData';
import { toast } from 'react-toastify';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';

const { Option } = Select;

function ModalAddDelivery(props) {
    const { open, setOpen, initialData } = props
    const location = useLocation();
    const history = useHistory();   

    const [dataUserOption, setDataUserOption] = React.useState([])
    const [dataOrderOption, setDataOrderOption] = React.useState([])
    const [IndexDeliveryDetail, setIndexDeliveryDetail] = React.useState(0)

    const [selectOptionDelivery, setSelectOptionDelivery] = React.useState(SELECT_DELIVERY_OPTION.DELIVERY)
    const [selectDeliveryDetailOption, setSelectDeliveryDetailOption] = React.useState(SELECT_DELIVERY_DETAIL_OPTION.INDEX)

    const formik = useFormik({
        initialValues: {
            id: 0,
            users_id: null,
            quantity: 0,
            date: null,
            total_distance: 0,
            total_cost: 0,
            delivery_details: [],
        },
        onSubmit: values => {
            // Handle form submission logic here
            if (initialData) {
                const newDeliveryData = FormikDataToPutDeliveryData(values)
                putData(`/deliveries/${initialData.id}`, newDeliveryData)
                    .then((res) => {
                        console.log(res)
                        if (res.status === 200) {
                            toast.success('Delivery updated successfully');
                            setOpen(false);
                        }
                        const newLocation = {
                            ...location,
                            search: location.search + `&update=${initialData.id}`,
                        };
                        history.push(newLocation);
                        formik.resetForm(); 
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                const newDeliveryData = FormikDataToPostDeliveryData(values)
                postData('/deliveries', newDeliveryData)
                    .then((res) => {
                        console.log(res)
                        if (res.status === 201) {
                            toast.success('Delivery added successfully');
                            setOpen(false);
                        }
                        const newLocation = {
                            ...location,
                            search: location.search + `&add=${res.data.data.id}`,
                        };
                        history.push(newLocation);
                        formik.resetForm();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    });

    React.useEffect(() => {
        if (initialData) {
            const mappingInitialData = AllDataToFormikDataDeliveryData(initialData)
            formik.setValues(mappingInitialData)
        }
    }, [initialData])

    React.useEffect(() => {
        // Fetch data api user
        fetchData('/users')
            .then((res) => {
                const data = res.data.data;
                data.map((item) => (
                    {
                        ...item,
                        value: item.id,
                        label: item.name
                    }))
                setDataUserOption(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [])

    React.useEffect(() => {
    }, [formik.values])

    const handleAddDelivery = () => {
        formik.setFieldValue(`delivery_details[${formik.values.delivery_details.length}].delivery_id`, initialData?.id || 0)
        setIndexDeliveryDetail(formik.values.delivery_details.length)
        setSelectDeliveryDetailOption(SELECT_DELIVERY_DETAIL_OPTION.ADD);
    }

    const handleEdit = (index) => {
        setIndexDeliveryDetail(index)
        setSelectDeliveryDetailOption(SELECT_DELIVERY_DETAIL_OPTION.ADD);
    }

    const handleDelete = (index) => {
        formik.setFieldValue(
            'delivery_details',
            formik.values.delivery_details.filter((item, i) => i !== index)
        );
    }

    return (
        <div>
            <Modal
                title="Add Delivery"
                open={open}
                onClose={() => setOpen(false)}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                footer={null}
            >
                {selectOptionDelivery === SELECT_DELIVERY_OPTION.DELIVERY &&
                    <Form layout="vertical" onSubmit={formik.handleSubmit}>
                        <Form.Item label="Courier">
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a Courier"
                                optionFilterProp="children"
                                value={formik.values.users_id}
                                onChange={(value, option) => {
                                    formik.setFieldValue("users_id", value)
                                    formik.setFieldValue("users", option.item)
                                }}
                            >
                                {dataUserOption.map((item) => {
                                    return (
                                        <Option item={item} value={item.id}>{item.name}</Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Quantity">
                            <Input
                                name="quantity"
                                type='number'
                                value={formik.values.quantity}
                                onChange={(event) => formik.setFieldValue('quantity', event.target.value)}
                            />
                        </Form.Item>

                        <Form.Item label="Date">
                            <DatePicker
                                name='date'
                                style={{ width: '100%' }}
                                format={"dddd, DD MMMM YYYY"}
                                onChange={(value) => formik.setFieldValue('date', value)}
                                value={formik.values.date ? moment(formik.values.date) : null}
                            />
                        </Form.Item>

                        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
                            <div className='flex gap-2'>
                            <Button onClick={() => setOpen(false)} style={{ marginRight: '10px' }}>
                                Cancel
                            </Button>
                            <Button className="items-center flex justify-center text-white bg-blue-400" htmlType="submit" onClick={() => setSelectOptionDelivery(SELECT_DELIVERY_OPTION.DELIVERY_DETAIL)}>
                                Next
                            </Button>
                            </div>
                        </Form.Item>
                    </Form>
                }

                {selectOptionDelivery === SELECT_DELIVERY_OPTION.DELIVERY_DETAIL &&
                    <div>
                        {selectDeliveryDetailOption === SELECT_DELIVERY_DETAIL_OPTION.INDEX &&
                            <>
                                <Button onClick={() => handleAddDelivery()} style={{ marginBottom: '10px' }}>
                                    Add Delivery Detail
                                </Button>
                                {formik.values.delivery_details.map((item, index) => {
                                    return (
                                        <Card
                                            actions={[
                                                <EditOutlined key="edit" style={{ color: 'blue', fontSize: '20px' }} onClick={() => handleEdit(index)} />,
                                                <MdDelete key="delete" style={{ color: 'red', fontSize: '20px' }} onClick={() => handleDelete(index)} />,
                                            ]}
                                            key={index} style={{ padding: '8px', width: "100%", margin: '5px' }}>
                                            <div style={{ display: "flex", gap: '5px', justifyContent: "space-between", padding: '2px', color: "black", alignItems: 'center' }}>
                                                <CustomerInfo record={item?.order} />
                                                <div>
                                                    <label style={{ fontSize: '14px', fontWeight: 'bold', color: "black" }}>{item?.type}</label>
                                                    <div>{item?.distance} Km</div>
                                                    <div>{formatCurrency(parseInt(item?.cost || 0))}</div>
                                                </div>
                                            </div>
                                            <hr />
                                            <CardItemService record={item?.order} />
                                        </Card>
                                    )
                                })}
                                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: '10px' }}>
                                    <Button onClick={() => setSelectOptionDelivery(SELECT_DELIVERY_OPTION.DELIVERY)} style={{ marginRight: '10px' }}>
                                        Back
                                    </Button>
                                    <Button className="items-center flex justify-center text-white bg-blue-400" htmlType="submit" onClick={() => formik.handleSubmit()}>
                                        {initialData ? 'Update' : 'Save'}
                                    </Button>
                                </div>
                            </>
                        }
                        {selectDeliveryDetailOption === SELECT_DELIVERY_DETAIL_OPTION.ADD &&
                            <AddDeliveryDetail
                                formik={formik}
                                index={IndexDeliveryDetail}
                                setSelectDeliveryDetailOption={setSelectDeliveryDetailOption}
                            />
                        }

                    </div>
                }
            </Modal>
        </div>
    );
}

export default ModalAddDelivery;