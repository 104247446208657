import dayjs from "dayjs";

export const FormikDataToPostDeliveryData = (data) => {
    return {
        users_id: data.users_id,
        quantity: data.quantity,
        date: data.date,
        total_distance: data.delivery_details.reduce((acc, item) => acc + parseInt(item.distance), 0),
        total_cost: data.delivery_details.reduce((acc, item) => acc + parseInt(item.cost), 0),
        delivery_details: data.delivery_details.map((item) => {
            return {
                order_id: item.order.id,
                delivery_id: 0,
                address: item.address,
                date: data.date,
                type: item.type,
                photo_url: null,
                description: item.description,
                distance: item.distance,
                cost: item.cost,
            }
        }),
    };
}

export const FormikDataToPutDeliveryData = (data) => {
    return {
        id: data.id,
        users_id: data.users_id,
        quantity: data.quantity,
        date: data.date,
        total_distance: data.delivery_details.reduce((acc, item) => acc + parseInt(item.distance), 0),
        total_cost: data.delivery_details.reduce((acc, item) => acc + parseInt(item.cost), 0),
        delivery_details: data.delivery_details.map((item) => {
            return {
                id: item.id || 0,
                order_id: item.order.id,
                delivery_id: item.delivery_id,
                address: item.address,
                date: data.date,
                type: item.type,
                photo_url: null,
                description: item.description,
                distance: item.distance,
                cost: item.cost,
            }
        }),
    };
}

export const AllDataToFormikDataDeliveryData = (data) => {
    return {
        id: data.id,
        users_id: data.users_id,
        quantity: data.quantity,
        date: dayjs(data.date).format('dddd, DD MMMM YYYY'),
        total_distance: data.total_distance,
        total_cost: data.total_cost,
        delivery_details: data.delivery_details.map((item) => {
            return {
                id: item.id,
                order: item.order,
                order_id: item.order.id,
                delivery_id: item.delivery_id,
                address: item.address,
                date: data.date,
                type: item.type,
                photo_url: item.photo_url,
                description: item.description,
                distance: item.distance,
                cost: item.cost,
            }
        }),
    };
}