import React, { useState } from "react";
import { formatCurrency } from "../../helper/formatCurrency";
import { FaMoneyBillWave } from "react-icons/fa";
import { BsBank2 } from "react-icons/bs";
import { SiContactlesspayment } from "react-icons/si";
import moment from "moment";
import { putData } from "../../helper/api";
import { toast } from "react-toastify";
import { Card, Typography, Button, Modal, Input, Tabs, List, Avatar, Tag, Statistic } from "antd";
import Expenses from "./Expenses";
import { useHistory } from 'react-router-dom'
import { useGeneral } from "../../contexts/GeneralContext";

const { Title, Text } = Typography;

function ShiftDashboard({ dataShift, setShiftDashboardOpen, trigerFetchData, setTrigerFetchData }) {

    const { getProfile } = useGeneral()
    const [openModalEndShift, setOpenModalEndShift] = useState(false);
    const [cashInDrawer, setCashInDrawer] = useState(0);
    const history = useHistory()

    const handleEndShift = () => {
        putData(`end-shifts/${dataShift.id}`, {
            cash_actual: parseInt(cashInDrawer),
            expense: parseInt(dataShift.total_expenses)
        })
            .then(() => {
                setOpenModalEndShift(false);
                setShiftDashboardOpen(false);
                toast.success("Shift Ended");
            })
            .catch((error) => {
                toast.error("Failed to end shift");
            });
    };

    const PaymentMethodCard = ({ title, icon, amount, count, color }) => (
        <Card className="mb-4 hover:shadow-lg transition-shadow">
            <Statistic
                title={
                    <div className="flex items-center gap-2">
                        {icon}
                        <span>{title}</span>
                    </div>
                }
                value={formatCurrency(amount)}
                suffix={<Tag color={color}>{count} transactions</Tag>}
            />
        </Card>
    );

    const items = [
        {
            key: 'payments',
            label: 'Payments',
            children: (
                <div className="space-y-4">
                    <PaymentMethodCard
                        title="Cash"
                        icon={<FaMoneyBillWave className="text-green-500" />}
                        amount={dataShift.payment_methods.cash.amount}
                        count={dataShift.payment_methods.cash.count}
                        color="green"
                    />
                    <PaymentMethodCard
                        title="Bank Transfer"
                        icon={<BsBank2 className="text-blue-500" />}
                        amount={dataShift.payment_methods.bank_transfer.amount}
                        count={dataShift.payment_methods.bank_transfer.count}
                        color="blue"
                    />
                    <PaymentMethodCard
                        title="QRIS"
                        icon={<SiContactlesspayment className="text-purple-500" />}
                        amount={dataShift.payment_methods.qris.amount}
                        count={dataShift.payment_methods.qris.count}
                        color="purple"
                    />

                    <Card title="Transaction Details" className="mt-4">
                        <List
                            dataSource={[
                                ...dataShift.payment_methods.cash.transactions,
                                ...dataShift.payment_methods.bank_transfer.transactions,
                                ...dataShift.payment_methods.qris.transactions
                            ].sort((a, b) => new Date(b.time) - new Date(a.time))}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar icon={
                                                item.method === 'cash' ? <FaMoneyBillWave /> :
                                                    item.method === 'bank_transfer' ? <BsBank2 /> :
                                                        <SiContactlesspayment />
                                            } />
                                        }
                                        title={item.customer}
                                        description={moment(item.time).format('HH:mm:ss')}
                                    />
                                    <div>
                                        <Tag color={
                                            item.method === 'cash' ? 'green' :
                                                item.method === 'bank_transfer' ? 'blue' :
                                                    'purple'
                                        }>
                                            {item.method}
                                        </Tag>
                                        <Text strong>{formatCurrency(item.amount)}</Text>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </Card>
                </div>
            )
        },
        {
            key: 'summary',
            label: 'Summary',
            children: (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Card>
                        <Statistic title="Total Revenue" value={formatCurrency(dataShift.total_revenue)} />
                    </Card>
                    <Card>
                        <Statistic title="Total Expenses" value={formatCurrency(dataShift.total_expenses)} />
                    </Card>
                    <Card>
                        <Statistic title="Total Services" value={dataShift.service} />
                    </Card>
                    <Card>
                        <Statistic title="Total Items" value={dataShift.item} />
                    </Card>
                </div>
            )
        },
        {
            key: 'expenses',
            label: 'Expenses',
            children: <Expenses
                shift_id={dataShift.id}
                user_id={dataShift.employee_shift?.id}
                date={moment(dataShift.start_time).format('YYYY-MM-DD')}
                trigerFetchData={trigerFetchData}
                setTrigerFetchData={setTrigerFetchData}
            />
        }
    ];

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-6">
                <div>
                    <Title level={4}>
                        Shift {moment(dataShift.start_time).format('DD MMMM YYYY')}
                    </Title>
                    <Text type="secondary">
                        Started by {dataShift.employee_shift?.name} at {moment(dataShift.start_time).format('HH:mm')}
                    </Text>
                </div>
                <div className="flex gap-2">
                    {getProfile?.role === 'superadmin' ? (
                        <Button
                            type="outlined"
                            onClick={() => history.push('/shift-list')}
                            className="bg-white text-black"
                        >
                            Shift List
                        </Button>
                    ) : (<></>)}
                    <Button
                        type="primary"
                        onClick={() => setOpenModalEndShift(true)}
                        className="bg-blue-500 text-white"
                    >
                        End Shift
                    </Button>

                </div>
            </div>

            <Tabs items={items} />

            <Modal
                title="End Shift"
                open={openModalEndShift}
                onCancel={() => setOpenModalEndShift(false)}
                footer={[
                    <Button key="cancel" onClick={() => setOpenModalEndShift(false)}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleEndShift}
                        className="bg-blue-500"
                    >
                        End Shift
                    </Button>
                ]}
            >
                <div className="space-y-4">
                    <div className="flex justify-between">
                        <Text>Cash Initial:</Text>
                        <Text strong>{formatCurrency(dataShift.cash_initial)}</Text>
                    </div>
                    <div className="flex justify-between">
                        <Text>Cash Expected:</Text>
                        <Text strong>{formatCurrency(dataShift.cash_expected)}</Text>
                    </div>
                    <div className="flex justify-between">
                        <Text>Total Expenses:</Text>
                        <Text strong>{formatCurrency(parseInt(dataShift.total_expenses))}</Text>
                    </div>
                    <div className="flex justify-between font-bold">
                        <Text>Total Cash:</Text>
                        <Text>{formatCurrency(
                            parseFloat(dataShift.cash_initial) +
                            parseFloat(dataShift.cash_expected) -
                            parseFloat(dataShift.total_expenses)
                        )}</Text>
                    </div>

                    <div className="mt-6">
                        <Text strong>Enter Cash in Drawer</Text>
                        <Input
                            className="mt-2"
                            placeholder="Enter amount"
                            value={formatCurrency(cashInDrawer)}
                            onChange={(e) => {
                                const value = e.target.value.replace(/[^\d]/g, '');
                                setCashInDrawer(parseInt(value) || 0);
                            }}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ShiftDashboard;