import React, { useEffect, useState } from 'react';
import {
    Button,
    Modal
} from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import ModalSalesOrder from './ModalSalesOrder';
import { deleteData } from '../../helper/api';
import { useHistory, useLocation } from 'react-router-dom';
import ModalAddTransaction from './ModalAddTransaction';
import DeleteButtonWithValidation from '../../components/layout/DeleteButtonWithValidation';
import { updatePayment } from './helper';
import { useGeneral } from '../../contexts/GeneralContext';
import { toast } from 'react-toastify';

function ActionSalesOrder(props) {
    const { record } = props
    const { getProfile, getShiftNow } = useGeneral();
    const location = useLocation();
    const history = useHistory();

    const [open, setOpen] = React.useState(false)

    const [openModalDelete, setOpenModalDelete] = React.useState(false)
    const [openModalTransaction, setOpenModalTransaction] = React.useState(false)
    const [recordData, setRecordData] = React.useState(record)
    const [userData, setUserData] = useState(getProfile);
    const [shiftNow, setShiftNow] = useState(getShiftNow);

    useEffect(() => {
        setUserData(getProfile);
        setShiftNow(getShiftNow);
    }, [getProfile, getShiftNow]);

    useEffect(() => {
        const updatedPayment = updatePayment(record.payment, record.order_details)
        setRecordData(
            {
                ...record,
                payment: updatedPayment
            }
        )
    }, [record])

    const handleDeleteTransaction = () => {

        // Membuat query string dengan menambahkan parameter id
        const newDelete = `&delete=${record.id}`;

        // Memperbarui location dengan menambahkan parameter
        const newLocation = {
            ...location,
            search: location.search + newDelete,
        };

        // Menggunakan replace agar tidak menambahkan entri ke dalam history stack
        history.push(newLocation);
        setOpenModalDelete(false)
    }

    const handleOpenModalAdd = () => {
        if (shiftNow !== null) {
            setOpenModalTransaction(true);
        } else {
            toast.error('Shift is not open');
        }
    }

    return (
        <div className='d-flex gap-2'>
            {/* MODAL TRANSACTION */}
            <ModalAddTransaction open={openModalTransaction} setOpen={setOpenModalTransaction} initialData={recordData} />

            {/* MODAL DELETE */}
            <Modal
                title="Delete"
                open={openModalDelete}
                onOk={() => handleDeleteTransaction()}
                onCancel={() => setOpenModalDelete(false)}
            >
                {/* ARE YOU SURE */}
                <h2>Are you sure want to delete this transaction {record.code}?</h2>
            </Modal>

            <div>
                <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => handleOpenModalAdd()}>
                    <MdEdit style={{ fontSize: "20px" }} />
                </Button>
                {/* <Button style={{ width: "50px" }} type="danger" className="item-center" onClick={() => setOpenModalDelete(true)}>
                    <MdDelete style={{ fontSize: "20px" }} />
                </Button> */}
                {/* <DeleteButtonWithValidation setOpenModalDelete={setOpenModalDelete} /> */}
            </div>

        </div>
    );
}

export default ActionSalesOrder;