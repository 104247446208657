export const ItemDataModel = {
    "id": 0,
    "name": "",
    "brand": "",
    "type": "",
    "color": "",
    "createdAt": "",
    "updatedAt": "",
    "deletedAt": null
}

export const PROCESS_TIME_OPTIONS = [
    {
        label: 'Regular',
        value: 'regular',
        percent: 0
    },
    {
        label: 'Next Day',
        value: 'next_day',
        percent: 0.5
    },
    {
        label: 'Same Day',
        value: 'same_day',
        percent: 1
    },
]