import React from 'react';
import { Button, Popconfirm, Space } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { deleteData } from '../../helper/api';
import { toast } from 'react-toastify';
import ModalEditAbsent from './ModalEditAbsent';

const ActionAbsent = ({ record, trigerFetchData, setTrigerFetchData }) => {
    const [openModalEdit, setOpenModalEdit] = React.useState(false);

    const handleDelete = () => {
        deleteData(`/absents/${record.id}`)
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Absen berhasil dihapus');
                    setTrigerFetchData(!trigerFetchData);
                }
            })
            .catch((error) => {
                toast.error(error.response?.data?.message || 'Gagal menghapus absen');
            });
    };

    return (
        <>
            <ModalEditAbsent
                open={openModalEdit}
                setOpen={setOpenModalEdit}
                initialData={record}
                trigerFetchData={trigerFetchData}
                setTrigerFetchData={setTrigerFetchData}
            />

            <Space>
                <Button
                    type="primary"
                    icon={<EditOutlined />}
                    onClick={() => setOpenModalEdit(true)}
                    className="bg-blue-500"
                >
                    Edit
                </Button>
                <Popconfirm
                    title="Hapus Absen"
                    description="Apakah anda yakin ingin menghapus absen ini?"
                    onConfirm={handleDelete}
                    okText="Ya"
                    cancelText="Tidak"
                    okButtonProps={{ className: "bg-red-500" }}
                >
                    <Button
                        className="bg-red-500 text-white"
                        danger
                        icon={<DeleteOutlined />}
                    >
                        Delete
                    </Button>
                </Popconfirm>
            </Space>
        </>
    );
};

export default ActionAbsent;