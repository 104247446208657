import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchData } from '../helper/api';
import { useHistory } from "react-router-dom";

const GeneralContext = createContext();

export function GeneralProvider({ children }) {
    const [getProfile, setGetProfile] = useState(null);
    const [getShiftNow, setGetShiftNow] = useState(null);
    const [loadGetProfile, setLoadGetProfile] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                setLoading(true);

                // Fetch semua data secara parallel menggunakan Promise.all
                const [userResponse] = await Promise.all([
                    fetchData('/get-profile'),
                ]);


                if (userResponse.data.status === 401) {
                    localStorage.removeItem('token');
                    history.replace('/sign-in');
                }

                // Set user data
                setGetProfile(userResponse.data.data.user);
                setGetShiftNow(userResponse.data.data.shifts);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAllData();
    }, []);

    const handleGetProfile = async () => {
        setLoadGetProfile(true);
        const [userResponse] = await Promise.all([
            fetchData('/get-profile'),
        ]);
        setGetProfile(userResponse.data.data.user);
        setGetShiftNow(userResponse.data.data.shift);
        setLoadGetProfile(false);
    }


    const value = {
        getProfile,
        getShiftNow,
        loadGetProfile,
        handleGetProfile,
        loading
    };

    return (
        <GeneralContext.Provider value={value}>
            {children}
        </GeneralContext.Provider>
    );
}

export function useGeneral() {
    const context = useContext(GeneralContext);
    if (context === undefined) {
        throw new Error('useGeneral must be used within a GeneralProvider');
    }
    return context;
}