import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Button, DatePicker } from 'antd';
import { fetchData, postData, putData } from '../../helper/api';
import { toast } from 'react-toastify';
import moment from 'moment';

const { Option } = Select;

const ModalAddCollaboration = ({ open, setOpen, initialData, trigerFetchData, setTrigerFetchData }) => {

    const [BusinessDataOption, setBusinessDataOption] = useState([]);
    const [form] = Form.useForm();

    const handleAddCollaboration = () => {
        form.validateFields().then((values) => {
            const data = {
                ...values,
                start_date: Date.parse(values.start_date),
                end_date: Date.parse(values.end_date),
            };

            if (initialData) {
                putData(`/collaborations/${initialData.id}`, data)
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success('Collaboration updated successfully');
                            setOpen(false);
                            form.resetFields();
                            setTrigerFetchData(!trigerFetchData);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                postData('/collaborations', {
                    ...data,
                    branch_id: 1,
                })
                    .then((response) => {
                        if (response.status === 201) {
                            toast.success('Collaboration added successfully');
                            setOpen(false);
                            form.resetFields();
                            setTrigerFetchData(!trigerFetchData);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        });
    };

    useEffect(() => {
        fetchData('/businesses')
            .then((response) => {
                if (response.status === 200) {
                    setBusinessDataOption(response.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        if (initialData) {
            form.setFieldsValue({
                ...initialData,
                start_date: initialData.start_date ? moment(initialData.start_date) : null,
                end_date: initialData.end_date ? moment(initialData.end_date) : null,
            });
        }
    }, [initialData]);

    return (
        <Modal
            title="Add Collaboration"
            open={open}
            onCancel={() => setOpen(false)}
            footer={null}
        >
            <Form form={form} onFinish={handleAddCollaboration}>
                <Form.Item
                    label="Name"
                    name="name"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the name' }]}
                >
                    <Input placeholder="Enter name" />
                </Form.Item>

                <Form.Item
                    label="Business"
                    name="business_id"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please select the business' }]}
                >
                    <Select placeholder="Select business">
                        {BusinessDataOption.map(business => (
                            <Option key={business.id} value={business.id}>{business.name}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Start Date"
                    name="start_date"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the Start Date' }]}
                >
                    <DatePicker placeholder="Select Start Date" style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                    label="End Date"
                    name="end_date"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the End Date' }]}
                >
                    <DatePicker placeholder="Select End Date" style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className='flex gap-2'>
                        <Button
                            type="default"
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button className="items-center flex justify-center text-white bg-blue-400" htmlType="submit">
                            {initialData ? 'Update' : 'Add'}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalAddCollaboration;
