import React from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { formatCurrency } from '../../../helper/formatCurrency';
import { Button } from 'antd';

const exportToExcel = (financeData) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Laporan Keuangan Bulanan');

    // Setup the headers
    const headers = [
        'Tanggal', 'Pendapatan (Revenue)', 'Pengeluaran (Expense)', 'Laba/Rugi (Profit/Loss)', 'Cash', 'Bank Transfer', 'QRIS'
    ];
    worksheet.addRow(headers);

    // Style headers
    worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFB6C1' } }; // Light Pink for headers
    });

    // Insert finance report (daily data)
    financeData.finance_report.forEach((entry) => {
        const row = [
            entry.date,
            formatCurrency(entry.revenue),  // Pendapatan
            formatCurrency(entry.expense),  // Pengeluaran
            formatCurrency(entry.income),   // Laba/Rugi
            formatCurrency(entry.cash),     // Cash
            formatCurrency(entry.bank_transfer),  // Bank Transfer
            formatCurrency(entry.qris)  // QRIS
        ];

        const rowIndex = worksheet.addRow(row).number;

        // Apply coloring to differentiate sections
        worksheet.getRow(rowIndex).getCell(2).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '90EE90' } }; // Green for revenue
        worksheet.getRow(rowIndex).getCell(3).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFA07A' } }; // Red for expenses
        worksheet.getRow(rowIndex).getCell(4).fill = entry.income >= 0 ? 
            { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6' } } :  // Blue for profit
            { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF6347' } };   // Red for loss
    });

    // Insert summarized data at the end
    const summaryHeaders = ['Summary', '', '', '', '', '', ''];
    worksheet.addRow(summaryHeaders).font = { bold: true };

    const summarizedRow = [
        'Total', 
        formatCurrency(financeData.summarized_data.totalRevenue),  // Total Revenue
        formatCurrency(financeData.summarized_data.totalExpense),  // Total Expense
        formatCurrency(financeData.summarized_data.totalProfit),   // Total Profit
        formatCurrency(financeData.summarized_data.totalCash),     // Total Cash
        formatCurrency(financeData.summarized_data.totalBankTransfer), // Total Bank Transfer
        formatCurrency(financeData.summarized_data.totalQris)      // Total QRIS
    ];
    const summaryRowIndex = worksheet.addRow(summarizedRow).number;

    // Style summary row
    worksheet.getRow(summaryRowIndex).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } }; // Light Grey for summary
    });

    // Adjust column widths
    worksheet.columns.forEach(column => {
        column.width = 20;
    });

    // Save the workbook to a file
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer]), 'Laporan_Keuangan_Bulanan.xlsx');
    });
};

const ExportExcel = ({ financeData }) => {
    return (
        <div>
            <Button onClick={() => exportToExcel(financeData)}>Export Finance Report</Button>
        </div>
    );
};

export default ExportExcel;
