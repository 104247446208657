import React, { useEffect, useState } from 'react';
import { Table, Empty, Card, Input, Button, Popover, Pagination
  , Row, Col, Typography
} from 'antd';
import { columnsDelivery, deliveryDataToDataSourceList } from './helper';
import { IoIosAddCircle } from 'react-icons/io';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import { useQuery } from '../../helper/useQuery';
import { deleteData, fetchData } from '../../helper/api';
import ModalAddDelivery from './ModalAddDelivery';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatCurrency } from '../../helper/formatCurrency';
import { TbTruckDelivery } from "react-icons/tb";
import { IoPersonSharp } from "react-icons/io5";
import { GiPathDistance } from "react-icons/gi";
import { FaMoneyBillTrendUp } from "react-icons/fa6";

const { Title } = Typography;

const Index = () => {
  const location = useLocation();
  const history = useHistory();
  // Data Delivery
  const [dataSource, setDataSource] = useState();
  const [statisticData, setStatisticData] = useState();

  // Modal Add
  const [openModalAdd, setOpenModalAdd] = useState(false);

  const handleOpenModalAdd = () => {
    setOpenModalAdd(true);
  };


  const [stateDate, setStateDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  // Pagination & Filter Date
  const query = useQuery();
  const page = query.get('page') || 1;
  const pageSize = query.get('pageSize') || 10;
  const startDate = query.get('startDate') || moment().subtract(5, 'days').format('YYYY-MM-DD');
  const endDate = query.get('endDate') || moment().format('YYYY-MM-DD');

  // PAGINATION
  const [pagination, setPagination] = useState({
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    pageSize: 10,
  });

  // search
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const url = `/deliveries?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}&search=${encodeURIComponent(searchValue)}`;

    const searchLocation = location.search;
    const newSearchLocation = searchLocation.replace(/&add=\d+|&update=\d+/, "");
    history.push({
      ...location,
      search: newSearchLocation,
    });
    fetchData(url)
      .then((res) => {
        const data = res.data.data.deliveries;
        const dataPagination = res.data.data.pagination;
        const sourceDataList = deliveryDataToDataSourceList(data);
        setDataSource(sourceDataList);
        setPagination({
          totalItems: dataPagination.totalItems,
          totalPages: dataPagination.totalPages,
          currentPage: dataPagination.currentPage,
          pageSize: dataPagination.pageSize,
        });

        setStatisticData(res.data.data.statistics);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page, pageSize, startDate, endDate, searchValue, location.search]);


  const handleFilterTanggal = (item) => {
    setStateDate([item.selection]);
    const startDateParam = moment(item.selection.startDate).format('YYYY-MM-DD');
    const endDateParam = moment(item.selection.endDate).format('YYYY-MM-DD');

    if (page !== 1) {
      history.push({
        pathname: location.pathname,
        search: `?page=1&pageSize=${pageSize}&startDate=${startDateParam}&endDate=${endDateParam}`
      });
    } else {
      // Mengupdate parameter URL
      history.push({
        pathname: location.pathname,
        search: `?page=${page}&pageSize=${pageSize}&startDate=${startDateParam}&endDate=${endDateParam}`
      });
    }
  }

  const content = (
    <DateRangePicker
      onChange={handleFilterTanggal} // Menggunakan fungsi handleFilterTanggal yang telah dibuat
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={stateDate}
      direction="horizontal"
    />
  );


  const handlePageChange = (page, pageSize) => {
    setPagination({
      ...pagination,
      currentPage: page,
      pageSize: pageSize
    });

    history.push({
      pathname: location.pathname,
      search: `?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`
    });
  };

  useEffect(() => {
    // if there is a location.search is delete
    const deleteParam = query.get('delete');
    if (deleteParam) {
      deleteData(`/deliveries/${deleteParam}`)
        .then((res) => {
          console.log(res);
          toast.success('Delivery deleted successfully');
          history.push({
            pathname: location.pathname,
            search: `?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [location.search]);

  const handlePageSizeChange = (current, size) => {
    setPagination({
      ...pagination,
      currentPage: current,
      pageSize: size
    });
    history.push({
      pathname: location.pathname,
      search: `?page=${current}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`
    });
  }

  const onSearch = (value) => {
    setSearchValue(value);
  }

  const statistics = [
    {
      name: "Total Delivery",
      value: statisticData?.total_delivery,
      icon: <TbTruckDelivery />,
    },
    {
      name: "Total Customer",
      value: statisticData?.total_delivery_details,
      icon: <IoPersonSharp />,
    },
    {
      name: "Total Distance",
      value: statisticData?.total_distance,
      icon: <GiPathDistance />,
    },
    {
      name: "Total Cost",
      value: formatCurrency(parseInt(statisticData?.total_cost || 0)),
      icon: <FaMoneyBillTrendUp />,
    },
  ];

  return (
    <div>
      <ModalAddDelivery open={openModalAdd} setOpen={setOpenModalAdd} />
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        {statistics.map((c, index) => (
          <Col
            key={index}
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            className="mb-4"
          >
            <Card bordered={false} className="criclebox ">
              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col xs={18}>
                    <span>{c.name}</span>
                    <Title level={3}>
                      {c.value}
                    </Title>
                  </Col>
                  <Col xs={6}>
                  <div className="icon-box flex flex-col items-center justify-center" style={{ fontSize: '20px' }}>{c.icon}</div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      
      <Card
        bordered={false}
        className="criclebox tablespace mb-4"
        title={
          <div style={{ display: "flex", gap: "20px" }}>
            <h4>Delivery</h4>
            <Input
                    style={{ width: "200px" , fontSize: "12px" }}
                    placeholder="Search Courier"
                    size="small"
                    onPressEnter={(e) => {
                      if (e.key === 'Enter') {
                        onSearch(e.target.value); // Gantilah dengan nama fungsi pencarian Anda
                      }
                    }}
                  />
                </div>
        }
        extra={
          <div style={{ display: "flex", gap: "20px" }}>
            <Popover content={content} placement="leftTop" title="Filter Tanggal" trigger="click">
              <Button>Entry Date Filter : {moment(startDate).format('DD MMM YYYY')} - {moment(endDate).format('DD MMM YYYY')}</Button>
            </Popover>

            <Button className="items-center flex justify-center text-white bg-blue-400"  onClick={() => handleOpenModalAdd()}>
              <IoIosAddCircle style={{ fontSize: "20px" }} />
              Add Delivery
            </Button>
          </div>
        }

      >
           <div style={{ padding: "20px", display: 'flex', justifyContent: "flex-end" }}>
          <Pagination
            current={pagination.currentPage}
            total={pagination.totalItems}
            pageSize={pagination.pageSize}
            onChange={handlePageChange}
            onShowSizeChange={handlePageSizeChange}
            showSizeChanger
            pageSizeOptions={['10', '20', '50', '100']}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          />
        </div>
        <div className="table-responsive">
          <Table
            columns={columnsDelivery}
            dataSource={dataSource}
            pagination={false}
            className="ant-border-space"
            locale={{
              emptyText: <Empty description="Data not found" />,
            }}
          />
        </div>
     

      </Card>
    </div>
  );
}

export default Index;