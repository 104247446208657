import React, { useEffect } from 'react';
import ModalAddDelivery from './ModalAddDelivery';
import { Button, Modal } from "antd";
import { MdEdit, MdDelete } from "react-icons/md";
import { deleteData } from '../../helper/api';
import { useHistory, useLocation } from 'react-router-dom';
import DeleteButtonWithValidation from '../../components/layout/DeleteButtonWithValidation';

function ActionDelivery(props) {
    const { record } = props
    const location = useLocation()
    const history = useHistory()

    const [initialData, setInitialData] = React.useState(record)
    const [openModalDelete, setOpenModalDelete] = React.useState(false)
    const [openModalDelivery, setOpenModalDelivery] = React.useState(false)

    useEffect(() => {
        setInitialData(record)
    }, [record])

    const handleDeleteDelivery = () => {
        const newDelete = `&delete=${record.id}`;

        // delete if before have search word delete
        const newSearch = location.search.replace(/&delete=\d+/g, '');

        const newLocation = {
            ...location,
            search: newSearch + newDelete,
        };
        history.push(newLocation);
    }

    return (
        <div>
            <div className='d-flex gap-2'>
                {/* MODAL TRANSACTION */}
                <ModalAddDelivery open={openModalDelivery} setOpen={setOpenModalDelivery} initialData={initialData} />

                {/* MODAL DELETE */}
                <Modal
                    title="Delete"
                    open={openModalDelete}
                    onOk={() => handleDeleteDelivery()}
                    onCancel={() => setOpenModalDelete(false)}
                >
                    {/* ARE YOU SURE */}
                    <h2>Are you sure want to delete this delivery {record.id}?</h2>
                </Modal>

                <div>
                    <Button style={{ width: "50px", marginRight: "10px" }} className="items-center flex justify-center text-white bg-blue-400" onClick={() => setOpenModalDelivery(true)}>
                        <MdEdit style={{ fontSize: "20px" }} />
                    </Button>
                    {/* <Button style={{ width: "50px" }} type="danger" className="item-center" onClick={() => setOpenModalDelete(true)}>
                        <MdDelete style={{ fontSize: "20px" }} />
                    </Button> */}
                     <DeleteButtonWithValidation setOpenModalDelete={setOpenModalDelete} />
                </div>

            </div>
        </div>
    );
}

export default ActionDelivery;