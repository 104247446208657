import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import CustomerPattern from './components/CustomerPattern';
import { fetchData } from '../../helper/api';

const CustomerHistory = () => {
    const { customerId } = useParams();
    const [loading, setLoading] = useState(true);
    const [customerData, setCustomerData] = useState(null);
    const [patternAnalysis, setPatternAnalysis] = useState(null);

    useEffect(() => {
        const fetchCustomerData = async () => {
            try {
                const [customerResponse, patternResponse] = await Promise.all([
                    fetchData(`/customers/${customerId}`),
                    fetchData(`/customer-pattern/${customerId}`)
                ]);

                setCustomerData(customerResponse.data);
                setPatternAnalysis(patternResponse.data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchCustomerData();
    }, [customerId]);

    if (loading) return <Spin />;

    return (
        <div className="tabled">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Card
                        bordered={false}
                        className="criclebox tablespace mb-24"
                        title="Customer History Analysis"
                    >
                        <CustomerPattern analysis={patternAnalysis} />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CustomerHistory;