import React, { useEffect } from 'react'
import { Input, Button, Table, Empty, Card } from 'antd'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { formatCurrency } from '../../helper/formatCurrency'
import { set } from 'date-fns'
import ShiftDashboard from './ShiftDashboard'
import { fetchData, postData } from '../../helper/api'
import { toast } from 'react-toastify'
import moment from 'moment'
import { FaCashRegister } from 'react-icons/fa'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useGeneral } from '../../contexts/GeneralContext'

export default function ShiftList() {
    const { getProfile, getShiftNow } = useGeneral()
    const [trigerFetchData, setTrigerFetchData] = React.useState(false)
    const history = useHistory()

    const [dataShiftList, setDataShiftList] = React.useState([])

    useEffect(() => {
        // Get data shift
        fetchData('/shifts')
            .then((response) => {
                const data = response.data.data
                const moveData = data.map((item) => {
                    return {
                        ...item,
                        name: item.employee_shift.name,
                        start_time: moment(item.start_time).format('DD MMM YYYY HH:mm:ss'),
                        end_time: item.end_time ? moment(item.end_time).format('DD MMM YYYY HH:mm:ss') : '-',
                        uang_awal: formatCurrency(parseInt(item.cash_initial || 0)),
                        uang_tunai_masuk: formatCurrency(parseInt(item.cash_expected || 0)),
                        total_seharusnya: formatCurrency(parseInt(item.cash_expected) + parseInt(item.cash_initial) - parseInt(item.expense)),
                        total_sebenarnya: formatCurrency(parseInt(item.cash_actual || 0)),
                        beda: formatCurrency(parseInt(item.difference || 0)),
                        expense: formatCurrency(parseInt(item.expense || 0)),
                    }
                })
                setDataShiftList(moveData)
            })
            .catch((error) => {
                console.log(error)
            })

    }, [trigerFetchData])

    const columnsShift = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Start Time',
            dataIndex: 'start_time',
            key: 'start_time',
        },
        {
            title: 'End Time',
            dataIndex: 'end_time',
            key: 'end_time',
        },
        {
            title: 'Initial Cash',
            dataIndex: 'uang_awal',
            key: 'uang_awal',
        },
        {
            title: 'Cash Inflow',
            dataIndex: 'uang_tunai_masuk',
            key: 'uang_tunai_masuk',
        },
        {
            title: 'Cash Expense',
            dataIndex: 'expense',
            key: 'expense',
        },
        {
            title: 'Expected Total',
            dataIndex: 'total_seharusnya',
            key: 'total_seharusnya',
        },
        {
            title: 'Actual Total',
            dataIndex: 'total_sebenarnya',
            key: 'total_sebenarnya',
        },
        {
            title: 'Difference',
            dataIndex: 'beda',
            key: 'beda',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <Button type="link" onClick={() => history.push(`/shift/${record.id}`)}>
                    Detail
                </Button>
            ),
        },
    ];

    return (
        <div>
            <Card>
                <div className="table-responsive" >
                    <Table
                        columns={columnsShift}
                        dataSource={dataShiftList}
                        className="ant-border-space"
                        locale={{
                            emptyText: <Empty description="Data not found" />,
                        }}
                        pagination={{
                            position: ['bottomCenter'],
                            pageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: ['5', '10', '20', '30', '40'],
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        }}
                    />
                </div>
            </Card>

        </div>
    )
}
