import axios from 'axios';

const HOST = process.env.REACT_APP_API_HOST;
const PORT = parseInt(process.env.REACT_APP_API_PORT);
const TIMEOUT = parseInt(process.env.REACT_APP_API_TIMEOUT);

export const API_URL = HOST?.includes('localhost') ? `${HOST}:${PORT}` : HOST;

const instance = axios.create({ baseURL: API_URL, timeout: TIMEOUT });

export const setAuthToken = (token) => {
  if (token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    localStorage.setItem('token', token);
  } else {
    delete instance.defaults.headers.common['Authorization'];
    localStorage.removeItem('token');
  }
};

const savedToken = localStorage.getItem('token');
if (savedToken) setAuthToken(savedToken);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      setAuthToken(null);
      // window.location.href = '/sign-in';
    }
    return Promise.reject(error);
  }
);

export default instance;

export const fetchData = (url, config = {}) => instance.get(url, config);
export const postData = (url, data, config = {}) => instance.post(url, data, config);
export const putData = (url, data, config = {}) => instance.put(url, data, config);
export const deleteData = (url, config = {}) => instance.delete(url, config);