import React, { useEffect } from 'react';
import { Card, Button } from 'antd';
import { formatCurrency } from '../../helper/formatCurrency';
import dayjs from 'dayjs';
import CustomerInfo from '../component/CustomerInfo';
import CardItemService from '../Transaction/CardItemService';

function CardOrderList(props) {
    const { record } = props

    const [deliveryDetail, setDeliveryDetail] = React.useState(record?.delivery_details)
    const [openModalAddTransaction, setOpenModalAddTransaction] = React.useState(false)

    useEffect(() => {
        setDeliveryDetail(record?.delivery_details)
    }, [record])

    return (
        <div>
            {deliveryDetail?.map((item, index) => {
                return (
                    <>
                        <Card key={index} style={{ padding: '8px', width: "320px", margin: '5px' }}>
                            <div style={{ display: "flex", gap: '5px', justifyContent: "space-between", padding: '2px', color: "black", alignItems: 'start' }}>
                                <CustomerInfo record={item?.order} />
                                <div>
                                    <label style={{ fontSize: '14px', fontWeight: 'bold', color: "black" }}>Qty Item</label>
                                    <div>{item?.order?.order_details?.length}</div>
                                    {/* <div>{formatCurrency(parseInt(item?.cost))}</div> */}
                                </div>
                                <div>
                                    <label style={{ fontSize: '14px', fontWeight: 'bold', color: "black" }}>{item?.type}</label>
                                    <div>{item?.distance} Km</div>
                                    <div>{formatCurrency(parseInt(item?.cost))}</div>
                                </div>
                                
                            </div>
                            {/* <hr /> */}
                            {/* <CardItemService record={item?.order} /> */}
                        </Card>
                    </>
                    //                 <Card key={index} style={{ padding: '8px', width: "300px" }}>
                    //                     <div style={{ display: "flex", gap: '5px', color:"black", alignItems:'center' }}>
                    //                         <img src={item?.order?.order_details?.shoes_photos[0]?.url_photo} alt="shoes" style={{ width: '80px', height: '50px',objectFit: 'cover', // Menutupi ukuran container dengan memotong bagian dari gambar
                    // objectPosition: 'center center' }} />
                    //                         <div style={{ display: "flex", gap: '20px', justifyContent:"space-around", width:"100%" }}>
                    //                             <div>
                    //                                 <p style={{ color:"black"}}>{item.treatment.name}</p>
                    //                                 <p style={{ fontSize: '10px', color:"black"}}>{item.item.name} - {item.item.color}</p>
                    //                                 <p style={{ fontSize: '10px', color: 'black'}}> Due Date: <p style={{ fontSize: '12px', color: 'black', fontWeight:'bold'}}>{dayjs(item.due_date).format('DD MMM YYYY')}</p></p>
                    //                             </div>
                    //                             <div>
                    //                                 <p style={{ fontSize: '12px', color:"black"}}>{orderData?.payment?.status === 'paid' ? "Paid" : "Unpaid"}</p>
                    //                                 <p style={{ fontSize: '15px', fontWeight: 'bold', color:"black" }}>{formatNumber(parseInt(item.final_price))}</p>
                    //                             </div>
                    //                         </div>
                    //                     </div>
                    //                 </Card>
                )
            }
            )}
        </div>
    );
}

export default CardOrderList;