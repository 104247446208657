import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Alert
} from "antd";

import { postData,setAuthToken } from "../helper/api";

export default function SignIn({ onLogin }) {
  const history = useHistory();
  const [error, setError] = useState("");

  const { Title } = Typography;
  const { Footer, Content } = Layout;

  const onFinish = (values) => {
    setError("");
    const loginData = {
        email: values.email,
        password: values.password,
    };

    postData("/login", loginData)
      .then((res) => {
        localStorage.setItem("token", res.data.data.token);
        setAuthToken(res.data.data.token);
        onLogin();
        window.location.href = '/transaction';
      })
      .catch((err) => {
        console.log('Error response:', err.response);
        setError(err.response.data.error.message || "Invalid credentials");
        if (err.response && err.response.status === 401) {
          alert(err.response.data.error.message || "Invalid credentials");
        } else {
          alert("An error occurred during login. Please try again.");
        }
      });
  };

  return (
    <>
      <Layout className="layout-default layout-signin" style={{ height: "100vh" }}>
        <Content className="signin d-flex justify-content-center ">
          <Row gutter={[24, 0]} justify="space-around" style={{ width:"100%" }}>
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">Katsikat.Id</Title>
              <Title className="font-regular text-muted" level={5}>
                Enter your email and password to sign in
              </Title>
              
              {error && (
                <Alert
                  message={error}
                  type="error"
                  showIcon
                  className="mb-4"
                />
              )}

              <Form
                onFinish={onFinish}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input type="password"  placeholder="Password" />
                </Form.Item>

                <Form.Item>
                  <Button
                    className="btn-primary bg-blue-400 text-white"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Content>
        <Footer>
          <p className="copyright">
            {" "}
            Copyright © 2024 Katsikat.Id
          </p>
        </Footer>
      </Layout>
    </>
  );
}