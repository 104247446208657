import React, { useEffect, useState } from "react";
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameDay } from "date-fns";
import { fetchData } from "../../helper/api";
import dayjs from "dayjs";
import CalendarDetailModal from './CalendarViewPaymentModal'; // Modal Component

const CalendarViewPayment = () => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [financeData, setFinanceData] = useState([]);

    useEffect(() => {
        fetchData('/genereteDashboardFinanceReport', {
            params: {
                month: dayjs(currentMonth).format('MM'),
                year: dayjs(currentMonth).format('YYYY'),
            },
        }).then((res) => {
            setFinanceData(res.data.data.finance_report || []);
        });
    }, [currentMonth]);

    const daysInMonth = eachDayOfInterval({
        start: startOfMonth(currentMonth),
        end: endOfMonth(currentMonth),
    });

    const handlePrevMonth = () => {
        setCurrentMonth((prev) => new Date(prev.getFullYear(), prev.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setCurrentMonth((prev) => new Date(prev.getFullYear(), prev.getMonth() + 1, 1));
    };

    const handleDateClick = (date) => {
        setSelectedDate(date);
        setIsModalOpen(true);
    };

    const handleModalOk = () => {
        setIsModalOpen(false);
    };

    const handleModalCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="mx-auto p-4 border rounded-lg bg-white shadow-md">
            <header className="flex justify-between items-center mb-4">
                <button className="px-3 py-1 bg-gray-200 rounded hover:bg-gray-300" onClick={handlePrevMonth}>&lt;</button>
                <h2 className="text-xl font-bold">{format(currentMonth, "MMMM yyyy")}</h2>
                <button className="px-3 py-1 bg-gray-200 rounded hover:bg-gray-300" onClick={handleNextMonth}>&gt;</button>
            </header>
            <div className="grid lg:grid-cols-5 md:grid-cols-4 grid-cols-2 gap-4">
                {daysInMonth.map((day) => {
                    const dateKey = format(day, "yyyy-MM-dd");
                    const finance = financeData.find(item => item?.date === dateKey);

                    const isPaidCompletely = finance && finance.unpaid === 0 && finance.paid !== 0;
                    const isNoTransactions = finance && finance.paid === 0 && finance.unpaid === 0;

                    return (
                        <div
                            key={day}
                            className={`p-6 border rounded-lg text-center cursor-pointer transition-all duration-300 shadow-md 
                            ${isSameDay(day, selectedDate) ?
                                    "bg-blue-600 text-white scale-110 shadow-xl ring-2 ring-blue-300" :
                                    isPaidCompletely ?
                                        "bg-green-500 text-white shadow-lg hover:ring-2 hover:ring-green-300" :
                                        isNoTransactions ?
                                            "bg-white text-gray-400" :
                                            "bg-yellow-100 hover:bg-yellow-200 hover:shadow-md"}`}
                            onClick={() => handleDateClick(day)}
                        >
                            {/* Date Number */}
                            <span className="block font-bold text-2xl mb-2">
                                {format(day, "d")}
                            </span>

                            {/* Finance Information */}
                            {finance ? (
                                <div className="space-y-1">
                                    <div className="text-gray-800 font-bold text-sm flex items-center justify-center gap-2">
                                        <span>💰</span> Paid:
                                        <span className="text-green-600">{finance.paid.toLocaleString()}</span>
                                    </div>
                                    <div className="text-red-600 font-semibold text-sm flex items-center justify-center gap-2">
                                        <span>❌</span> Unpaid:
                                        <span>{finance.unpaid.toLocaleString()}</span>
                                    </div>
                                    <div className="text-blue-600 font-semibold text-sm flex items-center justify-center gap-2">
                                        <span>📥</span> DP:
                                        <span>{finance.dp ? finance.dp.toLocaleString() : "0"}</span>
                                    </div>
                                </div>
                            ) : (
                                <div className="text-gray-500 text-sm mt-2">
                                    No Data Available
                                </div>
                            )}

                        </div>

                    );
                })}
            </div>

            {/* Modal */}
            {selectedDate && (
                <CalendarDetailModal
                    isModalOpen={isModalOpen}
                    handleOk={handleModalOk}
                    handleCancel={handleModalCancel}
                    data={financeData.find(item => item.date === format(selectedDate, "yyyy-MM-dd")) || {}}
                />
            )}
        </div>
    );
};

export default CalendarViewPayment;
