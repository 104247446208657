import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, Button, DatePicker } from 'antd';
import { fetchData, postData, putData } from '../../helper/api';
import { toast } from 'react-toastify';
import { formatCurrency } from '../../helper/formatCurrency';
import numeral from 'numeral';
import { useLocation, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import UploadPhoto from './UploadPhoto';
import { useFormik } from 'formik';
import moment from 'moment';
import { Dayjs } from 'dayjs';

const { Option } = Select;

const ModalAddCapitalRecord = ({ open, setOpen, initialData, trigerFetchData, setTrigerFetchData, nameOption, categoryOption }) => {
    const location = useLocation();
    const history = useHistory();

    const [employee, setEmployee] = React.useState([]);

    const formik = useFormik({
        initialValues: {
            user_id: '',
            date: '',
            type: '',
            amount: '',
            description: '',
            payment_method: '',
            photos: '',
        },
        onSubmit: values => {
            console.log('values', values);
            const newData = {
                ...values,
                photos: values?.photos[0]?.url_photo ? values?.photos[0]?.url_photo : values?.photos,
            }
            if (initialData) {
                putData(`/capital-records/${initialData.id}`, newData)
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success('capitalRecord updated successfully');
                            setOpen(false);
                            formik.resetForm();
                            setTrigerFetchData(!trigerFetchData);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                postData('/capital-records', newData)
                    .then((response) => {
                        if (response.status === 201) {
                            formik.resetForm();
                            toast.success('capitalRecord added successfully');
                            setOpen(false);
                            setTrigerFetchData(!trigerFetchData);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    })

    const handleAddcapitalRecord = () => {
        if (initialData) {
        } else {
        }

    };

    useEffect(() => {
        if (initialData) {
            formik.setValues({
                user_id: initialData.user_id,
                date: initialData.date,
                type: initialData.type,
                amount: initialData.amount,
                description: initialData.description,
                payment_method: initialData.payment_method,
                photos: initialData.photos,
            });
        }
    }, [initialData]);

    useEffect(() => {
        fetchData(`/users`)
            .then((res) => {
                const data = res.data.data;
                setEmployee(
                    data.map((item) => {
                        return {
                            id: item.id,
                            name: item.name,
                            item: item
                        }
                    }
                    )
                );
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    return (
        <Modal
            title={`${initialData ? 'Edit' : 'Add'} Capital Record`}
            open={open}
            onCancel={() => setOpen(false)}
            onOk={() => setOpen(false)}
            footer={null}
        >
            <Form layout="vertical" onSubmit={formik.handleSubmit}>
                <Form.Item
                    label="Financier"
                >
                    <Select
                        placeholder="Select Financier"
                        value={formik.values?.user_id || ''}
                        onChange={(e, option) => {
                            formik.setFieldValue('user_id', e);
                            formik.setFieldValue('user', option.item);
                        }}
                    >
                        {employee.map((item) => {
                            return (
                                <Option value={item.id} item={item.item}>{item.name}</Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item label="Date">
                    <DatePicker
                        name='date'
                        style={{ width: '100%' }}
                        format={"dddd, DD MMMM YYYY"}
                        onChange={(value) => formik.setFieldValue('date', value)}
                        value={formik.values?.date ? moment(formik.values?.date) : null}
                    />
                </Form.Item>

                <Form.Item label="Type">
                    <Select placeholder="Select type"
                        value={formik.values?.type}
                        onChange={(e) => {
                            formik.setFieldValue('type', e);
                        }}
                    >
                        <Option value="Penambahan Modal">Penambahan Modal</Option>
                        <Option value="Penarikan Modal">Penarikan Modal</Option>
                    </Select>
                </Form.Item>

                <Form.Item label="Amount" >
                    <Input
                        type='text'
                        value={formatCurrency(parseInt(formik.values?.amount || 0))}
                        onChange={(event) => {
                            const value = event.target.value;
                            // Mengabaikan karakter selain digit (contoh: comma atau titik)
                            const numericValue = parseFloat(value.replace(/[^\d]/g, ''));

                            // Menangani nilai awal NaN
                            const newValue = isNaN(numericValue) ? 0 : numericValue;

                            formik.setFieldValue("amount", newValue);
                        }}
                    />
                </Form.Item>
                <Form.Item label="Description" >
                    <Input
                        placeholder="Description"
                        value={formik.values?.description}
                        onChange={(e) => { formik.setFieldValue('description', e.target.value); }}
                    />
                </Form.Item>

                <Form.Item label="Payment Method" >
                    <Select placeholder="Select payment method"
                        value={formik.values?.payment_method}
                        onChange={(e) => {
                            formik.setFieldValue('payment_method', e);
                        }}
                    >
                        <Option value="cash">Cash</Option>
                        <Option value="bank_transfer">Transfer</Option>
                    </Select>
                </Form.Item>

                <label>Receipt</label>

                <UploadPhoto formik={formik} />

                <Button style={{ width: "100%" }} className="items-center flex justify-center text-white bg-blue-400" onClick={() => formik.handleSubmit()}>
                    {initialData ? 'Update' : 'Add'} Capital Record
                </Button>
            </Form>
        </Modal >
    );
};

export default ModalAddCapitalRecord;
