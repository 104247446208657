import React, { useEffect, useState } from 'react';
import { Table, Empty, Card, Input, Button, Popover, Pagination, Row, Col, Typography } from 'antd';
import { IoIosAddCircle } from "react-icons/io";
import { API_URL, fetchData } from '../../helper/api';
import { formatCurrency } from '../../helper/formatCurrency';
import ModalAddProductTransaction from './ModalAddProductTransaction';
// import ActionProductTransaction from './ActionProductTransaction';
import { useLocation, useHistory } from 'react-router-dom';
import ActionProductTransaction from './ActionProductTransaction';
import { PaymentMethodDisplay, PaymentStatusDisplay } from '../../models/orderData';
import { calculateDiscountedPrice } from '../../helper/Calculation';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import moment from 'moment';
import { useQuery } from '../../helper/useQuery';
import { FaCartArrowDown, FaMoneyBillWave } from 'react-icons/fa';
import { GrServices } from 'react-icons/gr';
import { TbShoe } from 'react-icons/tb';

const { Title, Text } = Typography;


function Index(props) {
    const [dataSource, setDataSource] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [trigerFetchData, setTrigerFetchData] = React.useState(false);
    const [listData, setListData] = React.useState([]);

    const location = useLocation();
    const history = useHistory();

    const [openModalAdd, setOpenModalAdd] = React.useState(false);

    // QUERY
    const query = useQuery();
    const page = query.get('page') || 1;
    const pageSize = query.get('pageSize') || 10;
    const startDate = query.get('startDate') || moment().subtract(5, 'days').format('YYYY-MM-DD');
    const endDate = query.get('endDate') || moment().format('YYYY-MM-DD');

    const [pagination, setPagination] = useState({
        totalItems: 0,
        totalPages: 0,
        currentPage: 1,
        pageSize: 10,
    });

    const [statisticData, setStatisticData] = useState({});


    const handleOpenModalAdd = () => {
        setOpenModalAdd(true);
    }

    const onSearch = (value) => {
        setSearchValue(value);
    }

    const mappingDataToDataSource = (data) => {
        return data.map((item, index) => {
            return {
                ...item,
                no: index + 1,
                price_display: formatCurrency(parseInt(item.price)),
            };
        });
    }

    useEffect(() => {
        const searchLocation = location.search;
        const newSearchLocation = searchLocation.replace(/&add_update=\d/, "");
        history.push({
            ...location,
            search: newSearchLocation,
        });
        // Fetch data
        let url = `/order-products?search=${encodeURIComponent(searchValue)}&startDate=${startDate}&endDate=${endDate}&page=${page}&pageSize=${pageSize}`;

        if (searchValue === "") {
            url = `/order-products?startDate=${startDate}&endDate=${endDate}&page=${page}&pageSize=${pageSize}`;
        }

        fetchData(url)
            .then((res) => {
                const data = res.data.data.orderProducts;
                const statisticData = res.data.data.statistics;

                const dataSource = mappingDataToDataSource(data);
                setStatisticData(statisticData);
                setDataSource(dataSource);
                setPagination({
                    ...pagination,
                    totalItems: res.data.data.total,
                    totalPages: res.data.data.page,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [location.search, trigerFetchData, searchValue, page, pageSize, startDate, endDate])


    useEffect(() => {
        fetchData('/order-products')
            .then((res) => {
                const data = res.data.data;
                const dataSource = data
                setListData(dataSource);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const column = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Customer',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <>
                    <div>{record.customer.name}</div>
                </>
            )
        },
        {
            title: 'Keranjang',
            dataIndex: 'order_details',
            key: 'order_details',
            render: (text, record) => (
                <>
                    <div className='flex flex-col gap-2'>
                        {record.order_product_details.map((item, index) => {
                            return (
                                <div className='flex items-center justify-between gap-5 border-2 border-gray-200 rounded-lg p-2'>
                                    <div className='flex items-center gap-4'>
                                        <img src={`${API_URL}/${item.product.images}`}
                                            alt={item.product.name} className='w-10 h-10 object-cover' />
                                        <div className='flex flex-col gap-2'>
                                            <div key={index}>{item.product.name}</div>
                                            <div key={index}>x{item.quantity}</div>
                                        </div>
                                    </div>
                                    <div key={index}>{formatCurrency(item.total_price_per_category)}</div>
                                </div>
                            )
                        }
                        )}</div>
                </>
            ),
        },
        {
            title: 'Metode Pembayaran',
            dataIndex: 'method',
            key: 'method',
            render: (text, record) => (
                <div>{PaymentMethodDisplay(record.payment.method)}</div>
            ),
        },
        {
            title: 'Status Pembayaran',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <div>{PaymentStatusDisplay(record.payment.status)}</div>
            ),
        },
        {
            title: 'Diskon',
            dataIndex: 'discount',
            key: 'discount',
            render: (text, record) => (
                <div>{formatCurrency(parseInt(calculateDiscountedPrice(record.payment.total_price, record.discount).discount_nominal))}</div>
            ),
        },
        {
            title: 'Total Harga',
            dataIndex: 'total_price',
            key: 'total_price',
            render: (text, record) => (
                <div>{formatCurrency(parseInt(record.payment.final_price))}</div>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <ActionProductTransaction record={record} trigerFetchData={trigerFetchData} setTrigerFetchData={setTrigerFetchData} />
            ),
        },
    ]



    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);

    const handleFilterTanggal = (item) => {
        setState([item.selection]);
        const startDateParam = moment(item.selection.startDate).format('YYYY-MM-DD');
        const endDateParam = moment(item.selection.endDate).format('YYYY-MM-DD');

        if (page !== 1) {
            history.push({
                pathname: location.pathname,
                search: `?page=1&pageSize=${pageSize}&startDate=${startDateParam}&endDate=${endDateParam}`
            });
        } else {
            // Mengupdate parameter URL
            history.push({
                pathname: location.pathname,
                search: `?page=${page}&pageSize=${pageSize}&startDate=${startDateParam}&endDate=${endDateParam}`
            });
        }
    };

    const handlePageChange = (page, pageSize) => {
        setPagination({
          ...pagination,
          currentPage: page,
          pageSize: pageSize
        });
    
        history.push({
          pathname: location.pathname,
          search: `?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`
        });
      };
    
      const handlePageSizeChange = (current, size) => {
        setPagination({
          ...pagination,
          currentPage: current,
          pageSize: size
        });
        history.push({
          pathname: location.pathname,
          search: `?page=${current}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`
        });
      }


    const content = (
        <DateRangePicker
            onChange={handleFilterTanggal} // Menggunakan fungsi handleFilterTanggal yang telah dibuat
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
        />
    );


    const statistics = [
        {
            name: `Total Transaction (${statisticData?.count_transaction || 0})`,
            value: formatCurrency(parseInt(statisticData?.sum_orders_after_discount || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: `Total Paid (${statisticData?.payment_status?.paid || 0})`,
            value: formatCurrency(parseInt(statisticData?.paid_revenue || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: `Total Unpaid (${statisticData?.payment_status?.unpaid || 0})`,
            value: formatCurrency(parseInt(statisticData?.unpaid_revenue || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: "Qty Products",
            value: statisticData?.quantity_product,
            icon: <FaCartArrowDown />,
        },
        {
            name: "Qty Products Category",
            value: statisticData?.quantity_product_category,
            icon: <FaCartArrowDown />,
        },

    ];


    return (
        <>
            <ModalAddProductTransaction listData={listData} open={openModalAdd} setOpen={setOpenModalAdd} trigerFetchData={trigerFetchData} setTrigerFetchData={setTrigerFetchData} />
            <Row className="rowgap-vbox" gutter={[24, 0]}>
                {statistics.map((c, index) => (
                    <Col
                        key={index}
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}
                        xl={8}
                        className="mb-4"
                    >
                        <Card bordered={false} className="criclebox ">
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col xs={18}>
                                        <span>{c.name}</span>
                                        <Title level={3}>
                                            {c.value}
                                        </Title>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="icon-box flex flex-col items-center justify-center" style={{ fontSize: '20px' }}>{c.icon}</div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Card
                bordered={false}
                className="criclebox tablespace mb-4"
                title={
                    <div style={{ display: "flex", gap: "20px" }}>
                        <h4>Transaksi Produk</h4>
                        <Input
                            style={{ width: "200px", fontSize: "12px" }}
                            placeholder="Search Name / ProductTransaction / Service"
                            size="small"
                            onPressEnter={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch(e.target.value); // Gantilah dengan nama fungsi pencarian Anda
                                }
                            }}
                        />
                    </div>
                }
                extra={
                    <div style={{ display: "flex", gap: "20px" }}>
                        <Popover content={content} placement="leftTop" title="Filter Tanggal" trigger="click">
                            <Button>
                                Date
                                {` ${moment(startDate).format('DD MMM YYYY')} - ${moment(endDate).format('DD MMM YYYY')}`}
                            </Button>
                        </Popover>
                        <Button className="order-products-center flex justify-center items-center text-white bg-blue-400" onClick={() => handleOpenModalAdd()}>
                            <IoIosAddCircle style={{ fontSize: "20px" }} />
                            Add ProductTransaction
                        </Button>
                    </div>
                }
            >
                <div className="table-responsive">
                    <Table
                        columns={column}
                        dataSource={dataSource}
                        pagination={false}
                        className="ant-border-space"
                        locale={{
                            emptyText: <Empty description="Data not found" />,
                        }}
                    />
                    <div style={{ padding: "20px", display: 'flex', justifyContent: "flex-end" }}>
                        <Pagination
                            current={pagination.currentPage}
                            total={pagination.totalItems}
                            pageSize={pagination.pageSize}
                            onChange={handlePageChange}
                            onShowSizeChange={handlePageSizeChange}
                            showSizeChanger
                            pageSizeOptions={['10', '20', '50', '100', '500', '1000']}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                    </div>
                </div>
            </Card>
        </>
    );
}

export default Index;