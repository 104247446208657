import React, { useEffect } from 'react';
import CopyButton from './CopyButton';
import ModalAddCustomer from './ModalAddCustomer';
import { Button } from 'antd';
import { MdEdit } from 'react-icons/md';
import { formatCurrency } from '../../helper/formatCurrency';
import { FaWhatsapp } from "react-icons/fa";


function CustomerInfo(props) {
    const { record } = props

    const [openModalAddCustomer, setOpenModalAddCustomer] = React.useState(false)

    return (
        <div>
            <ModalAddCustomer open={openModalAddCustomer} setOpen={setOpenModalAddCustomer} initialData={record?.customer} />
            <div style={{ display: 'flex' }}>
                <label style={{ fontSize: '14px', fontWeight: 'bold', color: "black" }}>{record?.customer?.name}</label>
                <CopyButton textToCopy={`${record?.customer?.name} \n wa.me/${record?.customer?.phone} \n ${record?.customer?.address} \n ${record?.customer?.gmaps} \n ${record?.payment?.status} \n ${formatCurrency(parseInt(record?.payment?.final_price))}`} />
            </div>
            <div><label>{record?.customer?.phone}</label></div>
            <div className='mt-2'><label className='font-bold'>Catatan: </label></div>
            <div className='mb-2'><label className=''>{record?.customer?.description}</label></div>

            <div className='flex items-center'>
                <Button size='small' style={{ height: '30px' }} onClick={() => setOpenModalAddCustomer(true)}>
                    <div className='flex items-center' style={{ marginTop: '-4px' }}>
                        <MdEdit />Edit
                    </div>
                </Button>

                <Button size='large' style={{ height: '30px' }} onClick={() => window.open(`https://web.whatsapp.com/send?phone=${record?.customer?.phone}`, '_blank')}>
                    <div style={{ marginTop: '-4px' }}>
                        <FaWhatsapp style={{ fontSize: '16px' }} />
                    </div>
                </Button>
            </div>

        </div>
    );
}

export default CustomerInfo;