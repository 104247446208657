import React, { useState, useEffect } from 'react';
import { FaMoneyBillWave } from 'react-icons/fa';
import { Card, Col, Row, Typography } from 'antd';
import ReactApexChart from "react-apexcharts";
import { fetchData } from '../../../helper/api';
import { parse, set } from 'date-fns';
import { formatCurrency } from '../../../helper/formatCurrency';
import { useQuery } from '../../../helper/useQuery';
import moment from 'moment';


function Index(props) {

    const { Title, Paragraph } = Typography;
    const [DataBox, setDataBox] = useState();
    const [dataGraph, setDataGraph] = useState();
    const [IncomeData, setIncomeData] = useState();
    const [RevenueData, setRevenueData] = useState();
    const [ExpenseData, setExpenseData] = useState();

    const query = useQuery();

    const startDate = query.get('startDate');
    const endDate = query.get('endDate');


    useEffect(() => {
        let url = '/genereteDashboardProfitOverview';
        if (startDate && endDate) {
            url = `/genereteDashboardProfitOverview?startDate=${startDate}&endDate=${endDate}`;
        }

        fetchData(url)
            .then((res) => {
                const data = res.data.data;
                console.log(data);
                setDataBox(data);
                setDataGraph(data);
                setIncomeData({
                    data: data?.TotalIncomeByMonth?.map((c) => c.value),
                    categories: data?.TotalIncomeByMonth?.map((c) => c.category)
                });
                setRevenueData({
                    data: data?.TotalRevenueByMonth?.map((c) => c.value),
                    categories: data?.TotalRevenueByMonth?.map((c) => c.category)
                });
                setExpenseData({
                    data: data?.TotalExpensesByMonth?.map((c) => c.value),
                    categories: data?.TotalExpenseByMonth?.map((c) => c.category)
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [startDate, endDate]);


    const lineChart = {
        series: [
            {
                name: "Expense",
                data: ExpenseData?.data ? ExpenseData.data : [],
                offsetY: 0,
                color: "#f44336"
            },
            {
                name: "Revenue",
                data: RevenueData?.data ? RevenueData.data : [],
                offsetY: 0,
                color: "#FFC107"
            },
            {
                name: "Income",
                data: IncomeData?.data ? IncomeData.data : [],
                offsetY: 0,
                color: "#4CAF50"
            },

        ],

        options: {
            chart: {
                width: "100%",
                height: 350,
                type: "area",
                toolbar: {
                    show: false,
                },
            },

            legend: {
                show: false,
            },

            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
            },

            yaxis: {
                labels: {
                    style: {
                        fontSize: "14px",
                        fontWeight: 600,
                    },
                    formatter: function (val) {
                        return formatCurrency(parseInt(val));
                    }
                },
            },

            xaxis: {
                labels: {
                    style: {
                        fontSize: "14px",
                        fontWeight: 600,
                    },
                },
                categories: [
                    ...ExpenseData?.categories ? ExpenseData.categories : [],
                    ...RevenueData?.categories ? RevenueData.categories : [],
                    ...IncomeData?.categories ? IncomeData.categories : [],
                ],
            },

            tooltip: {
                y: {
                    formatter: function (val) {
                        return formatCurrency(parseInt(val));
                    },
                },
            },
        },
    };


    const dataBoxList1 = [
        {
            name: `Income - Expense (${formatCurrency(parseInt(DataBox?.IncomeTotal || 0))} - ${formatCurrency(parseInt(DataBox?.ExpenseNotCapitalTotal || 0))})`,
            value: formatCurrency(parseInt(DataBox?.IncomeTotal || 0) - parseInt(DataBox?.ExpenseNotCapitalTotal || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: `Revenue - Expense (${formatCurrency(parseInt(DataBox?.RevenueTotal || 0))} - ${formatCurrency(parseInt(DataBox?.ExpenseNotCapitalTotal || 0))})`,
            value: formatCurrency(parseInt(DataBox?.RevenueTotal || 0) - parseInt(DataBox?.ExpenseNotCapitalTotal || 0)),
            icon: <FaMoneyBillWave />,
        }
    ];

    const dataBoxList2 = [
        {
            name: `Income - Expense with Capital (${formatCurrency(parseInt(DataBox?.IncomeTotal || 0))} - ${formatCurrency(parseInt(DataBox?.ExpenseWithCapitalTotal || 0))})`,
            value: formatCurrency(parseInt(DataBox?.IncomeTotal || 0) - parseInt(DataBox?.ExpenseWithCapitalTotal || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: `Revenue - Expense with Capital (${formatCurrency(parseInt(DataBox?.RevenueTotal || 0))} - ${formatCurrency(parseInt(DataBox?.ExpenseWithCapitalTotal || 0))})`,
            value: formatCurrency(parseInt(DataBox?.RevenueTotal || 0) - parseInt(DataBox?.ExpenseWithCapitalTotal || 0)),
            icon: <FaMoneyBillWave />,
        }
    ];
    return (
        <div>
            <p className='text-lg font-bold mb-2'>Operasional Expense</p>
            <Row className="rowgap-vbox" gutter={[24, 0]}>
                {dataBoxList1.map((c, index) => (
                    <Col
                        key={index}
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                        className="mb-4"
                    >
                        <Card bordered={false} className="criclebox ">
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col xs={18}>
                                        <span>{c.name}</span>
                                        <Title level={3}>
                                            {c.value ? c.value : 0}
                                        </Title>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="icon-box flex flex-col items-center justify-center" style={{ fontSize: '20px' }}>{c.icon}</div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
            <p className='text-lg font-bold mb-2'>Operasional Expense with Capital</p>
            <Row className="rowgap-vbox" gutter={[24, 0]}>
                {dataBoxList2.map((c, index) => (
                    <Col
                        key={index}
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                        className="mb-4"
                    >
                        <Card bordered={false} className="criclebox ">
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col xs={18}>
                                        <span>{c.name}</span>
                                        <Title level={3}>
                                            {c.value ? c.value : 0}
                                        </Title>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="icon-box flex flex-col items-center justify-center" style={{ fontSize: '20px' }}>{c.icon}</div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>

            {/* CHART */}
            <div>
                <Card>
                    <div className="linechart">
                        <div>
                            <Title level={5}>Income vs Revenue vs Expense</Title>
                            <Paragraph className="lastweek">
                                than last week <span className="bnb2">-%</span>
                            </Paragraph>
                        </div>
                    </div>

                    <ReactApexChart
                        className="full-width"
                        options={lineChart.options}
                        series={lineChart.series}
                        type="area"
                        height={350}
                        width={"100%"}
                    />

                    {/* <div className='grid grid-cols-4 gap-4 p-4'>
                    <div className='col-span-1 flex justify-center flex-col items-center'>
                        <h1 className="font-bold text-lg">{data?.average_overview?.averagePerDay?.toFixed(2)}</h1>
                        <p className="text-sm">Avg. Order/day</p>
                    </div>
                    <div className='col-span-1 flex justify-center flex-col items-center'>
                        <h1 className="font-bold text-lg">{data?.average_overview?.averagePerWeek?.toFixed(2)}</h1>
                        <p className="text-sm">Avg. Order/week</p>
                    </div>
                    <div className='col-span-1 flex justify-center flex-col items-center'>
                        <h1 className="font-bold text-lg">{data?.average_overview?.averageItemsPerOrder?.toFixed(2)}</h1>
                        <p className="text-sm">Avg. Item/Order</p>
                    </div>
                    <div className='col-span-1 flex justify-center flex-col items-center'>
                        <h1 className="font-bold text-lg">{data?.average_overview?.averageServicePerOrder?.toFixed(2)}</h1>
                        <p className="text-sm">Avg. Service/Order</p>
                    </div>
                </div> */}
                </Card>
            </div>
        </div>
    );
}

export default Index;