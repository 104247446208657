import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';
import { postData, putData } from '../../helper/api';
import { toast } from 'react-toastify';
import { formatCurrency } from '../../helper/formatCurrency';
import numeral from 'numeral';
import { useLocation, useHistory } from 'react-router-dom';
import { format } from 'date-fns';

const { Option } = Select;

const ModalAddTreatment = ({ open, setOpen, initialData, trigerFetchData, setTrigerFetchData }) => {
    const location = useLocation();
    const history = useHistory();

    const [form] = Form.useForm();

    const handleAddTreatment = () => {
        form.validateFields()
            .then((values) => {
                if (numeral(values.price).value() <= 0) {
                    throw new Error('Price must be greater than 0');
                }

                if (initialData) {
                    putData(`/treatments/${initialData.id}`, {
                        ...values,
                        name: values.type_item + " - " + values.type_service,
                        price: numeral(values.price).value(),
                    })
                        .then((response) => {
                            if (response.status === 200) {
                                toast.success('Treatment updated successfully');
                                setOpen(false);
                                form.resetFields();
                                setTrigerFetchData(!trigerFetchData);
                            }
                        })
                        .catch((error) => {
                            toast.error(error.response?.data?.message || 'Failed to update treatment');
                        });
                } else {
                    postData('/treatments', {
                        ...values,
                        name: values.type_item + " - " + values.type_service,
                        price: numeral(values.price).value(),
                    })
                        .then((response) => {
                            if (response.status === 201) {
                                toast.success('Treatment added successfully');
                                setOpen(false);
                                form.resetFields();
                                setTrigerFetchData(!trigerFetchData);
                            }
                        })
                        .catch((error) => {
                            toast.error(error.response?.data?.message || 'Failed to add treatment');
                        });
                }
            })
            .catch((errorInfo) => {
                toast.error('Please check all required fields');
            });
    };

    useEffect(() => {
        if (initialData) {
            form.setFieldsValue({
                name: initialData.name,
                price: numeral(initialData.price).format('0,0'),
                type_item: initialData.type_item,
                type_service: initialData.type_service,
                process_time: initialData.process_time,
                description: initialData.description,
            });
        }
    }, [initialData]);

    return (
        <Modal
            title={initialData ? 'Edit Treatment' : 'Add Treatment'}
            open={open}
            onCancel={() => setOpen(false)}
            onOk={() => setOpen(false)}
            footer={null}
        >
            <Form form={form} onFinish={handleAddTreatment}>
                <Form.Item
                    label="Type Item"
                    name="type_item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                        { required: true, message: 'Type item is required' },
                        { min: 3, message: 'Type item must be at least 3 characters' }
                    ]}
                >
                    <Input placeholder="Enter type item" />
                </Form.Item>

                <Form.Item
                    label="Type Service"
                    name="type_service"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                        { required: true, message: 'Type service is required' },
                        { min: 3, message: 'Type service must be at least 3 characters' }
                    ]}
                >
                    <Input placeholder="Enter type service" />
                </Form.Item>

                <Form.Item
                    label="Price"
                    name="price"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                        { required: true, message: 'Price is required' },
                        {
                            validator: (_, value) => {
                                if (!value) return Promise.resolve();
                                const price = numeral(value).value();
                                if (isNaN(price) || price <= 0) {
                                    return Promise.reject('Please enter a valid price');
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                >
                    <Input
                        type="text"
                        placeholder="Enter price"
                        onChange={(e) => {
                            const formattedValue = numeral(e.target.value).format('0,0');
                            form.setFieldsValue({ price: formattedValue });
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                        { required: true, message: 'Description is required' },
                        { min: 10, message: 'Description must be at least 10 characters' }
                    ]}
                >
                    <Input.TextArea placeholder="Enter description" />
                </Form.Item>

                <Form.Item>
                    <div className='flex justify-end'>
                        <Button
                            type="default"
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button className="items-center flex justify-center text-white bg-blue-400" htmlType="submit">
                            {initialData ? 'Update' : 'Add'}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalAddTreatment;
