import React, { useEffect } from 'react';
import { Table, Empty, Card, Input, Button, Popover, Pagination } from 'antd';
import { IoIosAddCircle } from "react-icons/io";
import { API_URL, fetchData } from '../../helper/api';
import { formatCurrency } from '../../helper/formatCurrency';
import ModalAddProduct from './ModalAddProduct';
import ActionProduct from './ActionProduct';
import { useLocation, useHistory } from 'react-router-dom';

function Index(props) {
    const [dataSource, setDataSource] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [trigerFetchData, setTrigerFetchData] = React.useState(false);
    const [listData, setListData] = React.useState([]);

    const location = useLocation();
    const history = useHistory();

    const [openModalAdd, setOpenModalAdd] = React.useState(false);

    const handleOpenModalAdd = () => {
        setOpenModalAdd(true);
    }

    const onSearch = (value) => {
        setSearchValue(value);
    }

    const mappingDataToDataSource = (data) => {
        return data.map((item, index) => {
            return {
                ...item,
                no: index + 1,
                price_display: formatCurrency(parseInt(item.price)),
            };
        });
    }

    useEffect(() => {
        const searchLocation = location.search;
        const newSearchLocation = searchLocation.replace(/&add_update=\d/, "");
        history.push({
            ...location,
            search: newSearchLocation,
        });
        // Fetch data
        let url = `/products?search=${encodeURIComponent(searchValue)}`;

        if (searchValue === "") {
            url = `/products`;
        }

        fetchData(url)
            .then((res) => {
                const data = res.data.data;
                const dataSource = mappingDataToDataSource(data);
                setDataSource(dataSource);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [location.search, trigerFetchData, searchValue])


    useEffect(() => {
        fetchData('/products')
        .then((res) => {
            const data = res.data.data;
            const dataSource = data
            setListData(dataSource);
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);

    const column = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <div className='flex gap-4 items-center'>
                    <img src={`${API_URL}/${record.images}`} alt={record.name} style={{ width: "50px", height: "50px", objectFit: "cover", borderRadius: "10%" }} />
                    <span style={{ marginLeft: "10px" }}>{record.name}</span>
                </div>
            ),
        },
        {
            title: 'Kategori',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            key: 'stock',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (text, record) => (
                <span>{formatCurrency(parseInt(record.price))}</span>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <ActionProduct record={record} trigerFetchData={trigerFetchData} setTrigerFetchData={setTrigerFetchData} />
            ),
        },
    ]

    return (
        <>
            <ModalAddProduct listData={listData} open={openModalAdd} setOpen={setOpenModalAdd} trigerFetchData={trigerFetchData} setTrigerFetchData={setTrigerFetchData} />
            <Card
                bordered={false}
                className="criclebox tablespace mb-4"
                title={
                    <div style={{ display: "flex", gap: "20px" }}>
                        <h4>Product</h4>
                        {/* <Input
                            style={{ width: "200px", fontSize: "12px" }}
                            placeholder="Search Name / Products / Service"
                            size="small"
                            onPressEnter={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch(e.target.value); // Gantilah dengan nama fungsi pencarian Anda
                                }
                            }}
                        /> */}
                    </div>
                }
                extra={
                    <div style={{ display: "flex", gap: "20px" }}>
                        <Button className="products-center flex justify-center items-center text-white bg-blue-400" onClick={() => handleOpenModalAdd()}>
                            <IoIosAddCircle style={{ fontSize: "20px" }} />
                            Add Products
                        </Button>
                    </div>
                }
            >
                <div className="table-responsive">
                    <Table
                        columns={column}
                        dataSource={dataSource}
                        pagination={false}
                        className="ant-border-space"
                        locale={{
                            emptyText: <Empty description="Data not found" />,
                        }}
                    />
                </div>
            </Card>
        </>
    );
}

export default Index;