import React, { useEffect } from 'react';
import { Form, Input, Button, Select, Upload, DatePicker, Modal } from 'antd';
import { TRANSACTION_DETAIL_OPTION } from '../helper';
import ModalAddItem from '../../Item/ModalAddItem';
import { WashingStatusOption, fileList } from '../../../models/orderData';
import { fetchData } from '../../../helper/api';
import { formatCurrency } from '../../../helper/formatCurrency';
import { calculateDiscount, calculateDiscountedPrice, calculateProcessTimeCost, calculateTotalPrice } from '../../../helper/Calculation';
import UploadDraggerItem from '../UploadDraggerItem';
import moment from 'moment';
import { PROCESS_TIME_OPTIONS } from '../../../models/itemData';
import { set } from 'date-fns';

const { Option } = Select;

function ModalTreatmentDetail(props) {
    const { formik, index, open, setOpen } = props;
    //index digunakan untuk menentukan index dari order_details yang akan di edit
    // jadi component yang memanggil ModalTreatmentDetail harus mengirimkan index

    // useState Employee
    const [employeeDataOption, setEmployeeDataOption] = React.useState([]);

    // Treatment
    const [treatmentDataOption, setTreatmentDataOption] = React.useState([]);

    // Item
    const [itemDataOption, setItemDataOption] = React.useState([]);
    const [listDataItem, setListDataItem] = React.useState([]);

    // Discount
    const [discountDataOption, setDiscountDataOption] = React.useState([]);
    const [discount, setDiscount] = React.useState(0);
    const [price, setPrice] = React.useState(0);


    useEffect(() => {
        // Get Data Employee
        fetchData('/users')
            .then((response) => {
                const data = response.data.data;
                const options = data.map((item) => ({
                    label: item.name,
                    value: item.id,
                    ...item,
                }));
                setEmployeeDataOption(options);
            })
            .catch((error) => {
                console.log(error);
            });

        // Get Data Treatment  
        fetchData('/treatments')
            .then((response) => {
                const data = response.data.data;
                const options = data.map((item) => ({
                    label: item.name,
                    value: item.id,
                    ...item,
                }));
                setTreatmentDataOption(options);
            })
            .catch((error) => {
                console.log(error);
            });

        // Get Data Item
        fetchData('/items')
            .then((response) => {
                const data = response.data.data;
                const options = data.map((item) => ({
                    label: `${item.brand} - ${item.name} - ${item.color} - ${item.type}`,
                    value: item.id,
                    ...item,
                }));
                setItemDataOption(options);
                setListDataItem(data);
            })
            .catch((error) => {
                console.log(error);
            });

        // Get Data Discount
        fetchData('/discounts')
            .then((response) => {
                const data = response.data.data;
                const options = data.map((item) => ({
                    label: item.name,
                    value: item.id,
                    ...item,
                }));
                setDiscountDataOption(options);
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);


    return (
        <div>
            <Modal
                title={true ? 'Edit Treatment' : 'Add Treatment'}
                open={open}
                onCancel={() => setOpen(false)}
                footer={null}
            >

                <Form>
                    <div style={{ marginBottom: '10px' }}>
                        <label style={{ fontWeight: 'bold' }}>Item {index + 1}</label>
                    </div>
                    <Form.Item>
                        <div><label>Upload Photo : </label></div>
                        {/* <UploadDraggerItem formik={formik} index={index} /> */}
                    </Form.Item>
                    <Form.Item>
                        <div><label>Recipient : </label></div>
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select a employee"
                            optionFilterProp="children"
                            value={index === null ? '' : formik?.values?.order_details[index]?.employee_id}
                            onChange={(value) => {
                                formik.setFieldValue(`order_details[${index}].employee_id`, value)
                            }}
                        >
                            {employeeDataOption.map((item) => (
                                <Option key={item.value} value={item.value}>{item.label}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <div><label>Treatment : </label></div>
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select a treatment"
                            optionFilterProp="children"
                            value={index === null ? '' : formik?.values?.order_details[index]?.treatment_id}
                            disabled
                        >
                            {treatmentDataOption.map((item) => (
                                <Option key={item.value} item={item} value={item.value}>{item.label}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <div><label>Item : </label></div>
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select a item"
                            optionFilterProp="children"
                            value={index === null ? '' : formik?.values?.order_details[index]?.item_id}
                            onChange={(value, option) => {
                                formik.setFieldValue(`order_details[${index}].item_id`, value)
                                formik.setFieldValue(`order_details[${index}].item_data`, option.item)
                            }}
                        >
                            {itemDataOption.map((item) => (
                                <Option key={item.value} item={item} value={item.value}>{item.label}</Option>
                            ))}
                        </Select>

                        <Button type="link" onClick={() => { }}>
                            New Item
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <div><label>Process Time : </label></div>
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select a process time"
                            optionFilterProp="children"
                            value={index === null ? '' : formik?.values?.order_details[index]?.treatment_data?.process_time_name}
                            // onChange={(value, option) => {
                            //     formik.setFieldValue(`order_details[${index}].process_time`, value)
                            //     formik.setFieldValue(`order_details[${index}].process_time_cost`, option.item.percent * formik?.values?.order_details[index]?.treatment?.price)
                            // }}
                            disabled
                        >
                            {/* {processTimeDataOption.map((item) => (
                                <Option key={item.value} item={item} value={item.value}>{item.label}</Option>
                            ))} */}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <div><label>Due Date : </label></div>
                        <DatePicker
                            style={{ width: '100%' }}
                            format={"dddd, DD MMMM YYYY"}
                            value={index === null ? '' : moment(formik?.values?.order_details[index]?.due_date)}
                        // onChange={(value) => { formik.setFieldValue(`order_details[${index}].due_date`, value) }}
                        />
                    </Form.Item>

                    <Form.Item>
                        <div><label>Discount : </label></div>
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select a discount"
                            optionFilterProp="children"
                            value={index === null ? '' : (formik?.values?.order_details[index]?.discount_id === '' ? discountDataOption.find(item => parseInt(item.discount) === 0)?.id : formik?.values?.order_details[index]?.discount_id)}
                            onChange={(value, option) => {
                                formik.setFieldValue(`order_details[${index}].discount_id`, value || null)
                                formik.setFieldValue(`order_details[${index}].discount_data`, option.item)
                                const discount = calculateDiscountedPrice(
                                    formik?.values?.order_details[index]?.treatment_data?.final_price_with_process_time,
                                    option.item
                                )

                                formik.setFieldValue(`order_details[${index}].treatment_data.discount_nominal_for_item`, discount.discount_nominal)   
                                formik.setFieldValue(`order_details[${index}].treatment_data.final_price_with_process_time_and_discount`, discount.final_price)   
                            }}
                        >
                            {discountDataOption.map((item) => (
                                <Option key={item.value} item={item} value={item.value}>{item.label}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {/* <Form.Item>
                        <div><label>Price : </label></div>
                        <Input
                            type='text'
                            disabled
                            value={index === null ? '' : formatCurrency(parseInt(formik?.values?.order_details[index]?.price || 0))}
                        />
                    </Form.Item>
                    <Form.Item>
                        <div><label>Final Price : </label></div>
                        <Input
                            type='text'
                            disabled
                            value={index === null ? '' : formatCurrency(parseInt(formik?.values?.order_details[index]?.final_price || 0))}
                        />
                    </Form.Item> */}

                    <Form.Item>
                        <div><label>Status : </label></div>
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select a status"
                            optionFilterProp="children"
                            value={index === null ? '' : formik?.values?.order_details[index]?.status}
                            onChange={(value) => { formik.setFieldValue(`order_details[${index}].status`, value) }}
                        >
                            {WashingStatusOption.map((item) => (
                                <Option key={item.value} value={item.value}>{item.label}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <div><label>Description : </label></div>
                        <Input
                            type='text'
                            value={index === null ? '' : formik?.values?.order_details[index]?.description}
                            onChange={(event) => {
                                const value = event.target.value;
                                formik.setFieldValue(`order_details[${index}].description`, value);
                            }}
                        />
                    </Form.Item>


                    <div className='flex flex-col gap-2 border-2 rounded-lg p-3 mb-3'>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span style={{ fontWeight: 'bold' }}>Price:</span>
                            <span>{index === null ? formatCurrency(parseInt(0)) : formatCurrency(parseInt(formik?.values?.order_details[index]?.treatment_data?.final_price_with_process_time))}</span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span style={{ fontWeight: 'bold' }}>Discount:</span>
                            <span>{index === null ? formatCurrency(parseInt(0)) : formatCurrency(parseInt(formik?.values?.order_details[index]?.treatment_data?.discount_nominal_for_item) || 0)}</span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span style={{ fontWeight: 'bold' }}>Final Price:</span>
                            <span>{index === null ? formatCurrency(parseInt(0)) : formatCurrency(parseInt(formik?.values?.order_details[index]?.treatment_data?.final_price_with_process_time_and_discount) || 0)}</span>
                        </div>
                    </div>

                    <div style={{ marginBottom: '10px', display: 'flex', gap: "10px", justifyContent: "flex-end" }}>
                        <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => setOpen(false)}>
                            Close
                        </Button>
                        {/* <Button type="dash" htmlType="button" className="login-form-button" onClick={() => { }}>
                            Cancel
                        </Button> */}
                    </div>
                </Form >
                {/* <ModalAddItem listData={listDataItem} open={ModalOpenItem} setOpen={setModalOpenItem} /> */}
            </Modal>

        </div >
    );
}

export default ModalTreatmentDetail;