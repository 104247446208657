import { useState } from 'react';
import { message } from 'antd';
import { fetchLeadReports } from '../api';

export const useLeadsReport = () => {
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchReport = async (startDate, endDate) => {
    try {
      setLoading(true);
      const response = await fetchLeadReports(startDate, endDate);
      setReport(response.data);
    } catch (error) {
      message.error('Gagal memuat laporan');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    report,
    loading,
    fetchReport
  };
}; 