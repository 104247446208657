import React from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import formatPhoneNumber from '../helper/formatPhoneNumber';

const ExportCustomerCSV = ({ dataSource, page = 1, limit = 100 }) => {
    const handleExport = () => {

        const startIndex = (page - 1) * limit;
        const endIndex = startIndex + limit;
        const dataToExport = dataSource.slice(startIndex, endIndex);

        // Persiapkan data
        const csvData = dataToExport.map(customer => ({
            name: `Cust ${customer.name}`,
            phone: formatPhoneNumber(customer.phone).toString()
        }));

        // Buat header CSV
        const headers = ['name,phone'];
        
        // Convert data ke format CSV
        const csvRows = csvData.map(row => 
            `"${row.name}","${row.phone}"`
        );
        
        // Gabungkan semua baris
        const csvString = [...headers, ...csvRows].join('\n');
        
        // Buat blob dan download
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        
        link.setAttribute('href', url);
        link.setAttribute('download', `customers_${new Date().toISOString().split('T')[0]}.csv`);
        link.style.visibility = 'hidden';
        
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Button 
            icon={<DownloadOutlined />}
            onClick={handleExport}
            className="bg-green-500 hover:bg-green-600 text-white"
            style={{ color: "white" }}
        >
            Export CSV
        </Button>
    );
};

export default ExportCustomerCSV;