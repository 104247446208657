import React, { useEffect } from 'react';
import { Table, Empty, Card, Input, Button, Popover, Pagination } from 'antd';
import { IoIosAddCircle } from "react-icons/io";
import { API_URL, fetchData } from '../../helper/api';
import { formatCurrency } from '../../helper/formatCurrency';
import ModalAddCollaboration from './ModalAddCollaboration';
import ActionCollaboration from './ActionCollaboration';
import { useLocation, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

function Index(props) {
    const [dataSource, setDataSource] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [trigerFetchData, setTrigerFetchData] = React.useState(false);
    const [listData, setListData] = React.useState([]);

    const location = useLocation();
    const history = useHistory();

    const [openModalAdd, setOpenModalAdd] = React.useState(false);

    const handleOpenModalAdd = () => {
        setOpenModalAdd(true);
    }

    const onSearch = (value) => {
        setSearchValue(value);
    }

    const mappingDataToDataSource = (data) => {
        return data.map((item, index) => {
            return {
                ...item,
                no: index + 1,
                price_display: formatCurrency(parseInt(item.price)),
            };
        });
    }

    useEffect(() => {
        const searchLocation = location.search;
        const newSearchLocation = searchLocation.replace(/&add_update=\d/, "");
        history.push({
            ...location,
            search: newSearchLocation,
        });
        // Fetch data
        let url = `/collaborations?search=${encodeURIComponent(searchValue)}`;

        if (searchValue === "") {
            url = `/collaborations`;
        }

        fetchData(url)
            .then((res) => {
                const data = res.data.data;
                const dataSource = mappingDataToDataSource(data);
                setDataSource(dataSource);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [location.search, trigerFetchData, searchValue])


    useEffect(() => {
        fetchData('/collaborations')
            .then((res) => {
                const data = res.data.data;
                const dataSource = data
                setListData(dataSource);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const column = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'PIC Name',
            dataIndex: 'pic_name',
            key: 'pic_name',
            render: (text, record) => (
                <div className='flex flex-col'>
                    <span className='font-bold'>{record.business.pic_name}</span>
                    <span>{record.business.pic_phone}</span>
                </div>
            ),
        },
        {
            title: 'Business Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span>{record.business.name}</span>
            ),
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            render: (text, record) => (
                <span>{dayjs(record.start_date).format('DD MMMM YYYY')}</span>
            ),
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
            render: (text, record) => (
                <span>{dayjs(record.end_date).format('DD MMMM YYYY')}</span>

            ),
        },
        {
            title: 'Kategori',
            dataIndex: 'category',
            key: 'category',
            render: (text, record) => (
                <span>{record.business.type}</span>
            ),
        },
        
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <span>{record.business.is_active === 'active' ? 'Active' : 'Inactive'}</span>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <ActionCollaboration record={record} trigerFetchData={trigerFetchData} setTrigerFetchData={setTrigerFetchData} />
            ),
        },
    ]

    return (
        <>
            <ModalAddCollaboration listData={listData} open={openModalAdd} setOpen={setOpenModalAdd} trigerFetchData={trigerFetchData} setTrigerFetchData={setTrigerFetchData} />
            <Card
                bordered={false}
                className="criclebox tablespace mb-4"
                title={
                    <div style={{ display: "flex", gap: "20px" }}>
                        <h4>Collaboration</h4>
                        <Input
                            style={{ width: "200px", fontSize: "12px" }}
                            placeholder="Search PIC Name / Business Name"
                            size="small"
                            onPressEnter={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch(e.target.value); // Gantilah dengan nama fungsi pencarian Anda
                                }
                            }}
                        />
                    </div>
                }
                extra={
                    <div style={{ display: "flex", gap: "20px" }}>
                        <Button className="collaborations-center flex justify-center items-center text-white bg-blue-400" onClick={() => handleOpenModalAdd()}>
                            <IoIosAddCircle style={{ fontSize: "20px" }} />
                            Add Collaborations
                        </Button>
                    </div>
                }
            >
                <div className="table-responsive">
                    <Table
                        columns={column}
                        dataSource={dataSource}
                        pagination={false}
                        className="ant-border-space"
                        locale={{
                            emptyText: <Empty description="Data not found" />,
                        }}
                    />
                </div>
            </Card>
        </>
    );
}

export default Index;