import React, { useEffect } from 'react';
import { Modal, Form, Select, Button, DatePicker, TimePicker } from 'antd';
import { fetchData, postData, putData } from '../../helper/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useFormik } from 'formik';

const { Option } = Select;

const ModalAddAbsent = ({ open, setOpen, initialData, trigerFetchData, setTrigerFetchData }) => {
    const [employees, setEmployees] = React.useState([]);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            employee_id: '',
            date: moment(),
            status: '',
            clock_in: null,
            clock_out: null,
            description: '',
            branch_id: 1, // Sesuaikan dengan kebutuhan
        },
        validate: values => {
            const errors = {};
            if (!values.employee_id) errors.employee_id = 'Karyawan wajib dipilih';
            if (!values.date) errors.date = 'Tanggal wajib diisi';
            if (!values.status) errors.status = 'Status wajib dipilih';
            if (!values.clock_in) errors.clock_in = 'Jam masuk wajib diisi';
            if (!values.clock_out) errors.clock_out = 'Jam keluar wajib diisi';
            return errors;
        },
        onSubmit: async (values) => {
            if (isSubmitting) return;
            setIsSubmitting(true);

            try {
                const formattedData = {
                    ...values,
                    date: moment(values.date).format('YYYY-MM-DD'),
                    clock_in: values.clock_in ? moment(values.clock_in).format('HH:mm:ss') : null,
                    clock_out: values.clock_out ? moment(values.clock_out).format('HH:mm:ss') : null,
                };

                if (initialData) {
                    const response = await putData(`/absents/${initialData.id}`, formattedData);
                    if (response.status === 200) {
                        toast.success('Absen berhasil diperbarui');
                        setOpen(false);
                        formik.resetForm();
                        setTrigerFetchData(!trigerFetchData);
                    }
                } else {
                    const response = await postData('/absents', formattedData);
                    if (response.status === 201) {
                        toast.success('Absen berhasil ditambahkan');
                        setOpen(false);
                        formik.resetForm();
                        setTrigerFetchData(!trigerFetchData);
                    }
                }
            } catch (error) {
                toast.error(error.response?.data?.message || 'Terjadi kesalahan');
            } finally {
                setIsSubmitting(false);
            }
        }
    });

    useEffect(() => {
        // Fetch data karyawan
        fetchData('/users')
            .then((res) => {
                const data = res.data.data;
                // Filter hanya karyawan dengan status active
                const activeEmployees = data.filter(item => item.status === 'active');
                setEmployees(activeEmployees.map((item) => ({
                    id: item.id,
                    name: item.name,
                    item: item
                })));
            })
            .catch((err) => {
                console.error(err);
                toast.error('Gagal mengambil data karyawan');
            });

        // Set initial values jika ada
        if (initialData) {
            formik.setValues({
                ...initialData,
                date: moment(initialData.date),
                clock_in: initialData.clock_in ? moment(initialData.clock_in, 'HH:mm:ss') : null,
                clock_out: initialData.clock_out ? moment(initialData.clock_out, 'HH:mm:ss') : null,
            });
        }
    }, [initialData]);

    return (
        <Modal
            title={`${initialData ? 'Edit' : 'Add'} Absent`}
            open={open}
            onCancel={() => {
                if (!isSubmitting) {
                    setOpen(false);
                    formik.resetForm();
                }
            }}
            footer={null}
            closable={!isSubmitting}
            maskClosable={!isSubmitting}
        >
            <Form layout="vertical">
                <Form.Item
                    label="Employee"
                    validateStatus={formik.errors.employee_id && formik.touched.employee_id ? 'error' : ''}
                    help={formik.touched.employee_id && formik.errors.employee_id}
                >
                    <Select
                        placeholder="Choose Employee"
                        value={formik.values.employee_id}
                        onChange={(value) => formik.setFieldValue('employee_id', value)}
                        onBlur={() => formik.setFieldTouched('employee_id', true)}
                        showSearch
                        optionFilterProp="children"
                    >
                        {employees.map((employee) => (
                            <Option key={employee.id} value={employee.id}>
                                {employee.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Date"
                    validateStatus={formik.errors.date && formik.touched.date ? 'error' : ''}
                    help={formik.touched.date && formik.errors.date}
                >
                    <DatePicker
                        style={{ width: '100%' }}
                        value={formik.values.date}
                        onChange={(date) => formik.setFieldValue('date', date)}
                        onBlur={() => formik.setFieldTouched('date', true)}
                        format="YYYY-MM-DD"
                        disabledDate={(current) => current && current > moment().endOf('day')}
                    />
                </Form.Item>

                <Form.Item
                    label="Status"
                    validateStatus={formik.errors.status && formik.touched.status ? 'error' : ''}
                    help={formik.touched.status && formik.errors.status}
                >
                    <Select
                        placeholder="Choose Status"
                        value={formik.values.status}
                        onChange={(value) => formik.setFieldValue('status', value)}
                        onBlur={() => formik.setFieldTouched('status', true)}
                    >
                        <Option value="Present">Present (Hadir)</Option>
                        <Option value="Absent">Absent (Tanpa Keterangan)</Option>
                        <Option value="DayOffPersonal">Ambil Libur Mingguan</Option>
                        <Option value="DayOffGeneral">Cuti Bersama</Option>
                        <Option value="Sick">Sick Leave (Sakit)</Option>
                        <Option value="PersonalLeave">Personal Leave (Izin Pribadi)</Option>
                        <Option value="EmergencyLeave">Emergency Leave (Izin Darurat)</Option>
                        <Option value="AnnualLeave">Annual Leave (Cuti Tahunan)</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Clock In"
                    validateStatus={formik.errors.clock_in && formik.touched.clock_in ? 'error' : ''}
                    help={formik.touched.clock_in && formik.errors.clock_in}
                >
                    <TimePicker
                        style={{ width: '100%' }}
                        value={formik.values.clock_in}
                        onChange={(time) => formik.setFieldValue('clock_in', time)}
                        onBlur={() => formik.setFieldTouched('clock_in', true)}
                        format="HH:mm:ss"
                        placeholder="Choose Clock In"
                    />
                </Form.Item>

                <Form.Item
                    label="Clock Out"
                >
                    <TimePicker
                        style={{ width: '100%' }}
                        value={formik.values.clock_out}
                        onChange={(time) => formik.setFieldValue('clock_out', time)}
                        format="HH:mm:ss"
                        placeholder="Choose Clock Out"
                    />
                </Form.Item>

                <Form.Item
                    label="Description"
                >
                    <Select
                        placeholder="Choose Description"
                        value={formik.values.description}
                        onChange={(value) => formik.setFieldValue('description', value)}
                    >
                        <Option value="Regular Absent">Regular Absent</Option>
                        <Option value="Late">Late (Terlambat)</Option>
                        <Option value="Early Leave">Early Leave (Pulang Awal)</Option>
                        <Option value="Absent">Tanpa Keterangan</Option>
                        <Option value="DayOffPersonal">Ambil Libur Mingguan</Option>
                        <Option value="DayOffGeneral">Cuti Bersama</Option>
                        <Option value="Sick">Sick Leave (Sakit)</Option>
                        <Option value="PersonalLeave">Personal Leave (Izin Pribadi)</Option>
                        <Option value="EmergencyLeave">Emergency Leave (Izin Darurat)</Option>
                        <Option value="AnnualLeave">Annual Leave (Cuti Tahunan)</Option>
                    </Select>
                </Form.Item>

                <Form.Item style={{ marginBottom: 0, textAlign: 'right' }}>
                    <Button
                        type="default"
                        style={{ marginRight: 8 }}
                        onClick={() => {
                            if (!isSubmitting) {
                                setOpen(false);
                                formik.resetForm();
                            }
                        }}
                        disabled={isSubmitting}
                    >
                        Batal
                    </Button>
                    <Button
                        type="primary"
                        onClick={formik.handleSubmit}
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        className="bg-blue-500"
                    >
                        {isSubmitting ? 'Saving...' : 'Add'}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalAddAbsent;
