import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, Button, DatePicker } from 'antd';
import { postData, putData } from '../../helper/api';
import { toast } from 'react-toastify';
import { formatCurrency } from '../../helper/formatCurrency';
import numeral from 'numeral';
import { useLocation, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import moment from 'moment';
import dayjs from 'dayjs';

const { Option } = Select;

const ModalAddBusiness = ({ open, setOpen, initialData, trigerFetchData, setTrigerFetchData }) => {
    const location = useLocation();
    const history = useHistory();

    const [form] = Form.useForm();

    const handleAddBusiness = () => {
        form.validateFields().then((values) => {
            if (initialData) {
                putData(`/businesses/${initialData.id}`, {
                    ...values,
                })
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success('Business updated successfully');
                            setOpen(false);
                            form.resetFields();
                            setTrigerFetchData(!trigerFetchData);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                postData('/businesses', {
                    ...values,
                    branch_id: 1,
                })
                    .then((response) => {
                        if (response.status === 201) {
                            toast.success('Business added successfully');
                            setOpen(false);
                            form.resetFields();
                            setTrigerFetchData(!trigerFetchData);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        });
    };

    useEffect(() => {
        if (initialData) {
            form.setFieldsValue({
                ...initialData,
            });
        }
    }, [initialData]);

    return (
        <Modal
            title="Add Business"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={() => setOpen(false)}
            footer={null}
        >
            <Form form={form} onFinish={handleAddBusiness}>
                <Form.Item
                    label="Name"
                    name="name"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the name' }]}
                >
                    <Input placeholder="Enter name" />
                </Form.Item>

                <Form.Item
                    label="Type"
                    name="type"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the type' }]}
                >
                    <Select placeholder="Select type">
                        <Option value="UKM">UKM</Option>
                        <Option value="Jual Sepatu">Jual Sepatu</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="pic_name"
                    name="pic_name"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Input placeholder="Enter pic_name" />
                </Form.Item>

                <Form.Item
                    label="pic_phone"
                    name="pic_phone"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the PIC Phone' }]}
                >
                    <Input placeholder="Enter PIC Phone" />
                </Form.Item>

                <Form.Item
                    label="Address"
                    name="address"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the address' }]}
                >
                    <Input placeholder="Enter address" />
                </Form.Item>

                <Form.Item
                    label="Is Active"
                    name="is_active"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please describe is active' }]}
                >
                    <Select placeholder="Select status">
                        <Option value="active">Active</Option>
                        <Option value="inactive">Inactive</Option>
                    </Select>
                </Form.Item>

                <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className='flex gap-2'>
                        <Button
                            type="default"
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button className="items-center flex justify-center text-white bg-blue-400" htmlType="submit">
                            {initialData ? 'Update' : 'Add'}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal >
    );
};

export default ModalAddBusiness;
