import { Form, Input, Select, Modal } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { followUpStatuses, sourceOptions } from '../constants';

const LeadForm = ({ visible, onCancel, onSubmit, initialValues }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(initialValues);
    }
  }, [visible, initialValues, form]);


  return (
    <Modal
      title={initialValues ? 'Edit Lead' : 'Tambah Lead Baru'}
      open={visible}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => form.submit()}
      confirmLoading={loading}
      okText="Simpan"
      cancelText="Batal"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={async (values) => {
          setLoading(true);
          try {
            await onSubmit(values);
            form.resetFields();
          } finally {
            setLoading(false);
          }
        }}
      >
        <Form.Item
          name="name"
          label="Nama"
          rules={[{ required: true, message: 'Nama harus diisi' }]}
        >
          <Input placeholder='Nama' />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Telepon"
          rules={[
            { required: true, message: 'Nomor telepon harus diisi' },
          ]}

        >
          <Input placeholder='628xxxx'/>
        </Form.Item>


        {/* status */}
        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: 'Status harus dipilih' }]}
        >
          <Select options={followUpStatuses} />
        </Form.Item>

        <Form.Item
          name="source"
          label="Sumber"
          rules={[{ required: true, message: 'Sumber harus dipilih' }]}
        >
          <Select options={sourceOptions} />
        </Form.Item>

        <Form.Item name="notes" label="Catatan">
          <Input.TextArea rows={4} placeholder='Catatan' />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LeadForm;