import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, Button, DatePicker } from 'antd';
import { postData, putData } from '../../helper/api';
import { toast } from 'react-toastify';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';

const { Option } = Select;

const ModalAddCustomer = ({ open, setOpen, initialData, trigerFetchData, setTrigerFetchData }) => {
    const location = useLocation();
    const history = useHistory();

    const [form] = Form.useForm();

    const handleAddCustomer = () => {
        form.validateFields().then((values) => {
            if (initialData) {
                putData(`/customers/${initialData.id}`, {
                    ...values,
                })
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success('Customer updated successfully');
                            setOpen(false);
                            form.resetFields();
                            setTrigerFetchData(!trigerFetchData);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                postData('/customers', {
                    ...values,
                })
                    .then((response) => {
                        if (response.status === 201) {
                            toast.success('Customer added successfully');
                            setOpen(false);
                            form.resetFields();
                            setTrigerFetchData(!trigerFetchData);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        });
    };

    useEffect(() => {
        if (initialData) {
            form.setFieldsValue({
                ...initialData,
                date_birthday: initialData.date_birthday ? moment(initialData.date_birthday, 'YYYY-MM-DD') : null,
            });
        }
    }, [initialData]);

    return (
        <Modal
            title="Add Customer"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={() => setOpen(false)}
            footer={null}
        >
            <Form form={form} onFinish={handleAddCustomer}>
                <Form.Item
                    label="Name"
                    name="name"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the name' }]}
                >
                    <Input placeholder="Enter name" />
                </Form.Item>

                <Form.Item
                    label="Phone"
                    name="phone"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the phone number' }]}
                >
                    <Input placeholder="Enter phone number" />
                </Form.Item>

                <Form.Item
                    label="Address"
                    name="address"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the address' }]}
                >
                    <Input placeholder="Enter address" />
                </Form.Item>
                <Form.Item
                    label="GMaps"
                    name="gmaps"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the gmaps link' }]}
                >
                    <Input placeholder="Enter gmaps" />
                </Form.Item>


                <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className='flex gap-2'>
                    <Button
                        type="default"
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button className="items-center flex justify-center text-white bg-blue-400" htmlType="submit">
                        {initialData ? 'Update' : 'Add'}
                    </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal >
    );
};

export default ModalAddCustomer;
