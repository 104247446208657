import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { fetchData } from "../../helper/api";
import { useGeneral } from "../../contexts/GeneralContext";

const DeleteButtonWithValidation = ({ setOpenModalDelete }) => {
  const { getProfile } = useGeneral();
  const [userRole, setUserRole] = useState(null); // Menyimpan role user
  const [loading, setLoading] = useState(true); // Indikator loading data

  useEffect(() => {
    setUserRole(getProfile?.role);
  }, [getProfile]);

  // Jika masih loading, tampilkan loader atau jangan tampilkan apa pun
  if (loading) return null;

  // Hanya render tombol jika userRole adalah 'superadmin'
  return (
    userRole === "superadmin" && (
      <Button
        className="items-center flex justify-center text-white bg-blue-400"
        danger
        onClick={() => setOpenModalDelete(true)}
      >
        Delete
      </Button>
    )
  );
};

export default DeleteButtonWithValidation;
