import React, { useEffect } from 'react';
import { Table, Empty, Card, Input, Button, Radio, Popover, Pagination } from 'antd';
import { IoIosAddCircle } from "react-icons/io";
import { fetchData } from '../../helper/api';
import { formatCurrency } from '../../helper/formatCurrency';
import ModalAddCustomer from './ModalAddCustomer';
import ActionCustomer from './ActionCustomer';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import cleanPhoneNumber from '../../helper/cleanPhoneNumber';
import CopyButton from '../component/CopyButton';
import { BiBrain } from "react-icons/bi";
import { RiAiGenerate } from "react-icons/ri";
import { TbBrain } from "react-icons/tb";
import ExportCustomerCSV from './components/ExportCustomerCSV';

function Index(props) {
    const [dataSource, setDataSource] = React.useState([]);
    const [dataFollowUp, setDataFollowUp] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [trigerFetchData, setTrigerFetchData] = React.useState(false);
    const [radioButton, setRadioButton] = React.useState('index');
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(100);

    const location = useLocation();
    const history = useHistory();

    const [openModalAdd, setOpenModalAdd] = React.useState(false);

    const handleOpenModalAdd = () => {
        setOpenModalAdd(true);
    }

    const onSearch = (value) => {
        setSearchValue(value);
    }

    const mappingDataToDataSource = (data) => {
        return data.map((item, index) => {
            return {
                ...item,
            };
        });
    }
    const mappingDataFUToDataSource = (data) => {   
        return data.map((item, index) => {
            return {
                id: item.customerId,
                name: item.customerName,
                phone: item.customerData?.phone,
                total_transaction: item.totalTransactions,
                last_transaction: moment(item.lastTransactionDate).format('DD MMM YYYY'),
                days_since_last_transaction: item.daysSinceLastTransaction,
                customerData: item.customerData,
            };
        });
    }

    useEffect(() => {
        const searchLocation = location.search;
        const newSearchLocation = searchLocation.replace(/&add_update=\d/, "");
        history.push({
            ...location,
            search: newSearchLocation,
        });
        // Fetch data
        let url = `/customers?search=${encodeURIComponent(searchValue)}`;

        if (searchValue === "") {
            url = `/customers`;
        }

        fetchData(url)
            .then((res) => {
                const data = res.data.data;
                const dataSource = mappingDataToDataSource(data);
                setDataSource(dataSource);
            })
            .catch((err) => {
                console.log(err);
            });

        fetchData(`/customers/follow_up`)
            .then((res) => {
                const data = res.data.data;
                const dataSource = mappingDataFUToDataSource(data);
                setDataFollowUp(dataSource);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [location.search, trigerFetchData, searchValue])


    const column = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => (
                <div className='flex gap-2 items-center'>
                    {text}
                    <CopyButton textToCopy={`${record?.name} \n wa.me/${record?.phone} \n ${record?.address}`} />
                    <Button
                        size="small"
                        onClick={() => history.push(`/customer-pattern/${record.id}`)}
                        className="flex items-center gap-1 text-gray-600 hover:text-blue-600 border border-gray-300 hover:border-blue-400 rounded-md px-2 py-1 text-xs transition-colors"
                    >
                        <TbBrain className="text-sm" /> 
                        Analysis
                    </Button>
                </div>
            ),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render: (text) => (
                <div style={{ width: '300px', maxWidth: '300px', overflow: 'hidden', whiteSpace: 'pre-line' }}>
                    {text}
                </div>
            ),
        },
        {
            title: 'GMaps',
            dataIndex: 'gmaps',
            key: 'gmaps',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <ActionCustomer record={record} trigerFetchData={trigerFetchData} setTrigerFetchData={setTrigerFetchData} />
            ),
        },
    ]


    const columnFollowUp = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <div className='flex gap-2 items-center'>
                    {text}
                    <CopyButton textToCopy={`${record.customerData?.name} \n wa.me/${record.customerData?.phone} \n ${record.customerData?.address}`} />
                </div>
            ),
        },
        {
            title: 'No Hp',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Total Transaction',
            dataIndex: 'total_transaction',
            key: 'total_transaction',
        },
        {
            title: 'Last Transaction',
            dataIndex: 'last_transaction',
            key: 'last_transaction',
        },
        {
            title: 'Days Since Last Transaction',
            dataIndex: 'days_since_last_transaction',
            key: 'days_since_last_transaction',
            render: (text) => (
                <div style={{ overflow: 'hidden', whiteSpace: 'pre-line' }}>
                    {text} days ago
                </div>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <div className='flex gap-2'>
                    <Button className="items-center flex justify-center text-white bg-green-600" onClick={() => {
                        window.open(`https://web.whatsapp.com/send?phone=${cleanPhoneNumber(record.customerData?.phone)}`, '_blank');
                    }}>WA Web</Button>
                    <Button className="items-center flex justify-center text-white bg-green-400" onClick={() => {
                        window.open(`https://api.whatsapp.com/send?phone=${cleanPhoneNumber(record.customerData?.phone)}`, '_blank');
                    }}>WA Apps</Button>
                </div>
            ),
        },
    ]

    const optionRadioButton = [
        {
            label: 'Data Customer',
            value: 'index',
        },
        {
            label: 'Follow Up',
            value: 'follow_up',
        }
    ]

    return (
        <>
            <ModalAddCustomer open={openModalAdd} setOpen={setOpenModalAdd} trigerFetchData={trigerFetchData} setTrigerFetchData={setTrigerFetchData} />
            <Radio.Group
                options={optionRadioButton}
                onChange={(e) => {
                    setRadioButton(e.target.value);
                }}
                value={radioButton}
                optionType="button"
                buttonStyle="solid"
                className="mb-4"
            />
            {radioButton === 'follow_up' ? (
                <Card
                    bordered={false}
                    className="criclebox tablespace mb-4"
                    title="Follow Up Customer"
                >
                    <div className="table-responsive">
                        <Table
                            columns={columnFollowUp}
                            dataSource={dataFollowUp}
                            pagination={{
                                position: ['topCenter'],
                                pageSize: 100,
                                showSizeChanger: true,
                                pageSizeOptions: ['20', '50', '100', '500', '1000'],
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            }}
                            className="ant-border-space"
                            locale={{
                                emptyText: <Empty description="Data not found" />,
                            }}
                        />
                    </div>
                </Card>
            ) :
                <Card
                    bordered={false}
                    className="criclebox tablespace mb-4"
                    title={
                        <div className="flex flex-col gap-4">
                            <div className="flex max-sm:flex-col gap-2" style={{ display: "flex", gap: "20px" }}>
                                <h4>Customer</h4>
                                <Input
                                    className="w-full md:w-[300px]"
                                    placeholder="Search Name / Phone / Address"
                                    size="small"
                                    onPressEnter={(e) => {
                                        if (e.key === 'Enter') {
                                            onSearch(e.target.value); // Gantilah dengan nama fungsi pencarian Anda
                                        }
                                    }}
                                />
                                <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => handleOpenModalAdd()}>
                                    <IoIosAddCircle style={{ fontSize: "20px" }} />
                                    Add Customer
                                </Button>
                                <ExportCustomerCSV dataSource={dataSource} page={page} limit={pageSize} />
                            </div>
                        </div>
                    }
                    extra={
                        <div style={{ display: "flex", gap: "20px" }}>
                            {/* <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => handleOpenModalAdd()}>
                                <IoIosAddCircle style={{ fontSize: "20px" }} />
                                Add Customer
                            </Button> */}
                        </div>
                    }
                >
                    <div className="table-responsive">
                        <Table
                            columns={column}
                            dataSource={dataSource}
                            pagination={{
                                position: ['topCenter'],
                                pageSize: 100,
                                showSizeChanger: true,
                                pageSizeOptions: ['20', '50', '100', '500', '1000'],
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                onChange: (page, pageSize) => {
                                    setPage(page);
                                    setPageSize(pageSize);
                                },
                            }}
                            className="ant-border-space"
                            locale={{
                                emptyText: <Empty description="Data not found" />,
                            }}
                        />
                    </div>
                </Card>
            }
        </>
    );
}

export default Index;