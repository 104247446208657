import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Button,
  Empty,
  Popover,
  Pagination,
  Input,
  Typography,
} from "antd";
import { IoIosAddCircle } from "react-icons/io";
import { columnsSalesOrder, orderDataToDataSourcesSalesOrder, salesOrderData } from "./helper";
import { useEffect, useMemo, useRef, useState } from "react";
import { orderDataAllModel, PaymentMethodDisplay, PaymentStatusDisplay } from "../../models/orderData";
import { deleteData, fetchData } from "../../helper/api";
import ModalAddTransaction from "./ModalAddTransaction";
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DateRangePicker } from "react-date-range";
import { addDays, set } from 'date-fns';
import { useQuery } from "../../helper/useQuery";
import { SearchOutlined } from '@ant-design/icons';
import moment from "moment";
import { FaMoneyBillWave, FaCartArrowDown } from "react-icons/fa";
import { FaPersonCirclePlus } from "react-icons/fa6";
import { GrServices } from "react-icons/gr";
import { TbShoe } from "react-icons/tb";
import { formatCurrency } from "../../helper/formatCurrency";
import ExportExcel from "./Excel/ExportExcel";
import Cashier from "./Cashier";
import { PrintOrderSummary } from "../Printer/PrintOrderSummary";
import { PrintOrderReglueSummary } from "../Printer/PrintOrderReglueSummary";
import CalendarViewOrder from "./CalendarViewOrder";
import CalendarViewPayment from "./CalendarViewPayment";
import { useMediaQuery } from "react-responsive";
import ActionSalesOrder from "./ActionSalesOrder";
import dayjs from "dayjs";

import { TransactionProvider, useTransaction } from './contexts/TransactionContext';
import { useGeneral } from "../../contexts/GeneralContext";

const { Search } = Input;
const { Title, Text } = Typography;

const SalesOrder = () => {
  const { getProfile, getShiftNow } = useGeneral();
  // data order
  const [dataAllOrder, setDataAllOrder] = useState(salesOrderData.id === 0 ? [] : salesOrderData);
  const [dataOrder, setDataOrder] = useState(salesOrderData.id === 0 ? [] : salesOrderData);
  const [dataOrderReglue, setDataOrderReglue] = useState();
  const [dataOrderResparasi, setDataOrderResparasi] = useState();
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [statisticData, setStatisticData] = useState();
  const [userData, setUserData] = useState(getProfile);
  const [shiftNow, setShiftNow] = useState(getShiftNow);

  const [triggerFetchOrder, setTriggerFetchOrder] = useState(false);

  const location = useLocation();
  const history = useHistory();

  // Pagination & Filter Date
  const query = useQuery();
  const page = query.get('page') || 1;
  const pageSize = query.get('pageSize') || 1000;
  const startDate = query.get('startDate') || moment().subtract(5, 'days').format('YYYY-MM-DD');
  const endDate = query.get('endDate') || moment().format('YYYY-MM-DD');

  // search
  const [searchValue, setSearchValue] = useState('');

  // PAGINATION
  const [pagination, setPagination] = useState({
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    pageSize: 1000,
  });

  const [statisticDataResparasi, setStatisticDataResparasi] = useState(null)


  // Infinite Scroll
  const [visibleData, setVisibleData] = useState([]); // Data yang ditampilkan
  const [lastVisibleRowKey, setLastVisibleRowKey] = useState(null); // ID elemen terakhir yang terlihat
  const observer = useRef(null);

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [SelectedFilter, setSelectedFilter] = useState('entry_date');
  const optionFilter = [
    { label: 'Entry Date', value: 'entry_date' },
    { label: 'Due Date', value: 'due_date' },
    { label: 'Payment Date', value: 'payment_date' },
    { label: 'Resparasi List', value: 'resparasi' },
  ];

  const handleFilter = (e) => {
    setSelectedFilter(e.target.value);
  }

  useEffect(() => {
    setUserData(getProfile);
    setShiftNow(getShiftNow);
  }, [getProfile, getShiftNow]);

  useEffect(() => {
    if (!lastVisibleRowKey) return;

    const targetElement = document.querySelector(`[data-row-key="${lastVisibleRowKey}"]`);
    if (!targetElement) return;

    observer.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          loadMoreData(); // Fungsi untuk memuat lebih banyak data
        }
      },
      { threshold: 0.1 } // Terlihat sebagian
    );

    observer.current.observe(targetElement);

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [lastVisibleRowKey, SelectedFilter]);

  const loadMoreData = () => {
    const nextBatch = dataOrder.slice(visibleData.length, visibleData.length + 10);
    setVisibleData(prev => [...prev, ...nextBatch]);
    if (nextBatch.length > 0) {
      setLastVisibleRowKey(nextBatch[nextBatch.length - 1]?.id);
    }
  };


  // useEffect(() => {
  //   fetchData("/get-profile")
  //     .then((res) => {
  //       const data = res.data.data;
  //       setUserData(data.user);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  // Option Cashier
  const optionTransaction = [
    { label: 'List Transaction', value: 'list_transaction' },
    { label: 'Calendar Order', value: 'calendar_view' },
    { label: 'Calendar Payment', value: 'calendar_payment' },
  ];

  const [optionTransactionValue, setOptionTransactionValue] = useState('list_transaction');

  const filterOrdersByReglue = (orders) => {
    return orders
      .map(order => {
        // Filter order_details yang memiliki type_service mengandung kata "reglue"
        const filteredDetails = order.order_details.filter(detail =>
          detail.treatment.type_service.toLowerCase().includes("reglue")
        );

        // Jika tidak ada detail yang sesuai, abaikan order ini
        if (filteredDetails.length === 0) return null;

        // Jika ada, buat salinan order dengan order_details yang sudah difilter
        return {
          ...order,
          order_details: filteredDetails
        };
      })
      .filter(order => order !== null); // Hapus null values
  }

  const filterOrdersResparasi = (orders) => {
    const keywords = ["reglue", "filler", "repaint", "repair"]; // Daftar kata kunci yang ingin difilter

    return orders
      .map(order => {
        // Filter order_details berdasarkan apakah type_service mengandung salah satu kata kunci
        const filteredDetails = order.order_details.filter(detail =>
          keywords.some(keyword =>
            detail.treatment.type_service.toLowerCase().includes(keyword)
          )
        );

        // Jika tidak ada detail yang sesuai, abaikan order ini
        if (filteredDetails.length === 0) return null;

        // Jika ada, buat salinan order dengan order_details yang sudah difilter
        return {
          ...order,
          order_details: filteredDetails
        };
      })
      .filter(order => order !== null); // Hapus null values
  };


  useEffect(() => {
    // Fetch data order dengan parameter dari URL
    const fetchDataWithParams = async () => {
      try {
        let url = `/orders?page=${page}&pageSize=${pageSize}&search=${encodeURIComponent(searchValue)}`;

        if (SelectedFilter === 'entry_date') {
          url += `&startDate=${startDate}&endDate=${endDate}`;
        } else if (SelectedFilter === 'due_date') {
          // Tambahkan rentang tanggal "Due Date" jika dipilih
          url += `&dueStartDate=${startDate}&dueEndDate=${endDate}`;
        } else if (SelectedFilter === 'payment_date') {
          // Tambahkan rentang tanggal "Payment Date" jika dipilih
          url += `&paymentStartDate=${startDate}&paymentEndDate=${endDate}`;
        } else {
          // Jika tidak ada filter yang dipilih, maka gunakan filter "Entry Date"
          url += `&startDate=${startDate}&endDate=${endDate}`;
        }

        // delete add update param
        const searchLocation = location.search;
        const newSearchLocation = searchLocation.replace(/&add=\d+|&update=\d+/, "");
        history.push({
          ...location,
          search: newSearchLocation,
        });
        fetchData(url)
          .then((res) => {
            const data = res.data.data;
            const dataSources = orderDataToDataSourcesSalesOrder(data.orders);
            const statistics = data.statistics;
            const dataFilterReglue = filterOrdersByReglue(dataSources)
            const dataFilterResparasi = filterOrdersResparasi(dataSources)
            setDataOrderReglue(dataFilterReglue);
            setDataOrderResparasi(dataFilterResparasi)
            setDataAllOrder(dataSources);
            setDataOrder(dataSources);
            // setAllData(dataSources);
            setVisibleData(dataSources.slice(0, 10));

            // Ambil ID data terakhir dari data yang terlihat
            if (dataSources.length > 0) {
              const lastRowId = dataSources.slice(0, 10).slice(-1)[0]?.id;
              setLastVisibleRowKey(lastRowId); // Set state dengan ID elemen terakhir
            }

            setStatisticData(statistics);

            setPagination({
              ...pagination,
              totalItems: data.pagination.totalItems,
              totalPages: data.pagination.totalPages,
              currentPage: parseInt(data.pagination.currentPage),
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.error("ERROR:", error);
      }
    };

    fetchDataWithParams();
  }, [page, pageSize, startDate, endDate, openModalAdd, triggerFetchOrder, location.search, searchValue, SelectedFilter]);



  const handleOpenModalAdd = () => {
    if (shiftNow !== null) {
      setOpenModalAdd(true);
    } else {
      toast.error('Shift is not open');
    }
  }

  useEffect(() => {
    // HANDLE DELETE TRANSACTION
    const deleteParam = query.get('delete');

    // Lakukan sesuatu dengan nilai parameter "delete"
    if (deleteParam) {
      deleteData(`/orders/${deleteParam}`)
        .then((res) => {
          console.log(res);
          toast.success('Delete Transaction Success');
          // Menghapus parameter "delete" dari URL
          history.push({
            pathname: location.pathname,
            search: `?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`
          });
          setTriggerFetchOrder(!triggerFetchOrder);
        })
        .catch((err) => {
          console.log(err);
        });
    }

  }, [location.search]);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  const handleFilterTanggal = (item) => {
    setState([item.selection]);
    const startDateParam = moment(item.selection.startDate).format('YYYY-MM-DD');
    const endDateParam = moment(item.selection.endDate).format('YYYY-MM-DD');

    if (page !== 1) {
      history.push({
        pathname: location.pathname,
        search: `?page=1&pageSize=${pageSize}&startDate=${startDateParam}&endDate=${endDateParam}`
      });
    } else {
      // Mengupdate parameter URL
      history.push({
        pathname: location.pathname,
        search: `?page=${page}&pageSize=${pageSize}&startDate=${startDateParam}&endDate=${endDateParam}`
      });
    }
  };

  const handlePageChange = (page, pageSize) => {
    setPagination({
      ...pagination,
      currentPage: page,
      pageSize: pageSize
    });

    history.push({
      pathname: location.pathname,
      search: `?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`
    });
  };

  const handlePageSizeChange = (current, size) => {
    setPagination({
      ...pagination,
      currentPage: current,
      pageSize: size
    });
    history.push({
      pathname: location.pathname,
      search: `?page=${current}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`
    });
  }

  const onSearch = (value) => {
    setSearchValue(value);
  }


  const content = (
    <DateRangePicker
      onChange={handleFilterTanggal} // Menggunakan fungsi handleFilterTanggal yang telah dibuat
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={state}
      direction="horizontal"
    />
  );

  const statistics = [
    {
      name: `Total Revenue (${statisticData?.count_transaction || 0})`,
      value: formatCurrency(parseInt(statisticData?.sum_revenue || 0)),
      icon: <FaMoneyBillWave />,
    },
    {
      name: `Total DP (${statisticData?.payment_status?.dp || 0})`,
      value: formatCurrency(parseInt(statisticData?.dp_revenue || 0)),
      icon: <FaMoneyBillWave />,
    },
    {
      name: `Total Paid (${statisticData?.payment_status?.paid || 0})`,
      value: formatCurrency(parseInt(statisticData?.paid_revenue || 0)),
      icon: <FaMoneyBillWave />,
    },
    {
      name: `Total Unpaid (${statisticData?.payment_status?.unpaid || 0})`,
      value: formatCurrency(parseInt(statisticData?.unpaid_revenue || 0)),
      icon: <FaMoneyBillWave />,
    },
    {
      name: "Total Orders",
      value: statisticData?.count_transaction,
      icon: <FaCartArrowDown />,
    },
    {
      name: "Total Services ",
      value: statisticData?.count_services,
      icon: <GrServices />,
    },
    {
      name: "Total Items",
      value: statisticData?.quantity_items,
      icon: <TbShoe />,
    },
  ];

  const statisticsResparasi = [
    {
      name: `Total Revenue`,
      value: formatCurrency(parseInt(statisticDataResparasi?.totalRevenue || 0)),
      icon: <FaMoneyBillWave />,
    },
    {
      name: `Total Paid`,
      value: formatCurrency(parseInt(statisticDataResparasi?.paidRevenue || 0)),
      icon: <FaMoneyBillWave />,
    },
    {
      name: `Total Unpaid`,
      value: formatCurrency(parseInt(statisticDataResparasi?.unpaidRevenue || 0)),
      icon: <FaMoneyBillWave />,
    }
  ];


  const calculateRepairRevenue = (orders) => {
    const keywords = ["reglue", "filler", "repaint", "repair"]; // Daftar kata kunci yang ingin difilter

    // Inisialisasi total pendapatan
    let totalRevenue = 0;
    let paidRevenue = 0;
    let unpaidRevenue = 0;

    // Iterasi melalui setiap order
    orders.forEach(order => {
      order.order_details.forEach(detail => {
        // Periksa apakah type_service mengandung salah satu kata kunci
        if (keywords.some(keyword => detail.treatment.name.toLowerCase().includes(keyword))) {

          const finalPrice = parseFloat(detail.final_price); // Ambil final_price sebagai angka
          totalRevenue += finalPrice; // Tambahkan ke total pendapatan

          // Periksa status pembayaran untuk mengelompokkan ke paid/unpaid
          if (order.payment.status.toLowerCase() === "paid") {
            paidRevenue += finalPrice;
          } else if (order.payment.status.toLowerCase() === "unpaid") {
            unpaidRevenue += finalPrice;
          }
        }
      });
    });

    return {
      totalRevenue, // Total pendapatan dari semua kata kunci
      paidRevenue,  // Pendapatan yang sudah dibayar
      unpaidRevenue // Pendapatan yang belum dibayar
    };
  };


  useEffect(() => {
    if (SelectedFilter === 'resparasi') {
      const dataStatistic = calculateRepairRevenue(dataOrderResparasi)
      setStatisticDataResparasi(dataStatistic)
    }

  }, [SelectedFilter, dataOrderResparasi]);



  return (
    <>
      <TransactionProvider>
        <ModalAddTransaction open={openModalAdd} setOpen={setOpenModalAdd} />

        <Radio.Group
          style={{ marginBottom: "20px" }}
          options={optionTransaction}
          onChange={(e) => setOptionTransactionValue(e.target.value)}
          value={optionTransactionValue}
          optionType="button"
          buttonStyle="solid"
        />

        {optionTransactionValue === 'list_transaction' ? (
          <>
            {userData?.role === "superadmin" | userData?.role === "admin" && SelectedFilter !== 'resparasi' && (
              <Row className="rowgap-vbox" gutter={[24, 0]}>
                {statistics.map((c, index) => (
                  <Col
                    key={index}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={6}
                    xl={6}
                    className="mb-4"
                  >
                    <Card bordered={false} className="criclebox ">
                      <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                          <Col xs={18}>
                            <span>{c.name}</span>
                            <Title level={3}>
                              {c.value}
                            </Title>
                          </Col>
                          <Col xs={6}>
                            <div className="icon-box flex flex-col items-center justify-center" style={{ fontSize: '20px' }}>{c.icon}</div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
            {userData?.role === "superadmin" | userData?.role === "admin" && SelectedFilter === 'resparasi' && (
              <Row className="rowgap-vbox" gutter={[24, 0]}>
                {statisticsResparasi.map((c, index) => (
                  <Col
                    key={index}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}
                    xl={8}
                    className="mb-4"
                  >
                    <Card bordered={false} className="criclebox ">
                      <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                          <Col xs={18}>
                            <span>{c.name}</span>
                            <Title level={3}>
                              {c.value}
                            </Title>
                          </Col>
                          <Col xs={6}>
                            <div className="icon-box flex flex-col items-center justify-center" style={{ fontSize: '20px' }}>{c.icon}</div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}

            <div className="tabled">
              <Radio.Group
                style={{ marginBottom: "20px" }}
                options={optionFilter}
                onChange={handleFilter}
                value={SelectedFilter}
                optionType="button"
                buttonStyle="solid"
              />
              <Row style={{ display: 'block' }}>
                <Col xl={24}>
                  <Card
                    bordered={false}
                    className="criclebox tablespace mb-4"
                    title={
                      <div className="flex flex-col gap-4">
                        <div className="flex max-sm:flex-col gap-2" style={{ display: "flex", gap: "20px" }}>
                          <h4>Transaction</h4>
                          <Input
                            className="w-full md:w-[300px]"
                            placeholder="Search Name / Phone / Invoice / Unpaid"
                            size="small"
                            onPressEnter={(e) => {
                              if (e.key === 'Enter') {
                                onSearch(e.target.value); // Gantilah dengan nama fungsi pencarian Anda
                              }
                            }}
                          />
                        </div>
                        <div className="max-sm:hidden">
                          {userData?.role === "superadmin" | userData?.role === "admin" && (
                            <ExportExcel orders={dataOrder} />
                          )}
                          <Button className="ml-1" onClick={() => PrintOrderSummary(dataOrder, startDate)} >Cetak List Order</Button>
                          <Button className="ml-1" onClick={() => PrintOrderReglueSummary(dataOrderReglue, startDate)} >Cetak Order Reglue</Button>
                        </div>

                        <div className="flex max-sm:flex-col md:hidden gap-2">
                          <Popover content={content} placement="leftTop" title="Filter Tanggal" trigger="click">
                            <Button>
                              {SelectedFilter === 'entry_date' ? 'Entry Date' : SelectedFilter === 'due_date' ? 'Due Date' : 'Payment Date'}:
                              {` ${moment(startDate).format('DD MMM YYYY')} - ${moment(endDate).format('DD MMM YYYY')}`}
                            </Button>
                          </Popover>

                          {userData?.role === "superadmin" | userData?.role === "admin" && (
                            <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => handleOpenModalAdd()}>
                              <IoIosAddCircle style={{ fontSize: "20px" }} />
                              Add Transaction
                            </Button>
                          )}
                        </div>
                      </div>
                    }
                    extra={
                      <div className="max-sm:hidden flex gap-2 gap-2">
                        <Popover content={content} placement="leftTop" title="Filter Tanggal" trigger="click">
                          <Button>
                            {SelectedFilter === 'entry_date' ? 'Entry Date' : SelectedFilter === 'due_date' ? 'Due Date' : 'Payment Date'}:
                            {` ${moment(startDate).format('DD MMM YYYY')} - ${moment(endDate).format('DD MMM YYYY')}`}
                          </Button>
                        </Popover>

                        {userData?.role === "superadmin" | userData?.role === "admin" && (
                          <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => handleOpenModalAdd()}>
                            <IoIosAddCircle style={{ fontSize: "20px" }} />
                            Add Transaction
                          </Button>
                        )}
                      </div>
                    }
                  >
                    <div style={{ padding: "20px", display: 'flex', justifyContent: "flex-end" }}>
                      <Pagination
                        current={pagination.currentPage}
                        total={pagination.totalItems}
                        pageSize={pagination.pageSize}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageSizeChange}
                        showSizeChanger
                        pageSizeOptions={['10', '20', '50', '100', '500', '1000']}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Orders`}
                      />
                    </div>
                    {isMobile ? (
                      <div className="space-y-4">
                        {visibleData.map((record) => (
                          <div
                            key={record.id}
                            data-row-key={record.id}
                            className="border border-gray-200 rounded-xl shadow-sm p-4 mb-4 bg-white"
                          >
                            {/* Header dengan Code dan Status */}
                            <div className="flex justify-between items-center mb-3">
                              <div className="flex items-center space-x-2">
                                <span className="font-medium text-gray-800">{record.code}</span>
                                <span className={`px-3 py-1 rounded-full text-xs font-medium
                                  ${record.payment_status === 'paid' ? 'bg-green-100 text-green-700' :
                                    record.payment_status === 'unpaid' ? 'bg-red-100 text-red-700' :
                                      'bg-yellow-100 text-yellow-700'}`}>
                                  {PaymentStatusDisplay(record.payment.status)}
                                </span>
                              </div>
                            </div>

                            {/* Informasi Customer dan Tanggal */}
                            <div className="grid grid-cols-2 gap-2 mb-4 text-sm">
                              <div>
                                <p className="text-gray-500">Customer</p>
                                <p className="text-lg text-gray-800">{record.customer.name}</p>
                              </div>
                              <div>
                                <p className="text-gray-500">Entry Date</p>
                                <p className="font-medium text-gray-800">
                                  {dayjs(record.entry_date).format("DD MMM YYYY")}
                                </p>
                              </div>
                            </div>

                            {/* Informasi Layanan dan Pembayaran */}
                            <div className="bg-gray-50 rounded-lg p-3 mb-4">
                              <div className="grid grid-cols-2 gap-3">
                                <div>
                                  <p className="text-gray-500 text-sm">Total Services</p>
                                  <p className="font-semibold text-gray-800">{record.quantity} items</p>
                                </div>
                                <div>
                                  <p className="text-gray-500 text-sm">Total Amount</p>
                                  <p className="font-semibold text-green-600">
                                    {formatCurrency(parseInt(record.payment.final_price))}
                                  </p>
                                </div>
                              </div>
                            </div>

                            {/* Informasi Pembayaran */}
                            <div className="flex items-center justify-between bg-blue-50 rounded-lg p-3">
                              <div>
                                <p className="text-gray-600 text-sm mb-1">Payment Method</p>
                                <div className="flex items-center space-x-2">
                                  <span className="font-medium text-blue-700">
                                    {PaymentMethodDisplay(record.payment.method)}
                                  </span>
                                </div>
                              </div>
                              <div>
                                <p className="text-gray-600 text-sm mb-1">Due Date</p>
                                <p className="font-medium text-blue-700">
                                  {dayjs(record.due_date).format("DD MMM YYYY")}
                                </p>
                              </div>
                            </div>

                            {/* Action Button */}
                            <div className="mt-3 flex justify-end">
                              <ActionSalesOrder text={null} record={record} />
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <Table
                          columns={columnsSalesOrder}
                          dataSource={SelectedFilter === 'resparasi' ? dataOrderResparasi : visibleData}
                          pagination={false}
                          className="ant-border-space"
                          locale={{
                            emptyText: <Empty description="Data not found" />,
                          }}
                          rowKey="id"
                        />
                      </div>
                    )}


                  </Card>
                </Col>
              </Row>
            </div>
          </>
        ) : optionTransactionValue === 'calendar_view' ? (
          <>
            <div>
              <CalendarViewOrder />
            </div>
          </>
        ) : (<>
          <CalendarViewPayment />
        </>)
        }

      </TransactionProvider>
    </>
  );
}

export default SalesOrder;
