export const optionRadioPaymentMethod = [
    { label: 'Cash', value: 'cash' },
    { label: 'Bank Transfer', value: 'bank_transfer' },
    { label: 'Qris', value: 'qris' },
];

export const optionRadioPaymentStatus = [
    { label: 'Paid', value: 'paid' },
    { label: 'DP', value: 'dp' },
    { label: 'Unpaid', value: 'unpaid' },
];

export const optionRadioTransaction = [
    { label: 'Input Data', value: 'transaction' },
    { label: 'Items', value: 'transaction_detail' },
    { label: 'Payment', value: 'payment' },
]