import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Button, Upload } from 'antd';
import { fetchData, postData, putData } from '../../helper/api';
import { toast } from 'react-toastify';
import { ConvertStringToArray } from '../../helper/convertStringToArray';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputCustom from '../../components/InputCustom';
import SelectCustom from '../../components/SelectCustom';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import UploadProduct from './UploadProduct';

const { Option } = Select;


const ModalAddProduct = ({ open, setOpen, initialData, listData, trigerFetchData, setTrigerFetchData }) => {

    const formik = useFormik({
        initialValues: {
            name: '',
            branch_id: 1,
            category: '',
            description: '',
            price: '',
            stock: '',
            images: '',
            sold: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Nama harus diisi'),
            category: Yup.string().required('Kategori harus diisi'),
            description: Yup.string().required('Deskripsi harus diisi'),
            price: Yup.number().required('Harga harus diisi'),
            stock: Yup.number().required('Stock harus diisi'),
            images: Yup.string().required('Images harus diisi'),
            sold: Yup.number().required('Sold harus diisi'),
        }),
        onSubmit: values => {
            console.log(values);

            if (initialData) {
                putData(`/products/${initialData.id}`, values)
                    .then((res) => {
                        toast.success('Data berhasil diupdate');
                        setTrigerFetchData(trigerFetchData + 1);
                        formik.resetForm();
                        setOpen(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error('Data gagal diupdate');
                    })
            } else {
                postData('/products', values)
                    .then((res) => {
                        toast.success('Data berhasil ditambahkan');
                        formik.resetForm();
                        setTrigerFetchData(trigerFetchData + 1);
                        setOpen(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        toast.error('Data gagal ditambahkan');
                    })
            }
        }
    })

    useEffect(() => {
        if (initialData) {
            formik.setValues({
                name: initialData.name,
                branch_id: initialData.branch_id,
                category: initialData.category,
                description: initialData.description,
                price: initialData.price,
                stock: initialData.stock,
                images: initialData.images,
                sold: initialData.sold,
            })
        }
    }, [initialData])


    return (
        <Modal
            title="Add Product"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={() => setOpen(false)}
            footer={null}
        >
            <div className='flex flex-col gap-10'>
                <UploadProduct 
                    formik={formik}
                />
                <InputCustom
                    id="name"
                    name="name"
                    label="Nama"
                    inputType={"text"}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    touched={(formik.touched.name)}
                    error={formik.errors.name}
                />

                <SelectCustom
                    id="category"
                    name="category"
                    label="Kategori"
                    value={formik.values.category}
                    onChange={value => formik.setFieldValue('category', value)}
                    onBlur={formik.handleBlur}
                    touched={(formik.touched.category)}
                    error={formik.errors.category}
                    option={[
                        { label: 'Parfum', value: 'Parfum' },
                        { label: 'Cleaner', value: 'Cleaner' },
                        { label: 'Sikat', value: 'Sikat' },
                    ]}
                />

                <InputCustom
                    id="description"
                    name="description"
                    label="Deskripsi"
                    inputType={"text"}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    touched={(formik.touched.description)}
                    error={formik.errors.description}
                />

                <InputCustom
                    id="price"
                    name="price"
                    label="Harga"
                    inputType={"number"}
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    touched={(formik.touched.price)}
                    error={formik.errors.price}

                />

                <InputCustom
                    id="stock"
                    name="stock"
                    label="Stock"
                    inputType={"number"}
                    value={formik.values.stock}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    touched={(formik.touched.stock)}
                    error={formik.errors.stock}

                />

                <InputCustom
                    id="sold"
                    name="sold"
                    label="Sold"
                    inputType={"number"}
                    value={formik.values.sold}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    touched={(formik.touched.sold)}
                    error={formik.errors.sold}

                />

                <Button
                    className='bg-blue-500 text-white'
                    onClick={formik.handleSubmit}
                >
                    {initialData ? 'Update' : 'Tambah'}
                </Button>
            </div>

        </Modal>
    );
};

export default ModalAddProduct;
