import React, { useEffect, useState } from 'react';
import { Table, Empty, Card, Input, Button, Popover, Pagination, Image, Tag, Row, Col, Typography } from 'antd';
import { IoIosAddCircle, IoIosPerson } from "react-icons/io";
import { fetchData, putData } from '../../helper/api';
import { formatCurrency } from '../../helper/formatCurrency';
import ModalAddAbsent from './ModalAddAbsent';
import ActionAbsent from './ActionAbsent';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { useQuery } from '../../helper/useQuery';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import { toast } from 'react-toastify';
import EmployeeAbsent from './EmployeeAbsent';


const { Title, Text } = Typography;


function Index(props) {
    const [dataSource, setDataSource] = useState([]);
    const [trigerFetchData, setTrigerFetchData] = useState(false);

    const location = useLocation();
    const history = useHistory();

    const [dataUser, setDataUser] = useState([]);

    const [openModalAdd, setOpenModalAdd] = useState(false);
    const [rekapKaryawan, setRekapKaryawan] = useState([]);

    // Pagination & Filter Date
    const query = useQuery();
    const page = query.get('page') || 1;
    const pageSize = query.get('pageSize') || 10;
    const startDate = query.get('startDate') || moment().format('YYYY-MM-DD');
    const endDate = query.get('endDate') || moment().format('YYYY-MM-DD');

    // search
    const [searchValue, setSearchValue] = useState('');

    // PAGINATION
    const [pagination, setPagination] = useState({
        totalItems: 0,
        totalPages: 0,
        currentPage: 1,
        pageSize: 10,
    });

    const handleOpenModalAdd = () => {
        setOpenModalAdd(true);
    }

    const onSearch = (value) => {
        setSearchValue(value);
    }

    // const mappingDataToDataSource = (data) => {
    //     return data.map((item, index) => {
    //         return {
    //             ...item,
    //             no: index + 1,
    //             name: item.employee_absent.name,
    //         };
    //     });
    // }

    const mappingDataToDataSource = (data) => {
        return data
            .filter((item) => {
                // Jika role adalah "superadmin", tampilkan semua data
                if (dataUser?.role === "superadmin") {
                    return true;
                }
                // Jika bukan superadmin, filter berdasarkan user ID
                return item?.employee_id === dataUser?.id;
            })
            .map((item, index) => ({
                ...item,
                no: index + 1,
                name: item.employee_absent.name,
            }));
    };


    useEffect(() => {
        const searchLocation = location.search;
        const newSearchLocation = searchLocation.replace(/&add_update=\d/, "");
        history.push({
            ...location,
            search: newSearchLocation,
        });
        // Fetch data
        let url = `/absents?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}&search=${encodeURIComponent(searchValue)}`;


        fetchData(url)
            .then((res) => {
                const data = res.data.data;
                const dataSource = mappingDataToDataSource(data.absents);
                setDataSource(dataSource);
                setRekapKaryawan(data.rekap_karyawan);
                setPagination({
                    ...pagination,
                    totalItems: data.pagination.totalItems,
                    totalPages: data.pagination.totalPages,
                    currentPage: parseInt(data.pagination.currentPage),
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [location.search, trigerFetchData, searchValue, dataUser]);

    const handleEndWork = (id) => {
        putData(`/absents/${id}`, { clock_out: moment().format('HH:mm:ss') })
            .then((response) => {
                if (response.status === 200) {
                    setTrigerFetchData(!trigerFetchData);
                    toast.success('Absent updated successfully');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchData('/get-profile')
            .then((res) => {
                const data = res.data.data;
                setDataUser(data?.user);
                console.log(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const column = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => (
                <p style={{ color: "black" }}>{moment(record.date).format('dddd, DD MMM YYYY')}</p>
            ),
        },
        {
            title: 'Clock In',
            dataIndex: 'clock_in',
            key: 'clock_in',
        },
        {
            title: 'Clock Out',
            dataIndex: 'clock_out',
            key: 'clock_out',
            render: (text, record) => (
                <>
                    {record.clock_out ?
                        <p style={{ color: "black" }}>{record.clock_out}</p> :
                        <Button className='bg-red-500 text-white' size="small" onClick={() => handleEndWork(record.id)}>End Work</Button>
                    }
                </>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <Tag color={record.status === "Present" ? "green" : "red"} key={record.status}>
                    {record.status}
                </Tag>
            ),
        },
        // {
        //     title: 'Photos',
        //     dataIndex: 'photo_clock_in',
        //     key: 'photo_clock_in',
        //     render: (text, record) => (
        //         <Image
        //             width={100}
        //             src={record.photo_clock_in}
        //         />
        //     ),
        // },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                dataUser?.role === "superadmin" && (
                    <ActionAbsent 
                        record={record} 
                        trigerFetchData={trigerFetchData} 
                        setTrigerFetchData={setTrigerFetchData} 
                    />
                )
            ),
        },
    ]

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);

    const handleFilterTanggal = (item) => {
        setState([item.selection]);
        const startDateParam = moment(item.selection.startDate).format('YYYY-MM-DD');
        const endDateParam = moment(item.selection.endDate).format('YYYY-MM-DD');

        if (page !== 1) {
            history.push({
                pathname: location.pathname,
                search: `?page=1&pageSize=${pageSize}&startDate=${startDateParam}&endDate=${endDateParam}`
            });
        } else {
            // Mengupdate parameter URL
            history.push({
                pathname: location.pathname,
                search: `?page=${page}&pageSize=${pageSize}&startDate=${startDateParam}&endDate=${endDateParam}`
            });
        }
    };

    const content = (
        <DateRangePicker
            onChange={handleFilterTanggal} // Menggunakan fungsi handleFilterTanggal yang telah dibuat
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
        />
    );

    const handlePageChange = (page, pageSize) => {
        setPagination({
            ...pagination,
            currentPage: page,
            pageSize: pageSize
        });

        history.push({
            pathname: location.pathname,
            search: `?page=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`
        });
    };

    const handlePageSizeChange = (current, size) => {
        setPagination({
            ...pagination,
            currentPage: current,
            pageSize: size
        });
        history.push({
            pathname: location.pathname,
            search: `?page=${current}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`
        });
    }

    return (
        <>


            {dataUser?.role === "superadmin" && (
                <>
                    <ModalAddAbsent open={openModalAdd} setOpen={setOpenModalAdd} trigerFetchData={trigerFetchData} setTrigerFetchData={setTrigerFetchData} />
                    <Row className="rowgap-vbox" gutter={[24, 0]} style={{ marginTop: '20px' }}>
                        {rekapKaryawan.map((employee, index) => (
                            <Col
                                key={index}
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}
                                xl={8}
                                className="mb-4"
                            >
                                <Card 
                                    bordered={false} 
                                    className="criclebox hover:shadow-lg transition-shadow duration-300"
                                >
                                    <div className="p-4">
                                        {/* Header */}
                                        <div className="flex items-center justify-between mb-4">
                                            <div className="flex items-center gap-3">
                                                <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center">
                                                    <IoIosPerson className="text-blue-500 text-xl" />
                                                </div>
                                                <div>
                                                    <h3 className="text-lg font-semibold text-gray-800">{employee.name}</h3>
                                                    <p className="text-sm text-gray-500">Total Days: {employee.total_days}</p>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Main Statistics */}
                                        <div className="grid grid-cols-3 gap-4 mb-4">
                                            <div className="bg-green-50 p-3 rounded-lg">
                                                <p className="text-sm text-gray-600">Present</p>
                                                <p className="text-xl font-bold text-green-600">{employee.total_present}</p>
                                            </div>
                                            <div className="bg-red-50 p-3 rounded-lg">
                                                <p className="text-sm text-gray-600">Absent</p>
                                                <p className="text-xl font-bold text-red-600">{employee.total_absent}</p>
                                            </div>
                                            <div className="bg-yellow-50 p-3 rounded-lg">
                                                <p className="text-sm text-gray-600">Sick</p>
                                                <p className="text-xl font-bold text-yellow-600">{employee.total_sick}</p>
                                            </div>
                                        </div>

                                        {/* Leave Details */}
                                        <div className="grid grid-cols-2 gap-3">
                                            <div className="flex items-center gap-2">
                                                <div className="w-2 h-2 rounded-full bg-purple-500"></div>
                                                <span className="text-sm text-gray-600">Day Off:</span>
                                                <span className="font-medium">{employee.total_day_off_personal}</span>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <div className="w-2 h-2 rounded-full bg-blue-500"></div>
                                                <span className="text-sm text-gray-600">Public Holiday:</span>
                                                <span className="font-medium">{employee.total_day_off_general}</span>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <div className="w-2 h-2 rounded-full bg-indigo-500"></div>
                                                <span className="text-sm text-gray-600">Annual Leave:</span>
                                                <span className="font-medium">{employee.total_annual_leave}</span>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <div className="w-2 h-2 rounded-full bg-pink-500"></div>
                                                <span className="text-sm text-gray-600">Personal Leave:</span>
                                                <span className="font-medium">{employee.total_personal_leave}</span>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <div className="w-2 h-2 rounded-full bg-orange-500"></div>
                                                <span className="text-sm text-gray-600">Emergency Leave:</span>
                                                <span className="font-medium">{employee.total_emergency_leave}</span>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <div className="w-2 h-2 rounded-full bg-teal-500"></div>
                                                <span className="text-sm text-gray-600">Work Duration:</span>
                                                <span className="font-medium">{employee.total_work_duration}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Card
                        bordered={false}
                        className="criclebox tablespace mb-4"
                    >
                        <div className="flex gap-4 max-md:flex-col" style={{ justifyContent: "space-between", padding: "20px" }}>
                            <div className="flex gap-4 max-md:flex-col">
                                <h3 className='font-bold' style={{ marginTop: "10px" }}>Absent</h3>
                                <Input
                                    style={{ width: "200px", fontSize: "12px" }}
                                    placeholder="Search Name"
                                    size="small"
                                    onPressEnter={(e) => {
                                        if (e.key === 'Enter') {
                                            onSearch(e.target.value); // Gantilah dengan nama fungsi pencarian Anda
                                        }
                                    }}
                                />
                            </div>
                            <div className="flex gap-2 max-md:flex-col">
                                <Popover content={content} placement="leftTop" title="Filter Tanggal" trigger="click">
                                    <Button>Entry Date Filter : {moment(startDate).format('DD MMM YYYY')} - {moment(endDate).format('DD MMM YYYY')}</Button>
                                </Popover>
                                <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => handleOpenModalAdd()}>
                                    <IoIosAddCircle style={{ fontSize: "20px" }} />
                                    Add Absent
                                </Button>
                            </div>
                        </div>
                        <div style={{ padding: "20px", display: 'flex', justifyContent: "flex-end" }}>
                            <Pagination
                                current={pagination.currentPage}
                                total={pagination.totalItems}
                                pageSize={pagination.pageSize}
                                onChange={handlePageChange}
                                onShowSizeChange={handlePageSizeChange}
                                showSizeChanger
                                pageSizeOptions={['10', '20', '50', '100']}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                            />
                        </div>
                        <div className="table-responsive">
                            <Table
                                columns={column}
                                dataSource={dataSource}
                                className="ant-border-space"
                                pagination={false}
                                locale={{
                                    emptyText: <Empty description="Data not found" />,
                                }}
                            />
                        </div>

                    </Card>
                </>
            )}

            {dataUser?.role === "employee" | dataUser?.role === "admin"  && (
                <EmployeeAbsent />
            )}


        </>
    );
}

export default Index;