import React from "react";
import { Modal } from "antd";

const CalendarViewPaymentModal = ({ isModalOpen, handleOk, handleCancel, data }) => {
    return (
        <Modal
            title={`Detail Transaksi - ${data.date}`}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <div className="space-y-6">
                 {/* Unpaid Transactions Section */}
                 <div className="bg-red-50 border border-red-200 p-4 rounded-lg shadow-md">
                    <h3 className="font-bold text-red-700 text-lg mb-2">❌ Unpaid Transactions</h3>
                    <ul className="space-y-2">
                        {data.unpaid_data?.length ? (
                            data.unpaid_data.map((item) => (
                                <li
                                    key={item.id}
                                    className="flex justify-between items-center p-2 bg-red-100 rounded-lg hover:shadow-lg transition-shadow"
                                >
                                    <span className="font-medium">{item.customer.name}</span>
                                    <span className="text-red-700 font-semibold">Rp {Number(item.payment.final_price).toLocaleString()}</span>
                                </li>
                            ))
                        ) : (
                            <li className="text-gray-500">No Unpaid Transactions</li>
                        )}
                    </ul>
                </div>
                 {/* DP Transactions Section */}
                 <div className="bg-red-50 border border-red-200 p-4 rounded-lg shadow-md">
                    <h3 className="font-bold text-red-700 text-lg mb-2">📥 DP Transactions</h3>
                    <ul className="space-y-2">
                        {data.dp_data?.length ? (
                            data.dp_data.map((item) => (
                                <li
                                    key={item.id}
                                    className="flex justify-between items-center p-2 bg-red-100 rounded-lg hover:shadow-lg transition-shadow"
                                >
                                    <span className="font-medium">{item.customer.name}</span>
                                    <span className="text-red-700 font-semibold">Rp {Number(item.payment.money).toLocaleString()}</span>
                                </li>
                            ))
                        ) : (
                            <li className="text-gray-500">No DP Transactions</li>
                        )}
                    </ul>
                </div>
                
                {/* Paid Transactions Section */}
                <div className="bg-green-50 border border-green-200 p-4 rounded-lg shadow-md">
                    <h3 className="font-bold text-green-700 text-lg mb-4">✅ Paid Transactions</h3>

                    {/* Total Amounts by Payment Method */}
                    <div className="mb-4 space-y-2">
                        <div className="flex justify-between text-sm font-medium text-gray-800">
                            <span>💳 QRIS:</span>
                            <span className="text-green-600">Rp {Number(data.qris || 0).toLocaleString()}</span>
                        </div>
                        <div className="flex justify-between text-sm font-medium text-gray-800">
                            <span>🏦 Bank Transfer:</span>
                            <span className="text-green-600">Rp {Number(data.bank_transfer || 0).toLocaleString()}</span>
                        </div>
                        <div className="flex justify-between text-sm font-medium text-gray-800">
                            <span>💵 Cash:</span>
                            <span className="text-green-600">Rp {Number(data.cash || 0).toLocaleString()}</span>
                        </div>
                    </div>

                    {/* List of Paid Transactions */}
                    <div className="bg-green-50 border border-green-200 p-4 rounded-lg shadow-md">
                        {/* Cash Transactions */}
                        {data.paid_data?.filter(item => item.payment.method === "cash").length > 0 && (
                            <div className="mb-6">
                                <h4 className="font-bold text-gray-800 text-md mb-2">💵 Cash</h4>
                                <ul className="space-y-2">
                                    {data.paid_data
                                        ?.filter(item => item.payment.method === "cash")
                                        .map((item) => (
                                            <li
                                                key={item.id}
                                                className="flex justify-between items-center p-2 bg-green-100 rounded-lg hover:shadow-lg transition-shadow"
                                            >
                                                <span className="font-medium text-gray-800">{item.customer.name}</span>
                                                <span className="text-green-700 font-semibold">
                                                    Rp {Number(item.payment.final_price).toLocaleString()}
                                                </span>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        )}

                        {/* Bank Transfer Transactions */}
                        {data.paid_data?.filter(item => item.payment.method === "bank_transfer").length > 0 && (
                            <div className="mb-6">
                                <h4 className="font-bold text-gray-800 text-md mb-2">🏦 Bank Transfer</h4>
                                <ul className="space-y-2">
                                    {data.paid_data
                                        ?.filter(item => item.payment.method === "bank_transfer")
                                        .map((item) => (
                                            <li
                                                key={item.id}
                                                className="flex justify-between items-center p-2 bg-green-100 rounded-lg hover:shadow-lg transition-shadow"
                                            >
                                                <span className="font-medium text-gray-800">{item.customer.name}</span>
                                                <span className="text-green-700 font-semibold">
                                                    Rp {Number(item.payment.final_price).toLocaleString()}
                                                </span>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        )}

                        {/* QRIS Transactions */}
                        {data.paid_data?.filter(item => item.payment.method === "qris").length > 0 && (
                            <div className="mb-6">
                                <h4 className="font-bold text-gray-800 text-md mb-2">💳 QRIS</h4>
                                <ul className="space-y-2">
                                    {data.paid_data
                                        ?.filter(item => item.payment.method === "qris")
                                        .map((item) => (
                                            <li
                                                key={item.id}
                                                className="flex justify-between items-center p-2 bg-green-100 rounded-lg hover:shadow-lg transition-shadow"
                                            >
                                                <span className="font-medium text-gray-800">{item.customer.name}</span>
                                                <span className="text-green-700 font-semibold">
                                                    Rp {Number(item.payment.final_price).toLocaleString()}
                                                </span>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        )}

                        {/* Jika Tidak Ada Paid Transactions */}
                        {!data.paid_data?.length && (
                            <div className="text-gray-500 text-center">No Paid Transactions</div>
                        )}
                    </div>

                </div>


               
            </div>

        </Modal>
    );
};

export default CalendarViewPaymentModal;
