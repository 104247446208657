import React, { createContext, useContext, useState, useEffect } from 'react';
import {
    fetchLeads,
    createLead as apiCreateLead,
    updateLead as apiUpdateLead,
    deleteLead as apiDeleteLead,
    fetchFollowUps,
    createFollowUp,
    fetchLeadReports
} from '../api';
import { fetchData } from '../../../helper/api';
import { toast } from 'react-toastify';
const LeadsContext = createContext();

export function LeadsProvider({ children }) {
    const [leads, setLeads] = useState([]);
    const [followUps, setFollowUps] = useState([]);
    const [reports, setReports] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState({
        leads: true,
        followUps: false,
        reports: false
    });
    const [error, setError] = useState(null);

    const fetchCurrentUser = async () => {
        try {
            setLoading(prev => ({ ...prev, leads: true }));
            const response = await fetchData('/get-profile');
            const data = response.data.data;

            setCurrentUser(data);
            setError(null);
        } catch (error) {
            console.error('Error fetching user profile:', error);
            setError(error.message);
        } finally {
            setLoading(prev => ({ ...prev, leads: false }));
        }
    };

    // Fetch semua leads dengan filter
    const getLeads = async (filters = {}) => {
        try {
            setLoading(prev => ({ ...prev, leads: true }));
            const response = await fetchLeads(filters);
            setLeads(response.data);
            setError(null);
        } catch (error) {
            console.error('Error fetching leads:', error);
            setError(error.response.data.error.message);
        } finally {
            setLoading(prev => ({ ...prev, leads: false }));
        }
    };

    // Create lead baru
    const createLead = async (leadData) => {
        try {
            const response = await apiCreateLead(leadData);
            setLeads(prevLeads => [...prevLeads, response.data]);
            return response.data;
        } catch (error) {
            console.error('Error creating lead:', error);
            toast.error(error.response.data.error.message);
            setError(error.response.data.error.message);
        }
    };

    // Update lead
    const updateLead = async (id, leadData) => {
        try {
            const response = await apiUpdateLead(id, leadData);
            getLeads();
            return response.data;
        } catch (error) {
            console.error('Error updating lead:', error);
            throw error;
        }
    };

    // Delete lead
    const deleteLead = async (id) => {
        try {
            await apiDeleteLead(id);
            setLeads(prevLeads => prevLeads.filter(lead => lead.id !== id));
        } catch (error) {
            console.error('Error deleting lead:', error);
            throw error;
        }
    };

    // Fetch follow-ups
    const getFollowUps = async () => {
        try {
            setLoading(prev => ({ ...prev, followUps: true }));
            // const response = await fetchFollowUps();
            // setFollowUps(response.data);
        } catch (error) {
            console.error('Error fetching follow-ups:', error);
            throw error;
        } finally {
            setLoading(prev => ({ ...prev, followUps: false }));
        }
    };

    // Create follow-up
    const addFollowUp = async (leadId, data) => {
        try {
            const response = await createFollowUp(leadId, data);
            // Update leads atau followUps state sesuai kebutuhan
            // await getFollowUps(); // Refresh follow-ups setelah menambah yang baru
            await getLeads();
            return response.data;
        } catch (error) {
            console.error('Error creating follow-up:', error);
            throw error;
        }
    };

    // Fetch reports
    const getReports = async (startDate, endDate) => {
        try {
            setLoading(prev => ({ ...prev, reports: true }));
            const response = await fetchLeadReports(startDate, endDate);
            setReports(response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching reports:', error);
            throw error;
        } finally {
            setLoading(prev => ({ ...prev, reports: false }));
        }
    };

    const testingTrueLeads = async () => {
        try {
            setLoading({
                leads: true,
                followUps: true,
                reports: true
            })
        } catch (error) {
            console.error('Error fetching leads:', error);
        }
    };

    const testingFalseLeads = async () => {
        try {
            setLoading({
                leads: false,
                followUps: false,
                reports: false
            })
        } catch (error) {
            console.error('Error fetching leads:', error);
        }
    };

    const testingAddSampleLeads = async () => {
        try {
            const response = await createLead({
                name: 'Sample Lead',
                email: 'sample@example.com',
                phone: '1234567890'
            });
            getLeads();
        } catch (error) {
            throw error;
        }
    }

    const testingDeleteSampleLeads = async () => {
        try {
            const response = await deleteLead(1);
            getLeads();
        } catch (error) {
            throw error;
        }
    }

    // Fetch leads saat komponen pertama kali di-mount
    useEffect(() => {
        fetchCurrentUser();
        getLeads();
    }, []);

    const value = {
        // State
        leads,
        followUps,
        reports,
        loading,
        error,
        currentUser,

        // Lead actions
        getLeads,
        createLead,
        updateLead,
        deleteLead,

        testingTrueLeads,
        testingFalseLeads,
        testingAddSampleLeads,
        testingDeleteSampleLeads,
        // Follow-up actions
        getFollowUps,
        addFollowUp,

        // Report actions
        getReports
    };

    return (
        <LeadsContext.Provider value={value}>
            {children}
        </LeadsContext.Provider>
    );
}

export function useLeads() {
    const context = useContext(LeadsContext);
    if (!context) {
        throw new Error('useLeads harus digunakan di dalam LeadsProvider');
    }
    return context;
}