import React, { useEffect } from 'react';
import { Table, Empty, Card, Input, Button } from 'antd';
import { IoIosAddCircle } from "react-icons/io";
import { fetchData } from '../../helper/api';
import ModalAddEmployee from './ModalAddEmployee';
import ActionEmployee from './ActionEmployee';
import { useLocation, useHistory } from 'react-router-dom';

function Index(props) {
    const [dataSource, setDataSource] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [trigerFetchData, setTrigerFetchData] = React.useState(false);

    const location = useLocation();
    const history = useHistory();

    const [openModalAdd, setOpenModalAdd] = React.useState(false);

    const handleOpenModalAdd = () => {
        setOpenModalAdd(true);
    }

    const onSearch = (value) => {
        setSearchValue(value);
    }

    const mappingDataToDataSource = (data) => {
        return data.map((item, index) => {
            return {
                ...item,
            };
        });
    }

    useEffect(() => {
        const searchLocation = location.search;
        const newSearchLocation = searchLocation.replace(/&add_update=\d/, "");
        history.push({
            ...location,
            search: newSearchLocation,
        });

        let url = `/users?search=${encodeURIComponent(searchValue)}`;

        if (searchValue === "") {
            url = `/users`;
        }

        fetchData(url)
            .then((res) => {
                const data = res.data.data;
                const dataSource = mappingDataToDataSource(data);
                setDataSource(dataSource);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [location.search, trigerFetchData, searchValue])


    const column = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        // {
        //     title: 'Branch Id',
        //     dataIndex: 'branch_id',
        //     key: 'branch_id',
        // },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        // {
        //     title: 'Address',
        //     dataIndex: 'address',
        //     key: 'address',
        //     render: (text) => text || '-',
        // },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
        },
        // {
        //     title: 'Salary',
        //     dataIndex: 'salary',
        //     key: 'salary',
        //     render: (text) => text || '-',
        // },
        // {
        //     title: 'Salary Date',
        //     dataIndex: 'salary_date',
        //     key: 'salary_date',
        //     render: (text) => text || '-',
        // },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <ActionEmployee record={record} trigerFetchData={trigerFetchData} setTrigerFetchData={setTrigerFetchData} />
            ),
        },
    ]

    return (
        <>
            <ModalAddEmployee open={openModalAdd} setOpen={setOpenModalAdd} trigerFetchData={trigerFetchData} setTrigerFetchData={setTrigerFetchData} />
                <Card
                    bordered={false}
                    className="criclebox tablespace mb-4"
                    title={
                        <div style={{ display: "flex", gap: "20px" }}>
                            <h4>Employee</h4>
                            <Input
                                style={{ width: "200px", fontSize: "12px" }}
                                placeholder="Search Name / Email / Phone"
                                size="small"
                                onPressEnter={(e) => {
                                    if (e.key === 'Enter') {
                                        onSearch(e.target.value);
                                    }
                                }}
                            />
                        </div>
                    }
                    extra={
                        <div style={{ display: "flex", gap: "20px" }}>
                            <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => handleOpenModalAdd()}>
                                <IoIosAddCircle style={{ fontSize: "20px" }} />
                                Add Employee
                            </Button>
                        </div>
                    }
                >
                    <div className="table-responsive">
                        <Table
                            columns={column}
                            dataSource={dataSource}
                            pagination={{
                                position: ['topCenter'],
                                pageSize: 100,
                                showSizeChanger: true,
                                pageSizeOptions: ['20', '50', '100', '500', '1000'],
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            }}
                            className="ant-border-space"
                            locale={{
                                emptyText: <Empty description="Data not found" />,
                            }}
                        />
                    </div>
                </Card>
        </>
    );
}

export default Index;