import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button, DatePicker, Select } from 'antd';
import { postData, putData, fetchData } from '../../helper/api';
import { toast } from 'react-toastify';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';

const ModalAddEmployee = ({ open, setOpen, initialData, trigerFetchData, setTrigerFetchData }) => {
    const location = useLocation();
    const history = useHistory();

    const [form] = Form.useForm();
    const [branches, setBranches] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleAddEmployee = () => {
        form.validateFields()
            .then((values) => {
                if (initialData) {
                    putData(`/users/${initialData.id}`, values)
                        .then((response) => {
                            if (response.status === 200) {
                                toast.success('Employee updated successfully');
                                setOpen(false);
                                form.resetFields();
                                setTrigerFetchData(!trigerFetchData);
                            }
                        })
                        .catch((error) => {
                            toast.error(error.response?.data?.message || 'Gagal memperbarui karyawan');
                        });
                } else {
                    postData('/users', values)
                        .then((response) => {
                            if (response.status === 201) {
                                toast.success('Employee added successfully');
                                setOpen(false);
                                form.resetFields();
                                setTrigerFetchData(!trigerFetchData);
                            }
                        })
                        .catch((error) => {
                            toast.error(error.response?.data?.message || 'Gagal menambahkan karyawan');
                        });
                }
            })
            .catch(() => {
                toast.error('Mohon periksa kembali form anda');
            });
    };

    useEffect(() => {
        if (initialData) {
            form.setFieldsValue({
                ...initialData,
                salary_date: initialData.salary_date ? moment(initialData.salary_date, 'YYYY-MM-DD') : null,
            });
        }
    }, [initialData]);

    useEffect(() => {
        const fetchBranches = async () => {
            setLoading(true);
            try {
                const response = await fetchData('/branches');
                if (response.status === 200) {
                    setBranches(response.data.data);
                }
            } catch (error) {
                toast.error('Gagal mengambil data cabang');
            } finally {
                setLoading(false);
            }
        };

        fetchBranches();
    }, []);

    return (
        <Modal
            title={initialData ? "Edit Karyawan" : "Tambah Karyawan"}
            open={open}
            onCancel={() => {
                setOpen(false);
                form.resetFields();
            }}
            footer={null}
        >
            <Form form={form} onFinish={handleAddEmployee} layout="vertical">
                <Form.Item
                    label="Cabang"
                    name="branch_id"
                    rules={[
                        { required: true, message: 'Cabang wajib dipilih' }
                    ]}
                >
                    <Select
                        placeholder="Pilih cabang"
                        loading={loading}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {branches.map((branch) => (
                            <Select.Option key={branch.id} value={branch.id}>
                                {branch.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Nama"
                    name="name"
                    rules={[
                        { required: true, message: 'Nama wajib diisi' },
                        { min: 3, message: 'Nama minimal 3 karakter' },
                        { pattern: /^[a-zA-Z\s]*$/, message: 'Nama hanya boleh berisi huruf' }
                    ]}
                >
                    <Input placeholder="Masukkan nama" />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        { required: true, message: 'Email wajib diisi' },
                        { type: 'email', message: 'Format email tidak valid' }
                    ]}
                >
                    <Input placeholder="Masukkan email" />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        { required: !initialData, message: 'Password wajib diisi' },
                        { min: 6, message: 'Password minimal 6 karakter' }
                    ]}
                >
                    <Input.Password placeholder="Masukkan password" />
                </Form.Item>

                <Form.Item
                    label="No. Telepon"
                    name="phone"
                    rules={[
                        { required: true, message: 'No. telepon wajib diisi' },
                        { pattern: /^[0-9]*$/, message: 'No. telepon hanya boleh berisi angka' },
                        { min: 10, message: 'No. telepon minimal 10 digit' },
                        { max: 13, message: 'No. telepon maksimal 13 digit' }
                    ]}
                >
                    <Input placeholder="Masukkan no. telepon" />
                </Form.Item>

                <Form.Item
                    label="Alamat"
                    name="address"
                    rules={[
                        { required: true, message: 'Alamat wajib diisi' },
                        { min: 10, message: 'Alamat minimal 10 karakter' }
                    ]}
                >
                    <Input.TextArea placeholder="Masukkan alamat" />
                </Form.Item>

                <Form.Item
                    label="Status"
                    name="status"
                    rules={[
                        { required: true, message: 'Status wajib dipilih' }
                    ]}
                >
                    <Select placeholder="Pilih status">
                        <Select.Option value="active">Aktif</Select.Option>
                        <Select.Option value="inactive">Tidak Aktif</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Role"
                    name="role"
                    rules={[
                        { required: true, message: 'Role wajib diisi' }
                    ]}
                >
                    <Select placeholder="Pilih role">
                        <Select.Option value="admin">Admin</Select.Option>
                        <Select.Option value="employee">Karyawan</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 0 }}>
                    <div className='flex gap-2'>
                        <Button
                            type="default"
                            onClick={() => {
                                setOpen(false);
                                form.resetFields();
                            }}
                        >
                            Batal
                        </Button>
                        <Button 
                            type="primary" 
                            htmlType="submit"
                        >
                            {initialData ? 'Perbarui' : 'Tambah'}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalAddEmployee;