import React from 'react';
import { Modal, List, Avatar, Image } from 'antd';
import { formatCurrency } from '../../helper/formatCurrency';

const CalenderViewOrderModal = ({ isModalOpen, handleOk, handleCancel, data }) => {

    const renderDataSection = (title, data) => (
        <div className='mb-4'>
            <h3 className='font-bold text-xl'>{title}</h3>
            <List
                dataSource={data}
                renderItem={item => (
                    <List.Item>
                        <div className='flex h-full justify-start items-center'>
                            <div>
                                <List.Item.Meta
                                    avatar={<Image src={item.detail?.shoes_photos[0]?.url_photo || null} width={100} />}

                                />
                            </div>
                            <div>
                                <p className='font-bold text-md'>{item.customer?.name}</p>
                                <p>Item: {item.detail?.item?.brand} - {item.detail?.item?.name} ({item.detail?.item?.color})</p>
                                <p>Treatment: {item.detail?.treatment?.name}</p>
                                <p>Description: {item.detail?.description}</p>
                            </div>
                        </div>
                    </List.Item>
                )}
            />
        </div>
    );

    const renderDataSectionPaid = (title, data) => (
        <div className='mb-2'>
            <h3 className='font-bold text-xl'>{title}</h3>
            <List
                dataSource={data}
                renderItem={item => (
                    <List.Item>
                            <div className='ml-2'>
                                <p className='font-bold text-md'>{item.customer?.name}</p>
                                <p>Total: {formatCurrency(parseInt(item.payment?.total_price))}</p>
                            </div>
                    </List.Item>
                )}
            />
        </div>
    );
    const renderDataSectionDP = (title, data) => (
        <div className='mb-2'>
            <h3 className='font-bold text-xl'>{title}</h3>
            <List
                dataSource={data}
                renderItem={item => (
                    <List.Item>
                            <div className='ml-2'>
                                <p className='font-bold text-md'>{item.customer?.name}</p>
                                <p>Total: {formatCurrency(parseInt(item.payment?.money))}</p>
                            </div>
                    </List.Item>
                )}
            />
        </div>
    );

    return (
        <Modal
            title={`Order Detail - ${data.date}`}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            {renderDataSectionPaid('Unpaid', data.unpaid_data)}
            {renderDataSectionDP('DP', data.dp_data)}
            {renderDataSectionPaid('Paid', data.paid_data)}
            {renderDataSection('Antrian', data.antrian_data)}
            {renderDataSection('Proses', data.process_data)}
            {renderDataSection('Siap Antar', data.siap_diambil_data)}
            {renderDataSection('Selesai', data.selesai_data)}
            {renderDataSection('Batal', data.batal_data)}
        </Modal>
    );
};

export default CalenderViewOrderModal;
