import React from 'react';
import ReactApexChart from "react-apexcharts";
import { Typography, Card } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import { formatCurrency } from '../../../helper/formatCurrency';


function Revenue(props) {

    const { data } = props;
    const { Title, Paragraph } = Typography;

    const lineChart = {
        series: [
            {
                name: "Revenue",
                data: data?.graph_revenue?.data,
                offsetY: 0,
            }
        ],

        options: {
            chart: {
                width: "100%",
                height: 350,
                type: "area",
                toolbar: {
                    show: false,
                },
            },

            legend: {
                show: false,
            },

            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
            },

            yaxis: {
                labels: {
                    style: {
                        fontSize: "14px",
                        fontWeight: 600,
                        colors: ["#8c8c8c"],
                    },
                    formatter: function (val) {
                        return formatCurrency(parseInt(val));
                    },
                },
            },

            xaxis: {
                labels: {
                    style: {
                        fontSize: "14px",
                        fontWeight: 600,
                        colors: "#8c8c8c",
                    },
                },
                categories: data?.graph_revenue?.labels,
            },

            tooltip: {
                y: {
                    formatter: function (val) {
                        return formatCurrency(parseInt(val));
                    },
                },
            },
        },
    };

    return (
        <div>
            <Card>
                <div className="linechart">
                    <div>
                        <Title level={5}>Revenue</Title>
                        <Paragraph className="lastweek">
                            than last week <span className="bnb2">-%</span>
                        </Paragraph>
                    </div>
                </div>

                <ReactApexChart
                    className="full-width"
                    options={lineChart.options}
                    series={lineChart.series}
                    type="area"
                    height={350}
                    width={"100%"}
                />

                <div className='grid grid-cols-4 gap-4 p-4'>
                    <div className='col-span-1 flex justify-center flex-col items-center'>
                        <h1 className="font-bold text-lg">{formatCurrency(parseInt(data?.average_overview?.averageRevenuePerDay))}</h1>
                        <p className="text-sm">Avg. Revenue/day</p>
                    </div>
                    <div className='col-span-1 flex justify-center flex-col items-center'>
                        <h1 className="font-bold text-lg">{formatCurrency(parseInt(data?.average_overview?.averageRevenuePerWeek))}</h1>
                        <p className="text-sm">Avg. Revenue/week</p>
                    </div>
                    <div className='col-span-1 flex justify-center flex-col items-center'>
                        <h1 className="font-bold text-lg">{formatCurrency(parseInt(data?.average_overview?.averageRevenuePerOrder))}</h1>
                        <p className="text-sm">Avg. Revenue/Order</p>
                    </div>
                    <div className='col-span-1 flex justify-center flex-col items-center'>
                        <h1 className="font-bold text-lg">{formatCurrency(parseInt(data?.average_overview?.averageRevenuePerService))}</h1>
                        <p className="text-sm">Avg. Revenue/Service</p>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default Revenue;