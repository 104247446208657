import React, { useEffect } from 'react';
import { Table, Empty, Card, Input, Button, Popover, Pagination } from 'antd';
import { IoIosAddCircle } from "react-icons/io";
import { fetchData } from '../../helper/api';
import { formatCurrency } from '../../helper/formatCurrency';
import ModalAddItem from './ModalAddItem';
import ActionItem from './ActionItem';
import { useLocation, useHistory } from 'react-router-dom';

function Index(props) {
    const [dataSource, setDataSource] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState("");
    const [trigerFetchData, setTrigerFetchData] = React.useState(false);
    const [listData, setListData] = React.useState([]);

    const location = useLocation();
    const history = useHistory();

    const [openModalAdd, setOpenModalAdd] = React.useState(false);

    const handleOpenModalAdd = () => {
        setOpenModalAdd(true);
    }

    const onSearch = (value) => {
        setSearchValue(value);
    }

    const mappingDataToDataSource = (data) => {
        return data.map((item, index) => {
            return {
                ...item,
                no: index + 1,
                price_display: formatCurrency(parseInt(item.price)),
            };
        });
    }

    useEffect(() => {
        const searchLocation = location.search;
        const newSearchLocation = searchLocation.replace(/&add_update=\d/, "");
        history.push({
            ...location,
            search: newSearchLocation,
        });
        // Fetch data
        let url = `/items?search=${encodeURIComponent(searchValue)}`;

        if (searchValue === "") {
            url = `/items`;
        }

        fetchData(url)
            .then((res) => {
                const data = res.data.data;
                const dataSource = mappingDataToDataSource(data);
                setDataSource(dataSource);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [location.search, trigerFetchData, searchValue])


    useEffect(() => {
        fetchData('/items')
        .then((res) => {
            const data = res.data.data;
            const dataSource = data
            setListData(dataSource);
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);

    const column = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: 'Color',
            dataIndex: 'color',
            key: 'color',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <ActionItem record={record} trigerFetchData={trigerFetchData} setTrigerFetchData={setTrigerFetchData} />
            ),
        },
    ]

    return (
        <>
            <ModalAddItem listData={listData} open={openModalAdd} setOpen={setOpenModalAdd} trigerFetchData={trigerFetchData} setTrigerFetchData={setTrigerFetchData} />
            <Card
                bordered={false}
                className="criclebox tablespace mb-4"
                title={
                    <div style={{ display: "flex", gap: "20px" }}>
                        <h4>Item</h4>
                        <Input
                            style={{ width: "200px", fontSize: "12px" }}
                            placeholder="Search Name / Item / Service"
                            size="small"
                            onPressEnter={(e) => {
                                if (e.key === 'Enter') {
                                    onSearch(e.target.value); // Gantilah dengan nama fungsi pencarian Anda
                                }
                            }}
                        />
                    </div>
                }
                extra={
                    <div style={{ display: "flex", gap: "20px" }}>
                        <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => handleOpenModalAdd()}>
                            <IoIosAddCircle style={{ fontSize: "20px" }} />
                            Add Item
                        </Button>
                    </div>
                }
            >
                <div className="table-responsive">
                    <Table
                        columns={column}
                        dataSource={dataSource}
                        pagination={{
                            position: ['topCenter'],
                            pageSize: 100,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '50', '100', '500', '1000'],
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        }}
                        className="ant-border-space"
                        locale={{
                            emptyText: <Empty description="Data not found" />,
                        }}
                    />
                </div>
            </Card>
        </>
    );
}

export default Index;