import React from 'react';
import { Button, Modal } from 'antd';
import ModalAddDiscount from './ModalAddDiscount';
import { deleteData } from '../../helper/api';
import { toast } from 'react-toastify';

function ActionDiscount(props) {
    const { record, trigerFetchData, setTrigerFetchData } = props;
    const [openModalAdd, setOpenModalAdd] = React.useState(false);
    const [openModalDelete, setOpenModalDelete] = React.useState(false);

    const handleDeleteDiscount = () => {
        deleteData(`/discounts/${record.id}`)
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Discount deleted successfully');
                    setTrigerFetchData(!trigerFetchData);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div style={{ display: "flex", gap: "20px" }}>
             <Modal
                title="Delete"
                open={openModalDelete}
                onOk={() => handleDeleteDiscount()}
                onCancel={() => setOpenModalDelete(false)}
            >
                {/* ARE YOU SURE */}
                <h2>Are you sure want to delete this discount {record.id}?</h2>
            </Modal>
            <ModalAddDiscount open={openModalAdd} setOpen={setOpenModalAdd} initialData={record} trigerFetchData={trigerFetchData} setTrigerFetchData={setTrigerFetchData} />
            <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => setOpenModalAdd(true)}>
                Edit
            </Button>
            {/* <Button className="items-center flex justify-center text-white bg-blue-400" danger className="item-center" onClick={() => setOpenModalDelete(true)}>
                Delete
            </Button> */}
        </div>
    );
}

export default ActionDiscount;