const formatPhoneNumber = (phone) => {
    if (!phone) return '';
    
    // Hapus semua karakter non-digit
    let cleaned = phone.replace(/\D/g, '');
    
    // Hapus awalan 0 atau 62
    if (cleaned.startsWith('0')) {
        cleaned = cleaned.substring(1);
    } else if (cleaned.startsWith('62')) {
        cleaned = cleaned.substring(2);
    }
    
    // Tambahkan +62
    return `+62${cleaned}`;
};

export default formatPhoneNumber;