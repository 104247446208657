import { Modal, Input, Select, Button, Form } from 'antd';
import { followUpTemplates } from '../constant/data';


const { TextArea } = Input;

const FollowUpPopup = ({ visible, onCancel, onSubmit, lead, currentUser }) => {
  const [form] = Form.useForm();

  const handleTemplateChange = (templateKey) => {
    const template = followUpTemplates[templateKey].template
      .replace('{name}', lead?.name || '')
      .replace('{agent}', currentUser?.name || '');
    
    form.setFieldsValue({ notes: template });
  };

  return (
    <Modal
      title="Follow Up Lead"
      open={visible}
      onCancel={onCancel}
      footer={null}
      width={600}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={{
          status: 'Tidak Respon',
          notes: ''
        }}
      >
        <Form.Item
          name="templateKey"
          label="Template Pesan"
        >
          <Select
            onChange={handleTemplateChange}
            options={Object.entries(followUpTemplates).map(([key, value]) => ({
              label: value.title,
              value: key
            }))}
          />
        </Form.Item>

        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true }]}
        >
          <Select
            options={[
              { label: 'Berhasil', value: 'Berhasil' },
              { label: 'Tidak Respon', value: 'Tidak Respon' },
              { label: 'Tidak Berminat', value: 'Tidak Berminat' }
            ]}
          />
        </Form.Item>

        <Form.Item
          name="notes"
          label="Pesan"
          rules={[{ required: true }]}
        >
          <TextArea rows={6} />
        </Form.Item>

        <div className="flex justify-end gap-2">
          <Button onClick={onCancel}>
            Batal
          </Button>
          <Button type="primary" onClick={() => form.submit()}>
            Kirim & Simpan
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default FollowUpPopup;