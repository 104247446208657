import React, { useEffect } from 'react'
import { Input, Button, Table, Empty, Card } from 'antd'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { formatCurrency } from '../../helper/formatCurrency'
import { set } from 'date-fns'
import ShiftDashboard from './ShiftDashboard'
import { fetchData, postData } from '../../helper/api'
import { toast } from 'react-toastify'
import moment from 'moment'
import { FaCashRegister } from 'react-icons/fa'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useGeneral } from '../../contexts/GeneralContext'

export default function Index() {
    const { handleGetProfile } = useGeneral()
    const [ShiftDashboardOpen, setShiftDashboardOpen] = React.useState(false)
    const [expenses, setExpenses] = React.useState(0)
    const [trigerFetchData, setTrigerFetchData] = React.useState(false)
    const [dataUser, setDataUser] = React.useState()
    const history = useHistory()

    const [dataShift, setDataShift] = React.useState([])
    const [dataShiftList, setDataShiftList] = React.useState([])

    const formik = useFormik({
        initialValues: {
            cashInDrawer: 0
        },

        onSubmit: values => {
            postData('/shifts', {
                cash_initial: values.cashInDrawer,
            })
                .then((response) => {
                    fetchData('/get-profile')
                        .then((response) => {
                            const data = response.data.data
                            setDataShift(data.shifts)
                            setShiftDashboardOpen(true)
                            formik.setFieldValue('cashInDrawer', 0)
                            handleGetProfile()
                            toast.success('Shift Opened')
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    })

    useEffect(() => {
        // Get data shift
        fetchData('/shifts')
            .then((response) => {
                const data = response.data.data
                const moveData = data.map((item) => {
                    return {
                        ...item,
                        name: item.employee_shift.name,
                        start_time: moment(item.start_time).format('DD MMM YYYY HH:mm:ss'),
                        end_time: item.end_time ? moment(item.end_time).format('DD MMM YYYY HH:mm:ss') : '-',
                        uang_awal: formatCurrency(parseInt(item.cash_initial || 0)),
                        uang_tunai_masuk: formatCurrency(parseInt(item.cash_expected || 0)),
                        total_seharusnya: formatCurrency(parseInt(item.cash_expected) + parseInt(item.cash_initial) - parseInt(item.expense)),
                        total_sebenarnya: formatCurrency(parseInt(item.cash_actual || 0)),
                        beda: formatCurrency(parseInt(item.difference || 0)),
                        expense: formatCurrency(parseInt(item.expense || 0)),
                    }
                })
                setDataShiftList(moveData)
            })
            .catch((error) => {
                console.log(error)
            })


        fetchData('/get-profile')
            .then((response) => {
                const data = response.data.data
                setDataUser(data.user)
                setDataShift(data.shifts)
                if (data.shifts.end_time === null) {
                    setShiftDashboardOpen(true)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }, [ShiftDashboardOpen, trigerFetchData])

    const columnsShift = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Start Time',
            dataIndex: 'start_time',
            key: 'start_time',
        },
        {
            title: 'End Time',
            dataIndex: 'end_time',
            key: 'end_time',
        },
        {
            title: 'Initial Cash',
            dataIndex: 'uang_awal',
            key: 'uang_awal',
        },
        {
            title: 'Cash Inflow',
            dataIndex: 'uang_tunai_masuk',
            key: 'uang_tunai_masuk',
        },
        {
            title: 'Cash Expense',
            dataIndex: 'expense',
            key: 'expense',
        },
        {
            title: 'Expected Total',
            dataIndex: 'total_seharusnya',
            key: 'total_seharusnya',
        },
        {
            title: 'Actual Total',
            dataIndex: 'total_sebenarnya',
            key: 'total_sebenarnya',
        },
        {
            title: 'Difference',
            dataIndex: 'beda',
            key: 'beda',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <Button type="link" onClick={() => history.push(`/shift/${record.id}`)}>
                    Detail
                </Button>
            ),
        },
    ];

    return (
        <div>
            {!ShiftDashboardOpen ? (
                <>
                    <div className="max-w-md mx-auto bg-white p-8 rounded-2xl shadow-lg border border-gray-100">
                        <div className="text-center mb-8">
                            <div className="inline-block p-3 bg-blue-50 rounded-full mb-4">
                                <FaCashRegister className="text-3xl text-blue-500" />
                            </div>
                            <h1 className="text-2xl font-bold text-gray-800">Open New Shift</h1>
                            <p className="text-gray-500 mt-2">Enter the initial amount in your cash drawer</p>
                        </div>

                        <div className="space-y-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Cash in Drawer
                                </label>
                                <div className="relative">
                                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                                        Rp
                                    </span>
                                    <Input
                                        name="cashInDrawer"
                                        value={formatCurrency(parseInt(formik.values.cashInDrawer) || 0)}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            const numericValue = parseFloat(value.replace(/[^\d]/g, ''));
                                            const newValue = isNaN(numericValue) ? 0 : numericValue;
                                            formik.setFieldValue('cashInDrawer', newValue);
                                        }}
                                        className="w-full pl-10 pr-4 py-3 border-2 border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
                                    />
                                </div>
                                {formik.touched.cashInDrawer && formik.errors.cashInDrawer && (
                                    <div className="mt-2 text-sm text-red-600 flex items-center gap-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                        </svg>
                                        {formik.errors.cashInDrawer}
                                    </div>
                                )}
                            </div>

                            <div className="pt-4">
                                <Button
                                    onClick={formik.handleSubmit}
                                    className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-3 rounded-lg transition-all duration-200 flex items-center justify-center gap-2 shadow-md hover:shadow-lg"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                                    </svg>
                                    Open Shift
                                </Button>
                                <p className="text-center text-sm text-gray-500 mt-4">
                                    Make sure to count your cash carefully before starting
                                </p>
                            </div>
                        </div>
                    </div>
                    {dataUser?.role === 'superadmin' ? (
                        <>
                            <Card>
                                <div className="table-responsive" >
                                    <Table
                                        columns={columnsShift}
                                        dataSource={dataShiftList}
                                        className="ant-border-space"
                                        locale={{
                                            emptyText: <Empty description="Data not found" />,
                                        }}
                                        pagination={{
                                            position: ['bottomCenter'],
                                            pageSize: 10,
                                            showSizeChanger: true,
                                            pageSizeOptions: ['5', '10', '20', '30', '40'],
                                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                        }}
                                    />
                                </div>
                            </Card>
                        </>
                    ) : (
                        <></>
                    )}

                </>
            ) : (
                <>
                    <ShiftDashboard
                        dataShift={dataShift}
                        setShiftDashboardOpen={setShiftDashboardOpen}
                        trigerFetchData={trigerFetchData}
                        setTrigerFetchData={setTrigerFetchData}
                    />
                </>
            )}

        </div>
    )
}
