import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Row, Col, Spin, Alert, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import CustomerPatternAnalysis from './components/CustomerPattern';
import { API_URL } from '../../helper/api';

const baseUrl = API_URL

const CustomerPattern = () => {
    const { customerId } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [analysis, setAnalysis] = useState(null);


    // Buat instance axios khusus untuk AI dengan timeout lebih lama
    const aiAxios = axios.create({
        baseURL: baseUrl,
        timeout: 60000, // 60 detik
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
        }
    });

    const fetchAnalysis = async (retryCount = 0) => {
        try {
            setLoading(true);
            setError(null);
            
            const response = await aiAxios.get(`/customer-pattern/${customerId}`);
            setAnalysis(response.data.data);
            
        } catch (err) {
            console.error('Error fetching customer analysis:', err);
            
            // Handle timeout error
            if (err.code === 'ECONNABORTED' && retryCount < 2) {
                console.log(`Request timeout. Retrying... Attempt ${retryCount + 1}`);
                return fetchAnalysis(retryCount + 1);
            }
            
            // Set error message based on error type
            setError(
                err.code === 'ECONNABORTED'
                    ? 'Analisis AI membutuhkan waktu lebih lama dari biasanya. Silakan coba lagi.'
                    : 'Gagal memuat analisis customer. Silakan coba lagi.'
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (customerId) {
            fetchAnalysis();
        }
    }, [customerId]);

    if (loading) {
        return (
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Customer Pattern Analysis"
                        >
                            <div className="text-center py-8">
                                <Spin size="large" />
                                <div style={{ marginTop: 16 }}>
                                    Menganalisis data customer...
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }

    if (error) {
        return (
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Customer Pattern Analysis"
                        >
                            <Alert
                                message="Error"
                                description={error}
                                type="error"
                                showIcon
                                action={
                                    <Button
                                        icon={<ReloadOutlined />}
                                        onClick={() => fetchAnalysis()}
                                        type="primary"
                                    >
                                        Coba Lagi
                                    </Button>
                                }
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <div className="tabled">
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Card
                        bordered={false}
                        className="criclebox tablespace mb-24"
                        title={
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span>Customer Pattern Analysis</span>
                                <Button
                                    icon={<ReloadOutlined />}
                                    onClick={() => fetchAnalysis()}
                                    type="primary"
                                >
                                    Refresh
                                </Button>
                            </div>
                        }
                    >
                        <CustomerPatternAnalysis analysis={analysis} />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CustomerPattern; 