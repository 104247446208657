import { API_URL, deleteData, fetchData, postData, putData } from '../../helper/api'

const BASE_URL = API_URL;

export const fetchLeads = async (filters = {}) => {
  const response = await fetchData(`${BASE_URL}/leads`, { params: filters });
  return response.data;
};

export const createLead = async (data) => {
  const response = await postData(`${BASE_URL}/leads`, data);
  return response.data;
};

export const updateLead = async (id, data) => {
  const response = await putData(`${BASE_URL}/leads/${id}`, data);
  return response.data;
};

export const deleteLead = async (id) => {
  const response = await deleteData(`${BASE_URL}/leads/${id}`);
  return response.data;
};

export const fetchFollowUps = async () => {
  const response = await fetchData(`${BASE_URL}/leads/follow-up-today`);
  return response.data;
};

export const createFollowUp = async (leadId, data) => {
  const response = await postData(`${BASE_URL}/leads/${leadId}/follow-up`, data);
  return response.data;
};

export const fetchLeadReports = async (startDate, endDate) => {
  const response = await fetchData(`${BASE_URL}/leads/reports`, {
    params: { startDate, endDate }
  });
  return response.data;
}; 