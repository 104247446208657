import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, DatePicker, Button, Radio, Card, Col, Row, Typography } from 'antd';
import { useState } from 'react';
import { optionRadioPaymentMethod, optionRadioPaymentStatus } from '../../models/generalData';
import { TRANSACTION_PAYMENT_OPTION } from './helper';
import { formatCurrency } from '../../helper/formatCurrency';
import moment from 'moment';
import { InvoiceDataGenerate, PrintInvoice } from '../Printer/PrintInvoice';
import UploadPhotoEvidence from './UploadPhotoEvidence';

const ModalHandlePayment = ({ formik, open, setOpen, initialData }) => {
    const [selectPaymentStatus, setSelectPaymentStatus] = useState(TRANSACTION_PAYMENT_OPTION.PAID);

    return (
        <div>
            <Modal
                title="Payment"
                open={open}
                onCancel={() => setOpen(false)}
                footer={null}
            >
                <>
                    <Form>

                        <Form.Item validateStatus={formik.errors.payment_status && 'error'}>
                            <div><label>Payment Status : </label></div>
                            <Radio.Group
                                buttonStyle="solid"
                                style={{ marginBottom: "20px", width: "100%" }}
                                value={selectPaymentStatus}
                                onChange={(e) => {
                                    formik.setFieldValue("payment.status", e.target.value);
                                    setSelectPaymentStatus(e.target.value);
                                }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', width: "100%" }}>
                                    {optionRadioPaymentStatus.map((item) => {
                                        return (
                                            <Radio.Button value={item.value} style={{ width: "100%" }}>
                                                {item.label}
                                            </Radio.Button>
                                        );
                                    })}
                                </div>
                            </Radio.Group>
                        </Form.Item>
                        {selectPaymentStatus === TRANSACTION_PAYMENT_OPTION.PAID &&
                            <>
                                <Form.Item validateStatus={formik.errors.payment?.method && 'error'}>
                                    <div><label>Payment Method : </label></div>
                                    <Radio.Group
                                        buttonStyle="solid"
                                        style={{ marginBottom: "20px", width: "100%" }}
                                        value={formik.values.payment.method}
                                        onChange={(e) => {
                                            formik.setFieldValue("payment.method", e.target.value);
                                        }}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', width: "100%" }}>
                                            {optionRadioPaymentMethod.map((item) => {
                                                return (
                                                    <Radio.Button value={item.value} style={{ width: "100%" }}>
                                                        {item.label}
                                                    </Radio.Button>
                                                );
                                            })}
                                        </div>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item validateStatus={formik.errors.payment?.date && 'error'}>
                                    <div><label>Payment Date : </label></div>
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        format={"dddd, DD MMMM YYYY"}
                                        value={formik.values.payment?.date ? moment(formik.values.payment?.date) : null}
                                        onChange={(value) => { formik.setFieldValue("payment.date", value ? value.toISOString() : null) }}
                                    />
                                </Form.Item>

                                {formik.values.payment?.method !== 'cash' && (
                                    <>
                                        <div><label>Upload Bukti Bayar : </label></div>
                                        <UploadPhotoEvidence formik={formik} />
                                    </>
                                )}

                                <Form.Item validateStatus={formik.errors.quantity && 'error'}>
                                    <div><label>Total Bayar : </label></div>
                                    <Input
                                        type='text'
                                        value={formatCurrency(parseInt(formik.values.payment?.money) || 0)}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            // Mengabaikan karakter selain digit (contoh: comma atau titik)
                                            const numericValue = parseFloat(value.replace(/[^\d]/g, ''));

                                            // Menangani nilai awal NaN
                                            const newValue = isNaN(numericValue) ? 0 : numericValue;

                                            formik.setFieldValue("payment.money", newValue);
                                            formik.setFieldValue("payment.change", parseInt(newValue) - (parseInt(formik.values.payment.total_price) + parseInt(formik.values.payment.total_discount)));
                                        }}
                                    />
                                </Form.Item>
                            </>
                        }
                        {/* Total Harga dan diskon */}
                        <Card style={{ width: "100%", textAlign: 'center', marginBottom: "20px" }}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{ fontWeight: 'bold' }}>Sub Total:</span>
                                        <span>{formatCurrency(parseInt(formik.values.payment.total_price))}</span>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{ fontWeight: 'bold' }}>Diskon:</span>
                                        <span>{formatCurrency(parseInt(formik.values.payment.total_discount || 0))}</span>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{ fontWeight: 'bold' }}>Grand Total:</span>
                                        <span>{formatCurrency(parseInt(formik.values.payment.final_price))}</span>
                                    </div>
                                </Col>
                                {/* Tambahkan data atau informasi lainnya di sini */}
                            </Row>
                        </Card>
                    </Form>
                    <div>
                        {/* <Invoice open={openModalInvoice} setOpen={setOpenModalInvoice} formik={formik} /> */}
                        {formik.values?.payment?.code && <>
                            {/* <Button type="dash" onClick={() => setOpenModalInvoice(true)}>Download Invoice</Button> */}
                            {/* <Button className='ml-3' type="dash" onClick={async () => PrintInvoice(formik.values)}>Cetak Invoice</Button>
                            <Button className='ml-3' type="dash" onClick={async () => PrintChecklist(formik.values)}>Cetak Checklist</Button> */}
                        </>}

                    </div>
                    <div className='mt-5' style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={() => setOpen(false)}>Batal</Button>
                    <Button className="items-center flex justify-center text-white bg-blue-400" htmlType="submit" style={{ marginLeft: '8px' }} onClick={() => formik.handleSubmit()}>
                        {initialData ? "Update Transaction" : "Tambah Transaction"}
                    </Button>
                    </div>
                </>
            </Modal>
        </div>
    );
};

export default ModalHandlePayment;