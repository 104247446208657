import React, { useEffect, useState } from 'react';
import { DatePicker, Card, Table, Empty } from 'antd';
import 'antd/dist/antd.css';
import dayjs from 'dayjs';
import { fetchData } from '../../../helper/api';
import { formatCurrency } from '../../../helper/formatCurrency';
import { Popover, Button } from 'antd';
import { FaEye } from "react-icons/fa";
import ExportExcel from './ExportExcel';

const { MonthPicker } = DatePicker;

function Index(props) {
    const [dataFinance, setDataFinance] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(dayjs());
    const [summary, setSummary] = useState([]);
    const handleMonthChange = (date, dateString) => {
        if (date) {
            setSelectedMonth(date.toISOString());
        } else {
            console.error("Invalid date selected");
        }
    };

    const renderUnpaidData = (text) => {
        return (
            <div className='flex flex-col gap-4'>
                {text.unpaid_data?.map((item, index) => (
                    <div>
                        <p>Nama : {item.customer.name}</p>
                        <p>Phone : {item.customer.phone}</p>
                        <p>Jumlah : {formatCurrency(parseInt(item.payment.final_price || 0))}</p>
                    </div>
                ))}
            </div>
        );
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text) => <div>
                {text === 'Total' ? <p className='font-bold text-black'>{text}</p> : dayjs(text).format('dddd, DD/MM/YYYY')}
            </div>,
        },
        {
            title: 'Revenue',
            dataIndex: 'revenue',
            key: 'revenue',
            render: (text) => <span>{formatCurrency(text)}</span>,
        },
        {
            title: 'Paid',
            dataIndex: 'paid',
            key: 'paid',
            render: (text) => <span>{formatCurrency(text)}</span>,
        },
        {
            title: 'Cash',
            dataIndex: 'cash',
            key: 'cash',
            render: (text) => <span>{formatCurrency(text)}</span>,
        },
        {
            title: 'Transfer',
            dataIndex: 'bank_transfer',
            key: 'transfer',
            render: (text) => <span>{formatCurrency(text)}</span>,
        },
        {
            title: 'Qris',
            dataIndex: 'qris',
            key: 'qris',
            render: (text) => <span>{formatCurrency(text)}</span>,
        },
        {
            title: 'Unpaid',
            key: 'unpaid',
            render: (text) => <>
                <span>{formatCurrency(text.unpaid)}</span>
                <Popover content={renderUnpaidData(text)} title="Detail">
                    <Button className='scale-75' type="link" icon={<FaEye />} />
                </Popover>
            </>,
        },
        {
            title: 'Income',
            dataIndex: 'income',
            key: 'income',
            render: (text) => <span>{formatCurrency(text)}</span>,
        },
        {
            title: 'Expense',
            dataIndex: 'expense',
            key: 'expense',
            render: (text) => <span>{formatCurrency(text)}</span>,
        },
        // {
        //     title: 'Capital In',
        //     dataIndex: 'capital_in',
        //     key: 'capital_in',
        //     render: (text) => <span>{formatCurrency(text)}</span>,
        // },
    ];

    useEffect(() => {
        fetchData('/genereteDashboardFinanceReport', {
            params: {
                month: dayjs(selectedMonth).format('MM'),
                year: dayjs(selectedMonth).format('YYYY'),
            },
        }).then((res) => {
            const data = res.data.data;
            setDataFinance(data.finance_report);
            setSummary(data?.summarized_data);
        });
    }, [selectedMonth]);

    return (
        <div>
            <div className='flex gap-4'>
                <MonthPicker
                    className='rounded-lg w-60'
                    onChange={handleMonthChange}
                    placeholder="Select month"
                    format="MMMM YYYY"
                    picker="month"
                    defaultValue={dayjs()}
                />

                <ExportExcel financeData={{ finance_report: dataFinance, summarized_data: summary }} />
            </div>
            <div className='p-10 bg-white rounded-lg shadow-lg mt-4 w-full'>
                <div className='flex flex-col gap-2'>
                    <div className='w-full items-center flex justify-between border-b border-black'>
                        <p> Total Revenue: </p>
                        <p className='font-bold'>{formatCurrency(summary?.totalOrder || 0)} <br /></p>
                    </div>
                    <div className='w-full items-center flex justify-between border-b border-black'>
                        <p> Total Paid:</p>
                        <p className='font-bold'>{formatCurrency(summary?.totalPaid || 0)} <br /></p>
                    </div>
                    <div className='w-full items-center flex justify-between border-b border-black'>
                        <p> Total Unpaid:</p>
                        <p className='font-bold'>{formatCurrency(summary?.totalUnpaid || 0)} <br /></p>
                    </div>
                    <div className='w-full items-center flex justify-between border-b border-black'>
                        <p> Total Income:</p>
                        <p className='font-bold'>{formatCurrency(summary?.totalIncome || 0)} <br /></p>
                    </div>
                    <div className='w-full items-center flex justify-between border-b border-black'>
                        <p> Total Expense:</p>
                        <p className='font-bold'>{formatCurrency(summary?.totalExpense || 0)} <br /></p>
                    </div>
                    {/* <div className='w-full items-center flex justify-between border-b border-black'>
                        <p> Total Capital In:</p>
                        <p className='font-bold'>{formatCurrency(summary?.totalCapitalIn || 0)} <br /></p>
                    </div> */}
                    <div className='w-full items-center flex justify-between border-b border-black'>
                        <p> Total Cash In:</p>
                        <p className='font-bold'>{formatCurrency(summary?.totalCash || 0)} <br /></p>
                    </div>
                    <div className='w-full items-center flex justify-between border-b border-black'>
                        <p> Total Transfer In:</p>
                        <p className='font-bold'>{formatCurrency(summary?.totalBankTransfer || 0)} <br /></p>
                    </div>
                    <div className='w-full items-center flex justify-between border-b border-black'>
                        <p> Total Qris In:</p>
                        <p className='font-bold'>{formatCurrency(summary?.totalQris || 0)} <br /></p>
                    </div>
                    <div className='w-full items-center flex justify-between border-b border-black'>
                        <p> Profit (Income - Expense):</p>
                        <p className='font-bold'>{formatCurrency(summary?.totalIncome - summary?.totalExpense || 0)} <br /></p>
                    </div>
                </div>
            </div>
            <Card className='mt-5 mb-10'>
                <div className="table-responsive">
                    <Table
                        columns={columns}
                        dataSource={dataFinance}
                        pagination={false}
                        // footer={() =>
                        //     <div className="flex gap-3 w-full">
                        //         <div className="font-semibold min-w-[180px] mr-[40px]">Total</div>
                        //         <div className='flex w-full'>
                        //             <p className="font-semibold min-w-[60px] w-100 px-[20px]">{formatCurrency(parseInt(dataFinance?.total?.revenue))}</p>
                        //             <p className="font-semibold min-w-[60px] w-100 px-[20px]">{formatCurrency(parseInt(dataFinance?.total?.paid))}</p>
                        //             <p className="font-semibold min-w-[60px] w-100 px-[20px]">{formatCurrency(parseInt(dataFinance?.total?.unpaid))}</p>
                        //             <p className="font-semibold min-w-[60px] w-100 px-[20px]">{formatCurrency(parseInt(dataFinance?.total?.income))}</p>
                        //             <p className="font-semibold min-w-[60px] w-100 px-[20px]">{formatCurrency(parseInt(dataFinance?.total?.expense))}</p>
                        //             <p className="font-semibold min-w-[60px] w-100 px-[20px]">{formatCurrency(parseInt(dataFinance?.total?.capital_in))}</p>
                        //         </div>
                        //     </div>
                        // }
                        className="ant-border-space"
                        locale={{
                            emptyText: <Empty description="Data not found" />,
                        }}
                    />
                </div>
            </Card>

        </div>
    );
}

export default Index;