import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography, Statistic, List, DatePicker, message, Skeleton } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title as ChartTitle,
  Tooltip,
  Legend
} from 'chart.js';
import styled from 'styled-components';
import DOMPurify from 'dompurify';
import moment from 'moment';
import axios from 'axios';
import { API_URL, fetchData } from '../../helper/api';
import { useQuery } from '../../helper/useQuery';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartTitle,
  Tooltip,
  Legend
);

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const StyledCard = styled(Card)`
  margin-bottom: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
`;

const AiAnalysisCard = styled(Card)`
  margin-bottom: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);

  .ai-analysis {
    .analysis-header {
      margin-bottom: 32px;
      padding-bottom: 16px;
      border-bottom: 2px solid #f0f0f0;

      h3 {
        color: #1890ff;
        font-size: 24px;
        margin-bottom: 16px;
      }

      .analysis-intro {
        color: #666;
        font-size: 16px;
        line-height: 1.6;
      }
    }

    .analysis-section {
      margin-bottom: 28px;
      padding: 20px;
      background: #fafafa;
      border-radius: 8px;
      transition: all 0.3s ease;

      &:hover {
        background: #f0f7ff;
        box-shadow: 0 2px 8px rgba(24,144,255,0.1);
      }

      h4 {
        color: #1890ff;
        font-size: 18px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        &::before {
          content: '';
          display: inline-block;
          width: 4px;
          height: 18px;
          background: #1890ff;
          margin-right: 12px;
          border-radius: 2px;
        }
      }

      p {
        color: #333;
        line-height: 1.8;
        margin-bottom: 16px;
      }

      ul {
        margin: 16px 0;
        padding-left: 20px;

        &.treatment-insights,
        &.customer-insights,
        &.recommendations {
          list-style: none;
          padding-left: 0;

          li {
            position: relative;
            padding-left: 24px;
            margin-bottom: 12px;
            line-height: 1.6;
            color: #444;

            &::before {
              content: '•';
              color: #1890ff;
              font-size: 20px;
              position: absolute;
              left: 0;
              top: -2px;
            }
          }
        }

        &.recommendations li {
          background: #e6f7ff;
          padding: 12px 16px 12px 32px;
          border-radius: 6px;
          margin-bottom: 12px;

          &::before {
            content: '→';
            left: 12px;
            top: 10px;
          }

          strong {
            color: #1890ff;
            display: block;
            margin-bottom: 4px;
          }
        }
      }
    }
  }
`;

const AiDashboard = (props) => {
  const [data, setData] = useState(null);
  const [dateRange, setDateRange] = useState([
    moment().startOf('month'),
    moment().endOf('month')
  ]);
  const [loading, setLoading] = useState(true);

  const query = useQuery();
  
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');


  const fetchDataAI = async (startDate, endDate) => {
    try {
      setLoading(true);

      // Set timeout 30 detik (default axios adalah 10 detik)
      const response = await fetchData('/ai-analysis', {
        params: {
          startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
          endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD')
        },
        timeout: 30000 // 30 detik
      });

      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Tambahkan notifikasi error jika timeout
      if (error.code === 'ECONNABORTED') {
        message.error('Request timeout. AI analysis membutuhkan waktu lebih lama dari biasanya.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataAI(startDate, endDate);
  }, [startDate, endDate]);

  const handleDateChange = (dates) => {
    if (dates) {
      setDateRange(dates);
    }
  };

  if (loading) {
    return (
      <div className="">
        {/* <Row justify="space-between" align="middle" className="mb-6">
          <Col>
            <Title level={2} className="text-2xl md:text-3xl">Business Analytics Dashboard</Title>
          </Col>
        </Row> */}

        {/* Performance Metrics Skeleton */}
        <Row gutter={[16, 16]} className="mt-6">
          {[1, 2, 3, 4].map(key => (
            <Col xs={24} sm={12} md={6} key={key}>
              <StyledCard>
                <Skeleton active paragraph={{ rows: 1 }} />
              </StyledCard>
            </Col>
          ))}
        </Row>

        {/* Chart and Customer Analysis Skeleton */}
        <Row gutter={[16, 16]} className="mt-6">
          <Col xs={24} lg={16}>
            <StyledCard>
              <Skeleton.Input style={{ width: '100%', height: '400px' }} active />
            </StyledCard>
          </Col>
          <Col xs={24} lg={8}>
            <StyledCard>
              <Skeleton active paragraph={{ rows: 4 }} />
            </StyledCard>
          </Col>
        </Row>

        {/* AI Insights Skeleton */}
        <AiAnalysisCard>
          <Skeleton active paragraph={{ rows: 10 }} />
        </AiAnalysisCard>
      </div>
    );
  }

  if (!data) return null;

  // Format currency
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR'
    }).format(value);
  };

  // Data untuk chart top treatments
  const chartData = {
    labels: data.metrics.treatmentAnalysis.topTreatments.map(t => t.treatment.name),
    datasets: [{
      label: 'Revenue',
      data: data.metrics.treatmentAnalysis.topTreatments.map(t => parseFloat(t.total_revenue)),
      backgroundColor: '#1890ff',
    }]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => formatCurrency(value)
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  return (
    <div className="">


      {/* Performance Metrics */}
      <Row gutter={[16, 16]} className="">
        <Col xs={24} sm={12} md={6}>
          <StyledCard className="h-full">
            <Statistic
              title="Total Revenue"
              value={data.metrics.businessPerformance.currentPeriod.total_revenue}
              precision={2}
              prefix="Rp"
              className="text-base md:text-lg"
            />
            <Text type="secondary" className="text-sm md:text-base">
              Growth: {' '}
              {data.metrics.businessPerformance.growth >= 0 ? (
                <Text type="success">
                  <ArrowUpOutlined /> {data.metrics.businessPerformance.growth.toFixed(2)}%
                </Text>
              ) : (
                <Text type="danger">
                  <ArrowDownOutlined /> {data.metrics.businessPerformance.growth.toFixed(2)}%
                </Text>
              )}
            </Text>
          </StyledCard>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <StyledCard className="h-full">
            <Statistic
              title="Total Orders"
              value={data.metrics.businessPerformance.currentPeriod.total_orders}
              className="text-base md:text-lg"
            />
          </StyledCard>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <StyledCard className="h-full">
            <Statistic
              title="Total Items"
              value={data.metrics.businessPerformance.currentPeriod.total_items}
              className="text-base md:text-lg"
            />
          </StyledCard>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <StyledCard className="h-full">
            <Statistic
              title="Average Order Value"
              value={data.metrics.businessPerformance.currentPeriod.average_order_value}
              precision={2}
              prefix="Rp"
              className="text-base md:text-lg"
            />
          </StyledCard>
        </Col>
      </Row>

      {/* Charts Section */}
      <Row gutter={[16, 16]} className="mt-6">
        <Col xs={24} lg={16}>
          <StyledCard title="Top Treatments Revenue" className="h-full">
            <div className="h-[300px] md:h-[400px]">
              <Bar data={chartData} options={chartOptions} />
            </div>
          </StyledCard>
        </Col>
        <Col xs={24} lg={8}>
          <StyledCard title="Customer Analysis" className="h-full">
            <List
              size="small"
              dataSource={[
                { title: 'Total Orders', value: data.metrics.customerAnalysis.total_orders },
                { title: 'Unique Customers', value: data.metrics.customerAnalysis.unique_customers },
                { title: 'Returning Customers', value: data.metrics.customerAnalysis.returning_customers },
                { title: 'New Customers', value: data.metrics.customerAnalysis.new_customers },
              ]}
              renderItem={item => (
                <List.Item className="flex justify-between py-2">
                  <Text className="text-sm md:text-base">{item.title}</Text>
                  <Text strong className="text-sm md:text-base">{item.value}</Text>
                </List.Item>
              )}
            />
          </StyledCard>
        </Col>
      </Row>

      {/* AI Insights */}
      <AiAnalysisCard className="mt-6">
        <div 
          className="prose prose-sm md:prose-base lg:prose-lg max-w-none"
          dangerouslySetInnerHTML={{ 
            __html: DOMPurify.sanitize(data.insights) 
          }} 
        />
      </AiAnalysisCard>
    </div>
  );
};

export default AiDashboard; 