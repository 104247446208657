import { Card, Row, Col, Statistic, DatePicker, Progress, Empty } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useLeadsReport } from '../hooks/useLeadsReport';
import moment from 'moment';
import { useQuery } from '../../../helper/useQuery';

const LeadsReport = () => {
  const query = useQuery();
  const prevTab = useRef(null);
  const { RangePicker } = DatePicker;
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const { report, loading, fetchReport } = useLeadsReport();

  useEffect(() => {
    const activeTab = query.get('tab') || '3';
    if (activeTab !== prevTab.current) {
      prevTab.current = activeTab;
      if (activeTab === '3') {
        fetchReport(startDate, endDate);
      }
    }
  }, [query]);

  const handleDateChange = (dates) => {
    if (dates) {
      setStartDate(dates[0].format('YYYY-MM-DD'));
      setEndDate(dates[1].format('YYYY-MM-DD'));
      fetchReport(dates[0].format('YYYY-MM-DD'), dates[1].format('YYYY-MM-DD'));
    }
  };

  const renderSourceDistribution = () => {
    if (!report?.by_source) {
      return <Empty description="Pilih rentang tanggal untuk melihat data" />;
    }

    return Object.entries(report.by_source).map(([source, count]) => {
      const percentage = (count / report.total_leads) * 100;
      return (
        <div key={source} className="mb-4">
          <div className="flex justify-between mb-2">
            <span>{source}</span>
            <span>{count} leads ({percentage.toFixed(1)}%)</span>
          </div>
          <Progress percent={percentage.toFixed(1)} showInfo={false} />
        </div>
      );
    });
  };

  const renderStatusDistribution = () => {
    if (!report?.by_status) {
      return <Empty description="Pilih rentang tanggal untuk melihat data" />;
    }

    return Object.entries(report.by_status).map(([status, count]) => {
      const percentage = (count / report.total_leads) * 100;
      return (
        <div key={status} className="mb-4">
          <div className="flex justify-between mb-2">
            <span>{status}</span>
            <span>{count} leads ({percentage.toFixed(1)}%)</span>
          </div>
          <Progress percent={percentage.toFixed(1)} showInfo={false} />
        </div>
      );
    });
  };

  return (
    <div>
      <div className="mb-6">
        <RangePicker
          onChange={handleDateChange}
          // default value this month
          value={[moment(startDate), moment(endDate)]}
          format="YYYY-MM-DD"
        />
      </div>

      <Row gutter={16}>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Leads"
              value={report?.total_leads || 0}
              loading={loading}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Leads Terkonversi"
              value={report?.converted_leads || 0}
              suffix={`/ ${report?.total_leads || 0}`}
              loading={loading}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Tingkat Konversi"
              value={report?.conversion_rate || 0}
              precision={2}
              suffix="%"
              loading={loading}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Rata-rata Follow Up"
              value={report?.average_follow_ups || 0}
              precision={1}
              loading={loading}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={16} className="mt-6">
        <Col span={12}>
          <Card
            title="Distribusi Sumber"
            loading={loading}
            className="h-full"
          >
            {renderSourceDistribution()}
          </Card>
        </Col>
        <Col span={12}>
          <Card
            title="Distribusi Status"
            loading={loading}
            className="h-full"
          >
            {renderStatusDistribution()}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LeadsReport;