import React, { useState, useEffect } from 'react';
import { Card, Button, Typography, Progress, Modal, TimePicker } from 'antd';
import moment from 'moment';
import { toast } from 'react-toastify';
import { fetchData, postData } from '../../helper/api';
import { useGeneral } from '../../contexts/GeneralContext';

const { Title, Text } = Typography;

// Konstanta untuk lokasi kantor
const OFFICE_LOCATION = {
    latitude: -7.9672739, // sesuaikan dengan lokasi kantor
    longitude: 112.6445213,
    radius: 50 // dalam meter
};

const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // Radius bumi dalam meter
    const φ1 = (lat1 * Math.PI) / 180;
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // dalam meter

    return distance;
};

const EmployeeAbsent = ({ userData }) => {
    const [workState, setWorkState] = useState({
        isWorking: false,
        startTime: null,
        endTime: null,
        progress: 0
    });
    const { getProfile } = useGeneral();

    const [dataUser, setDataUser] = useState(null);
    const [isEndWorkModalVisible, setIsEndWorkModalVisible] = useState(false);
    const [selectedTime, setSelectedTime] = useState(null);
    const [currentAbsentId, setCurrentAbsentId] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        setDataUser(getProfile);
    }, [getProfile]);

    // Tambahkan useEffect untuk cek status absensi
    useEffect(() => {
        if (dataUser?.id) {
            fetchData(`/check-today?employee_id=${dataUser.id}`)
                .then((res) => {
                    const { isAbsent, absentData, hasUnfinishedAbsent, isTodayAbsent } = res.data.data;

                    // Jika sudah absen hari ini atau ada absen yang belum selesai
                    if ((isAbsent || isTodayAbsent) && hasUnfinishedAbsent) {
                        const startTime = moment(absentData.date + ' ' + absentData.clock_in);
                        const endTime = moment(startTime).add(9, 'hours');

                        setWorkState({
                            isWorking: true,  // Set isWorking ke true
                            startTime: startTime,
                            endTime: endTime,
                            progress: calculateProgress(startTime, endTime)
                        });
                        setCurrentAbsentId(absentData.id);

                        // Cek untuk tampilkan modal end work
                        if (hasUnfinishedAbsent && !absentData.clock_out) {
                            const now = moment();
                            const workDuration = moment.duration(now.diff(startTime));
                            const hoursWorked = workDuration.asHours();

                            if (hoursWorked > 10 || !startTime.isSame(now, 'day')) {
                                setIsEndWorkModalVisible(true);
                                const defaultEndTime = moment(startTime).add(8, 'hours');
                                if (defaultEndTime.hours() > 17) {
                                    defaultEndTime.set({ hour: 17, minute: 0, second: 0 });
                                }
                                setSelectedTime(defaultEndTime);
                            }
                        }
                    } else {
                        // Reset state jika belum absen
                        setWorkState({
                            isWorking: false,
                            startTime: null,
                            endTime: null,
                            progress: 0
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    toast.error('Gagal mengecek status absensi');
                });
        }
    }, [dataUser]);

    // Tambahkan helper function untuk menghitung progress
    const calculateProgress = (startTime, endTime) => {
        const now = moment();
        const duration = moment.duration(endTime.diff(now));
        const hoursLeft = duration.asHours();
        return Math.min(((8 - hoursLeft) / 8) * 100, 100);
    };

    // Fungsi untuk mengecek lokasi
    const checkLocation = () => {
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject('Geolocation tidak didukung di browser ini');
                return;
            }

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const distance = calculateDistance(
                        position.coords.latitude,
                        position.coords.longitude,
                        OFFICE_LOCATION.latitude,
                        OFFICE_LOCATION.longitude
                    );

                    if (distance <= OFFICE_LOCATION.radius) {
                        resolve(true);
                    } else {
                        reject('Anda harus berada di area kantor untuk absen');
                    }
                },
                (error) => {
                    reject('Tidak dapat mengakses lokasi');
                }
            );
        });
    };

    // Handle mulai kerja
    const handleStartWork = async () => {
        if (isSubmitting) return; // Prevent double submit
        
        setIsSubmitting(true);
        try {
            // Cek lokasi
            await checkLocation();

            // Cek status absensi hari ini
            const checkResponse = await fetchData(`/check-today?employee_id=${dataUser?.id}`);
            const { isAbsent } = checkResponse.data.data;

            if (isAbsent) {
                toast.error('Anda sudah melakukan absensi hari ini!');
                return;
            }

            // Proses absensi
            const startTime = moment();
            const endTime = moment().add(8, 'hours');

            const response = await postData('/absents', {
                clock_in: startTime.format('HH:mm:ss'),
                date: startTime.format('YYYY-MM-DD'),
                status: 'Present',
                employee_id: dataUser?.id,
                branch_id: dataUser?.branch_id,
            }).then((response) => {
                setCurrentAbsentId(response.data.data.id);
                toast.success('Absen berhasil! Semangat bekerja!');
                setWorkState({
                    isWorking: true,
                    startTime: startTime,
                    endTime: endTime,
                    progress: 0
                });
            });

        } catch (error) {
            toast.error(error.toString());
        } finally {
            setIsSubmitting(false);
        }
    };

    // Update progress setiap menit
    useEffect(() => {
        let interval;
        if (workState.isWorking) {
            interval = setInterval(() => {
                const now = moment();
                const duration = moment.duration(workState.endTime.diff(now));
                const hoursLeft = duration.asHours();
                const progress = ((8 - hoursLeft) / 8) * 100;

                setWorkState(prev => ({
                    ...prev,
                    progress: Math.min(progress, 100)
                }));

                // Auto clock out
                if (progress >= 100) {
                    clearInterval(interval);
                    // Handle clock out logic
                }
            }, 60000); // Update setiap menit
        }
        return () => clearInterval(interval);
    }, [workState.isWorking, workState.endTime]);

    const handleEndWork = async (clockOutTime) => {
        if (isSubmitting) return; // Prevent double submit
        
        setIsSubmitting(true);
        try {
            const response = await postData('/end-work', {
                clock_out: clockOutTime.format('HH:mm:ss'),
                absent_id: currentAbsentId
            });

            if (response.status === 200) {
                toast.success('Selamat beristirahat! 🌙');
                setWorkState({
                    isWorking: false,
                    startTime: null,
                    endTime: null,
                    progress: 0
                });
                setIsEndWorkModalVisible(false);
            }
        } catch (error) {
            toast.error('Gagal melakukan end work');
        } finally {
            setIsSubmitting(false);
        }
    };

    const showEndWorkModal = () => {
        const startTime = moment(workState.startTime);
        const now = moment();
        const workDuration = moment.duration(now.diff(startTime));
        const hoursWorked = workDuration.asHours();

        // Jika sudah lebih dari 8 jam atau sudah beda hari
        if (hoursWorked > 8 || !startTime.isSame(now, 'day')) {
            setIsEndWorkModalVisible(true);

            // Set default time ke 8 jam setelah start time atau jam 17:00
            const defaultEndTime = moment(startTime).add(8, 'hours');
            if (defaultEndTime.hours() > 17) {
                defaultEndTime.set({ hour: 17, minute: 0, second: 0 });
            }

            setSelectedTime(defaultEndTime);
        } else {
            // Jika masih di hari yang sama dan belum 8 jam
            handleEndWork(now);
        }
    };

    // Render tampilan sebelum mulai kerja
    if (!workState.isWorking) {
        return (
            <Card className="text-center p-8 rounded-xl shadow-lg bg-gradient-to-br from-blue-50 to-purple-50">
                <Title level={2} className="font-sans text-3xl text-indigo-600">
                    ✨ Hai, {userData?.name}! ✨
                </Title>

                <div className="text-5xl font-mono font-bold my-6 text-gray-700">
                    {moment().format('HH:mm:ss')}
                </div>

                <Text className="block mb-4 text-lg text-gray-600 font-sans">
                    {moment().format('dddd, DD MMMM YYYY')}
                </Text>

                <div className="mb-6 text-sm text-gray-500 italic">
                    "{getRandomQuote()}"
                </div>

                <Button
                    size="large"
                    onClick={handleStartWork}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    className={`hover:scale-105 transition-transform duration-200 
                             bg-gradient-to-r from-blue-400 to-purple-500 
                             border-none rounded-full px-8 py-6 h-auto
                             text-white font-sans text-lg shadow-lg
                             hover:from-blue-500 hover:to-purple-600
                             ${isSubmitting ? 'opacity-75 cursor-not-allowed' : ''}`}
                >
                    <div className="flex items-center gap-2">
                        <span>🌟</span>
                        <span>{isSubmitting ? 'Memproses...' : 'Present'}</span>
                        <span>🌟</span>
                    </div>
                </Button>
            </Card>
        );
    }

    // Render tampilan sedang bekerja
    return (
        <Card className="text-center p-8 rounded-xl shadow-lg bg-gradient-to-br from-green-50 to-blue-50">
            <Title level={2} className="font-sans text-3xl text-green-600">
                🎯 Semangat Bekerja! 🎯
            </Title>

            <div className="flex justify-center gap-8 my-6">
                <div className="text-center">
                    <Text className="block text-gray-500">Mulai</Text>
                    <Text className="text-xl font-mono font-bold text-gray-700">
                        {workState.startTime.format('HH:mm')}
                    </Text>
                </div>
            </div>

            <Progress
                type="circle"
                percent={Math.round(workState.progress)}
                format={percent => (
                    <div className="text-lg">
                        <div>{Math.round((8 * percent) / 100 * 10) / 10}</div>
                        <div className="text-sm text-gray-500">jam</div>
                    </div>
                )}
                strokeColor={{
                    '0%': '#4F46E5',
                    '100%': '#06B6D4',
                }}
            />

            <div className="mt-6 text-sm text-gray-600 italic font-sans">
                "Setiap langkah kecil membawamu lebih dekat ke tujuan besarmu! 🌈"
            </div>

            <Button
                danger
                size="large"
                onClick={showEndWorkModal}
                disabled={isSubmitting}
                loading={isSubmitting}
                className={`mt-6 hover:scale-105 transition-transform duration-200
                         ${isSubmitting ? 'opacity-75 cursor-not-allowed' : ''}`}
            >
                {isSubmitting ? 'Memproses...' : 'End Work'}
            </Button>

            <Modal
                title="Pilih Waktu Selesai Kerja"
                open={isEndWorkModalVisible}
                onOk={() => handleEndWork(selectedTime)}
                onCancel={() => !isSubmitting && setIsEndWorkModalVisible(false)}
                okButtonProps={{ 
                    loading: isSubmitting,
                    disabled: isSubmitting 
                }}
                cancelButtonProps={{ 
                    disabled: isSubmitting 
                }}
                closable={!isSubmitting}
                maskClosable={!isSubmitting}
            >
                <div className="space-y-4">
                    <div className="text-yellow-600">
                        <p>⚠️ Perhatian:</p>
                        {moment().diff(workState.startTime, 'days') > 0 ? (
                            <p>Anda belum melakukan end work dari hari sebelumnya.</p>
                        ) : (
                            <p>Waktu kerja Anda sudah melebihi 10 jam.</p>
                        )}
                    </div>

                    <p>Silakan pilih jam selesai kerja Anda:</p>

                    <TimePicker
                        value={selectedTime}
                        onChange={setSelectedTime}
                        format="HH:mm:ss"
                        className="w-full"
                        disabledTime={() => ({
                            disabledHours: () => {
                                const startHour = moment(workState.startTime).hour();
                                return Array.from({ length: startHour }, (_, i) => i);
                            }
                        })}
                        minuteStep={5}
                    />

                    <div className="text-gray-500 text-sm mt-2">
                        * Waktu yang dipilih tidak boleh kurang dari jam masuk: {' '}
                        {moment(workState.startTime).format('HH:mm:ss')}
                    </div>
                </div>
            </Modal>
        </Card>
    );
};

// Tambahkan fungsi untuk quotes random
const getRandomQuote = () => {
    const quotes = [
        "Hari ini adalah hari yang luar biasa! ✨",
        "Siap untuk membuat hari ini jadi istimewa? 🌟",
        "Saatnya mewarnai hari dengan senyuman! 😊",
        "Mari mulai hari dengan senyuman! 😊",
        "Buat hari ini jadi masterpiece-mu! 🎯",
    ];
    return quotes[Math.floor(Math.random() * quotes.length)];
};

export default EmployeeAbsent;