import React from 'react';
import { Form, Input, Button, Select, DatePicker } from 'antd';
import { fetchData } from '../../helper/api';
import dayjs from 'dayjs';
import { formatCurrency } from '../../helper/formatCurrency';
import { DeliveryTypeOption, SELECT_DELIVERY_DETAIL_OPTION } from './helper';

const { Option } = Select;

function AddDeliveryDetail(props) {
    const { formik, index, selectDeliveryDetailOption, setSelectDeliveryDetailOption } = props


    const [dataOrderOption, setDataOrderOption] = React.useState([])

    React.useEffect(() => {
        // Fetch data order
        fetchData('/orders-options')
            .then((res) => {
                const data = res.data.data;
                data.map((item) => (
                    {
                        ...item,
                        value: item.id,
                        label: item.name
                    }))
                setDataOrderOption(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [])

    const handleDeleteData = () => {
        formik.setFieldValue(
            'delivery_details',
            formik.values.delivery_details.filter((item, i) => i !== index)
        );
        setSelectDeliveryDetailOption(SELECT_DELIVERY_DETAIL_OPTION.INDEX);
    };


    return (
        <div>
            <Form layout="vertical">
                <Form.Item label="Order">
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select a Order"
                        optionFilterProp="children"
                        value={formik.values.delivery_details[index]?.order_id}
                        onChange={(value, option) => {
                            formik.setFieldValue(`delivery_details[${index}].order_id`, value)
                            formik.setFieldValue(`delivery_details[${index}].order`, option.item)
                            formik.setFieldValue(`delivery_details[${index}].customer`, option.item?.customer)
                        }}
                    >
                        {dataOrderOption.map((item) => {
                            return (
                                <Option item={item} value={item.id}>{dayjs(item.entry_date).format("dddd, DD/MM/YYYY")} - {item?.customer?.name} - {item?.quantity} - {formatCurrency(parseInt(item?.payment?.total_price))}</Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label="Address">
                    <Input
                        name="address"
                        type='text'
                        value={formik.values.delivery_details[index]?.customer?.address}
                        onChange={(event) => formik.setFieldValue(`delivery_details[${index}].address`, event.target.value)}
                    />
                </Form.Item>
                <Form.Item label="Type">
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select a Type"
                        optionFilterProp="children"
                        value={formik.values.delivery_details[index]?.type}
                        onChange={(value, option) => {
                            formik.setFieldValue(`delivery_details[${index}].type`, value)
                        }}
                    >
                        {DeliveryTypeOption.map((item) => {
                            return (
                                <Option value={item.value}>{item.name}</Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label="Description">
                    <Input
                        name="description"
                        type='text'
                        value={formik.values.delivery_details[index]?.description}
                        onChange={(event) => formik.setFieldValue(`delivery_details[${index}].description`, event.target.value)}
                    />

                </Form.Item>

                <Form.Item label="Distance">
                    <Input
                        name="distance"
                        type='number'
                        value={formik.values.delivery_details[index]?.distance}
                        onChange={(event) => {
                            formik.setFieldValue(`delivery_details[${index}].distance`, event.target.value)
                            formik.setFieldValue(`delivery_details[${index}].cost`, parseInt(event.target.value * 500))
                        }
                        }
                    />

                </Form.Item>

                <Form.Item label="Cost">
                    <Input
                        disabled
                        name="cost"
                        type='text'
                        value={formatCurrency(parseInt(formik.values.delivery_details[index]?.distance * 500 || 0))}
                    // onChange={(event) => {
                    //     const value = event.target.value;
                    //     // Mengabaikan karakter selain digit (contoh: comma atau titik)
                    //     const numericValue = parseFloat(value.replace(/[^\d]/g, ''));

                    //     // Menangani nilai awal NaN
                    //     const newValue = isNaN(numericValue) ? 0 : numericValue;
                    //     formik.setFieldValue(`delivery_details[${index}].cost`, newValue)
                    // }}
                    />

                </Form.Item>

                <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className='flex gap-2'>
                        <Button onClick={() => handleDeleteData()} style={{ marginRight: '10px' }}>
                            Cancel
                        </Button>
                        <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => setSelectDeliveryDetailOption(SELECT_DELIVERY_DETAIL_OPTION.INDEX)}>
                            Save
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
}

export default AddDeliveryDetail;