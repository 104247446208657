import React from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { postData, putData } from '../../helper/api';
import { toast } from 'react-toastify';
import { useTransaction } from '../Transaction/contexts/TransactionContext';

// Skema validasi Yup untuk penambahan pelanggan baru
const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string()
        .required('Nomor telepon wajib diisi')
        .test('no-dash', 'Nomor telepon tidak boleh mengandung karakter "-"', (value) => !value.includes('-'))
        .test('no-spaces', 'Nomor telepon tidak boleh mengandung spasi', (value) => !/\s/.test(value))
        .test('no-plus', 'Nomor telepon tidak boleh mengandung tanda "+"', (value) => !value.includes('+'))
        .test('starts-with-628', 'Nomor telepon harus dimulai dengan "628"', (value) => value.startsWith('628'))
        .matches(/^[0-9]+$/, 'Nomor telepon hanya boleh berisi angka')  // Hanya boleh angka
    // password: Yup.string().required('Password is required'),
    // city: Yup.string().required('City is required'),
    // Add validation for other fields as needed
});

const ModalAddCustomer = (props) => {
    const { open, setOpen, initialData, formikFromCashier } = props;
    const { loadCustomerData } = useTransaction();

    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            // password: '',
            address: '',
            gmaps: '',
            description: '',
            // city: '',
            // Add other fields as needed
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                if (initialData) {
                    if (formikFromCashier) {
                        formikFromCashier.setFieldValue('customer_data', {
                            id: initialData.id,
                            name: values.name,
                            phone: values.phone,
                            email: '',
                            address: values.address,
                            description: values.description,
                        })
                    }
                    const response = await putData(`/customers/${initialData.id}`, values);
                    if (response.status === 200) {
                        formik.resetForm();
                        toast.success('Customer updated successfully');
                        setOpen(false);
                    }
                    return;
                } else {
                    const response = await postData('/customers', values);
                    if (response.status === 201) {
                        if (formikFromCashier) {
                            formikFromCashier.setFieldValue('customer_id', response.data.data.id)
                            formikFromCashier.setFieldValue('customer_data', response.data.data)
                        }
                        loadCustomerData();
                        formik.resetForm();
                        toast.success('Customer added successfully');
                        setOpen(false);
                    }
                    return;
                }
            } catch (error) {
                console.log(error);
                formik.setSubmitting(false);
                toast.error('Cek no customer atau no telepon sudah terdaftar');
            }
        },
    });

    React.useEffect(() => {
        if (initialData) {
            formik.setFieldValue('id', initialData?.id);
            formik.setFieldValue('name', initialData?.name);
            formik.setFieldValue('phone', initialData?.phone);
            // formik.setFieldValue('password', initialData.password);
            formik.setFieldValue('address', initialData?.address);
            formik.setFieldValue('gmaps', initialData.gmaps);
            formik.setFieldValue('description', initialData?.description);
            // formik.setFieldValue('city', initialData.city);

        } else {
            formik.resetForm();
        }


    }, [initialData, open]);

    return (
        <div>
            <Modal
                title={initialData ? 'Edit Customer' : 'Add Customer'}
                open={open}
                onOk={() => { }} // Handle submit function
                onCancel={() => setOpen(false)}
                footer={null}
            >
                <Form onSubmit={formik.handleSubmit}>
                    <Form.Item
                        name="name"
                        validateStatus={formik.errors.name && 'error'}
                        help={formik.errors.name}
                    >
                        <label>Name : </label>
                        <Input
                            placeholder="Enter name"
                            name="name"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                    </Form.Item>

                    <Form.Item
                        name="phone"
                        validateStatus={formik.errors.phone && 'error'}
                        help={formik.errors.phone}
                        touched={formik.touched.phone}
                    >
                        <label>Phone : </label>
                        <Input
                            placeholder="Enter phone"
                            name="phone"
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                        />
                    </Form.Item>

                    {/* <Form.Item
                        label="Password"
                        name="password"
                        validateStatus={formik.errors.password && 'error'}
                        help={formik.errors.password}
                    >
                        <Input.Password
                            placeholder="Enter password"
                            name="password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                        />
                    </Form.Item> */}

                    <Form.Item
                        name="address"
                        validateStatus={formik.errors.address && 'error'}
                        help={formik.errors.address}
                    >
                        <label>Address : </label>
                        <Input
                            placeholder="Enter address"
                            name="address"
                            onChange={formik.handleChange}
                            value={formik.values.address}
                        />
                    </Form.Item>
                    <Form.Item
                        name="gmaps"
                        validateStatus={formik.errors.gmaps && 'error'}
                        help={formik.errors.gmaps}
                    >
                        <label>GMaps : </label>
                        <Input
                            placeholder="Enter Gmaps"
                            name="gmaps"
                            onChange={formik.handleChange}
                            value={formik.values.gmaps}
                        />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        validateStatus={formik.errors.description && 'error'}
                        help={formik.errors.description}
                    >
                        <label>Description : </label>
                        <Input
                            placeholder="Enter description"
                            name="description"
                            onChange={formik.handleChange}
                            value={formik.values.description}
                        />
                    </Form.Item>


                    {/* <Form.Item
                        label="City"
                        name="city"
                        validateStatus={formik.errors.city && 'error'}
                        help={formik.errors.city}
                    >
                        <Input
                            placeholder="Enter city"
                            name="city"
                            onChange={formik.handleChange}
                            value={formik.values.city}
                        />
                    </Form.Item> */}

                    <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            loading={formik.isSubmitting}
                            className="items-center flex justify-center text-white bg-blue-400" htmlType="submit" onClick={formik.handleSubmit} style={{ marginRight: '10px' }} >
                            {initialData ? "Edit Customer" : "Add Customer"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ModalAddCustomer;
