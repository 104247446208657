import React from 'react';
import { Modal, Form, Select, Button, TimePicker, DatePicker, Input } from 'antd';
import { putData } from '../../helper/api';
import { toast } from 'react-toastify';
import moment from 'moment';

const { Option } = Select;

const ModalEditAbsent = ({ open, setOpen, initialData, trigerFetchData, setTrigerFetchData }) => {
    const [form] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    React.useEffect(() => {
        if (initialData) {
            form.setFieldsValue({
                ...initialData,
                date: initialData.date ? moment(initialData.date) : null,
                clock_in: initialData.clock_in ? moment(initialData.clock_in, 'HH:mm:ss') : null,
                clock_out: initialData.clock_out ? moment(initialData.clock_out, 'HH:mm:ss') : null,
                description: initialData.description || null,
            });
        }
    }, [initialData, form]);

    const handleSubmit = () => {
        if (isSubmitting) return;

        setIsSubmitting(true);
        form.validateFields()
            .then((values) => {
                const updatedData = {
                    ...values,
                    date: values.date ? moment(values.date).format('YYYY-MM-DD') : null,
                    clock_in: values.clock_in ? moment(values.clock_in).format('HH:mm:ss') : null,
                    clock_out: values.clock_out ? moment(values.clock_out).format('HH:mm:ss') : null,
                    description: values.description || null,
                };

                putData(`/absents/${initialData.id}`, updatedData)
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success('Absen berhasil diperbarui');
                            setOpen(false);
                            form.resetFields();
                            setTrigerFetchData(!trigerFetchData);
                        }
                    })
                    .catch((error) => {
                        toast.error(error.response?.data?.message || 'Gagal memperbarui absen');
                    })
                    .finally(() => {
                        setIsSubmitting(false);
                    });
            })
            .catch(() => {
                setIsSubmitting(false);
                toast.error('Mohon periksa kembali form anda');
            });
    };

    return (
        <Modal
            title="Edit Absent"
            open={open}
            onCancel={() => {
                if (!isSubmitting) {
                    setOpen(false);
                    form.resetFields();
                }
            }}
            footer={null}
            closable={!isSubmitting}
            maskClosable={!isSubmitting}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    label="Date"
                    name="date"
                    rules={[{ required: true, message: 'Date is required' }]}
                >
                    <DatePicker
                        style={{ width: '100%' }}
                        format="YYYY-MM-DD"
                        placeholder="Choose Date"
                        disabledDate={(current) => {
                            // Tidak bisa memilih tanggal lebih dari hari ini
                            return current && current > moment().endOf('day');
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label="Status"
                    name="status"
                    rules={[{ required: true, message: 'Status is required' }]}
                >
                    <Select placeholder="Choose Status">
                        <Option value="Present">Present (Hadir)</Option>
                        <Option value="Absent">Absent (Tanpa Keterangan)</Option>
                        <Option value="DayOffPersonal">Ambil Libur Mingguan</Option>
                        <Option value="DayOffGeneral">Cuti Bersama</Option>
                        <Option value="Sick">Sick Leave (Sakit)</Option>
                        <Option value="PersonalLeave">Personal Leave (Izin Pribadi)</Option>
                        <Option value="EmergencyLeave">Emergency Leave (Izin Darurat)</Option>
                        <Option value="AnnualLeave">Annual Leave (Cuti Tahunan)</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Clock In"
                    name="clock_in"
                    rules={[{ required: true, message: 'Clock In is required' }]}
                >
                    <TimePicker
                        format="HH:mm:ss"
                        style={{ width: '100%' }}
                        placeholder="Choose Clock In"
                    />
                </Form.Item>

                <Form.Item
                    label="Clock Out"
                    name="clock_out"
                >
                    <TimePicker
                        format="HH:mm:ss"
                        style={{ width: '100%' }}
                        placeholder="Choose Clock Out"
                    />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                >
                    <Input
                        placeholder="Enter Description"
                        onChange={(e) => form.setFieldValue('description', e.target.value)}
                    />
                </Form.Item>

                <Form.Item style={{ marginBottom: 0, textAlign: 'right' }}>
                    <Button
                        type="default"
                        style={{ marginRight: 8 }}
                        onClick={() => {
                            if (!isSubmitting) {
                                setOpen(false);
                                form.resetFields();
                            }
                        }}
                        disabled={isSubmitting}
                    >
                        Batal
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleSubmit}
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        className="bg-blue-500"
                    >
                        {isSubmitting ? 'Saving...' : 'Update'}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalEditAbsent; 