import { useEffect, useRef, useState } from 'react';
import { Table, Button, Tag, Space, Modal } from 'antd';
import { useFollowUps } from '../hooks/useFollowUps';
import FollowUpForm from './FollowUpForm';
import { statusColors } from '../constants';
import { useQuery } from '../../../helper/useQuery';

const FollowUpList = () => {
  const query = useQuery();
  const prevTab = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const { followUps, loading, createFollowUp, refreshFollowUps } = useFollowUps();

  useEffect(() => {
    const activeTab = query.get('tab') || '2';
    if(activeTab !== prevTab.current) {
      prevTab.current = activeTab;
      if(activeTab === '2') {
        refreshFollowUps();
      }
    }
  }, [query]);

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Telepon',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Status Terakhir',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={statusColors[status]}>{status}</Tag>
      ),
    },
    {
      title: 'Follow Up Count',
      dataIndex: 'follow_up_count',
      key: 'follow_up_count',
      render: (count) => (
        <Tag color={count >= 7 ? 'red' : 'green'}>{count}/7</Tag>
      ),
    },
    {
      title: 'Next Follow Up',
      dataIndex: 'next_follow_up_date',
      key: 'next_follow_up_date',
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_, record) => (
        <Button 
          type="primary"
          onClick={() => handleFollowUp(record)}
          disabled={record.follow_up_count >= 7}
        >
          Follow Up
        </Button>
      ),
    },
  ];

  const handleFollowUp = (lead) => {
    setSelectedLead(lead);
    setIsModalVisible(true);
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={followUps}
        loading={loading}
        rowKey="id"
      />

      <FollowUpForm
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setSelectedLead(null);
        }}
        onSubmit={async (values) => {
          await createFollowUp(selectedLead.id, values);
          setIsModalVisible(false);
          setSelectedLead(null);
        }}
        lead={selectedLead}
      />
    </div>
  );
};

export default FollowUpList; 