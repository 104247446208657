import { useEffect } from "react";
import { setAuthToken } from "../helper/api";

const LogOut = () => {
  useEffect(() => {
    setAuthToken(null);
    window.location.href = '/sign-in';
  }, []);

  return null;
};

export default LogOut;