import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, Button } from 'antd';
import { fetchData, postData, putData } from '../../helper/api';
import { toast } from 'react-toastify';
import { ConvertStringToArray } from '../../helper/convertStringToArray';
import { useFormik } from 'formik';
import { useTransaction } from '../Transaction/contexts/TransactionContext';

const { Option } = Select;

const ModalAddItem = ({ open, setOpen, initialData, listData, trigerFetchData, setTrigerFetchData }) => {

    const [BrandOption, setBrandOption] = React.useState([]);
    const [ColorOption, setColorOption] = React.useState([]);
    const { loadItemData } = useTransaction();
    const [TypeOption, setTypeOption] = React.useState([]);
    const [MaterialOption, setMaterialOption] = React.useState([]);

    const formik = useFormik({
        initialValues: {
            name: '',
            brand: [],
            color: [],
            // type: [],
            // material: [],
        },
        validate: values => {
            const errors = {};
            
            // Validasi nama
            if (!values.name) {
                errors.name = 'Nama item harus diisi';
            } else if (values.name.length < 3) {
                errors.name = 'Nama item minimal 3 karakter';
            }

            // Validasi brand
            if (!values.brand || values.brand.length === 0) {
                errors.brand = 'Brand harus dipilih';
            }

            // Validasi warna
            if (!values.color || values.color.length === 0) {
                errors.color = 'Warna harus dipilih';
            }

            return errors;
        },
        onSubmit: values => {
            const colorAsString = Array.isArray(values.color) ? values.color.join(', ') : values.color;
            const brandAsString = Array.isArray(values.brand) ? values.brand.join(', ') : values.brand;
            // const typeAsString = Array.isArray(values.type) ? values.type.join(', ') : values.type;
            // const materialAsString = Array.isArray(values.material) ? values.material.join(', ') : values.material;

            const newData = {
                name: values.name,
                brand: brandAsString,
                color: colorAsString,
                // type: typeAsString,
                // material: materialAsString,
            }
            if (initialData) {
                putData(`/items/${initialData.id}`, newData)
                    .then((response) => {
                        toast.success('Item updated successfully');
                        setOpen(false);
                        formik.resetForm();
                        loadItemData();
                        setTrigerFetchData(!trigerFetchData);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                postData('/items', newData)
                    .then((response) => {
                        toast.success('Item added successfully');
                        setOpen(false);
                        formik.resetForm();
                        loadItemData();
                        setTrigerFetchData(!trigerFetchData);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    })



    useEffect(() => {
        const uniqueOptions = (listData, key) => {
            const uniqueSet = new Set(listData?.map(item => item[key])); // Menggunakan Set untuk nilai unik
            return Array.from(uniqueSet).map(value => ({ value }));
        };

        const brandOption = uniqueOptions(listData, 'brand');
        setBrandOption(brandOption);

        const colorOption = uniqueOptions(listData, 'color');
        setColorOption(colorOption);

        // const typeOption = uniqueOptions(listData, 'type');
        // setTypeOption(typeOption);

        // const materialOption = uniqueOptions(listData, 'material');
        // setMaterialOption(materialOption);



        if (initialData) {
            formik.setValues(
                {
                    name: initialData?.name,
                    brand: ConvertStringToArray(initialData?.brand),
                    color: ConvertStringToArray(initialData?.color),
                    // type: ConvertStringToArray(initialData?.type),
                    // material: ConvertStringToArray(initialData?.material),
                }
            );
        } 
    }, [initialData, listData]);

    return (
        <Modal
            title="Add Item"
            open={open}
            onCancel={() => {
                setOpen(false);
                formik.resetForm();
            }}
            onOk={() => setOpen(false)}
            footer={null}
        >
            <Form layout="vertical">
                <Form.Item
                    label="Name"
                    validateStatus={formik.errors.name && formik.touched.name ? 'error' : ''}
                    help={formik.touched.name && formik.errors.name}
                >
                    <Input
                        placeholder="Enter item name"
                        value={formik.values?.name || ''}
                        onChange={(e) => {
                            formik.setFieldValue('name', e.target.value);
                        }}
                        onBlur={formik.handleBlur('name')}
                    />
                </Form.Item>


                <Form.Item
                    label="Brand"
                    validateStatus={formik.errors.brand && formik.touched.brand ? 'error' : ''}
                    help={formik.touched.brand && formik.errors.brand}
                >
                    <Select
                        mode='tags'
                        maxTagCount={1}
                        placeholder="Select brand"
                        value={formik.values?.brand}
                        onChange={(value) => {
                            formik.setFieldValue('brand', value);
                        }}
                        onBlur={() => formik.setFieldTouched('brand', true)}
                    >
                        {BrandOption?.map((item, index) => (
                            <Option key={index} value={item.value}>
                                {item.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Color"
                    validateStatus={formik.errors.color && formik.touched.color ? 'error' : ''}
                    help={formik.touched.color && formik.errors.color}
                >
                    <Select
                        mode='tags'
                        maxTagCount={3}
                        placeholder="Select color"
                        value={formik.values?.color}
                        onChange={(value) => {
                            formik.setFieldValue('color', value);
                        }}
                        onBlur={() => formik.setFieldTouched('color', true)}
                    >
                        {ColorOption?.map((item, index) => (
                            <Option key={index} value={item.value}>
                                {item.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                {/* <Form.Item
                    label="Type"
                >
                    <Select
                        mode='tags'
                        maxTagCount={1}
                        placeholder="Select type"
                        value={formik.values?.type}
                        onChange={(value) => {
                            formik.setFieldValue('type', value);
                        }}
                    >
                        {TypeOption?.map((item, index) => (
                            <Option key={index} value={item.value}>
                                {item.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Material"
                >
                    <Select
                        mode='tags'
                        maxTagCount={1}
                        placeholder="Select material"
                        value={formik.values?.material}
                        onChange={(value) => {
                            formik.setFieldValue('material', value);
                        }}
                    >
                        {MaterialOption?.map((item, index) => (
                            <Option key={index} value={item.value}>
                                {item.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item> */}

                <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className='flex gap-2'>
                        <Button
                            type="default"
                            style={{ marginRight: '10px' }}
                            onClick={() => {
                                setOpen(false);
                                formik.resetForm();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button 
                            loading={formik.isSubmitting}
                            disabled={!formik.isValid || formik.isSubmitting}
                            className={`items-center flex justify-center text-white ${!formik.isValid ? 'bg-gray-400' : 'bg-blue-400'}`}
                            htmlType="submit" 
                            onClick={formik.handleSubmit}
                        >
                            {initialData ? 'Update' : 'Add'}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal >
    );
};

export default ModalAddItem;
