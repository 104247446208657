import React, { useState } from 'react'
import { Card, Image } from 'antd'
import { formatNumber } from '../../helper/formatCurrency'
import dayjs from 'dayjs'
import { WashingStatusDisplay } from '../../models/orderData'
import { DisplayProcessTime, getStatusBackgroundColor, getStatusBadgeStyle, getStatusRadioStyle } from './helper'
import { Radio, Modal, Button } from 'antd'
import { fetchData, putData } from '../../helper/api'
import { toast } from 'react-toastify'
import {
    FaBox,
    FaClock,
    FaStickyNote,
    FaListAlt,
    FaSpinner,
    FaSun,
    FaCheckCircle,
    FaFlag,
    FaBan
} from 'react-icons/fa';


export default function CardItemService({ record }) {

    const [dataRecord, setDataRecord] = React.useState(record)

    const [dateNow, setDateNow] = React.useState(dayjs().format('YYYY-MM-DD'))
    const imgIllustration = "https://static.vecteezy.com/system/resources/previews/003/528/131/original/in-progress-man-run-to-their-goal-illustration-flat-vector.jpg"

    const [open, setOpen] = useState(false);
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = (e, id) => {

        const newDataRecord = { ...dataRecord }
        const orderDetails = newDataRecord.order_details.map(item => {
            if (item.id === id) {
                return { ...item, status: e.target.value }
            }
            return item
        })
        newDataRecord.order_details = orderDetails
        setDataRecord(newDataRecord)

        try {
            putData(`/order-details/${id}`, {
                status: e.target.value
            }).then(response => {
                toast.success('Berhasil mengubah status order')
            })
        } catch (error) {
            console.log('ERROR', error);
        }
        setOpen(false);
    };
    const handleCancel = () => {
        setOpen(false);
    };


    React.useEffect(() => {
        setDataRecord(record)
    }, [record])

    const handleStatusChange = (e, id) => {

        Modal.confirm({
            title: `Apakah anda yakin ingin mengubah status order menjadi "${WashingStatusDisplay(e.target.value)}"?`,
            footer: null,
            okText: 'Ya',
            cancelText: 'Tidak',
            onOk: () => handleOk(e, id),
            onCancel: handleCancel
        });


    }

    return (
        <>
            <Modal
                open={open}
                title="Title"
                footer={null}
            >
            </Modal>
            <div style={{ display: "flex", flexDirection: "column", gap: '5px', padding: '5px', borderRadius: '15px', backgroundColor: `${dataRecord?.payment?.status === 'paid' ? '#90ee90c4' : '#fff200'}` }}>
                {dataRecord?.order_details?.map((item, index) => {
                    return (
                        <Card
                            className={`overflow-hidden min-w-[700px] ${getStatusBackgroundColor(item.status, item.due_date <= dateNow)}`}
                            bodyStyle={{ padding: '12px' }}
                        >
                            <div className="flex gap-3">
                                {/* Image */}
                                <Image
                                    src={item?.shoes_photos[0]?.url_photo || "https://th.bing.com/th/id/OIP.cY6hooCAv2-CFp6BL6Tp3gHaHa?rs=1&pid=ImgDetMain"}
                                    className="rounded-lg"
                                    width={80}
                                    height={50}
                                    style={{ objectFit: 'cover', objectPosition: 'center center' }}
                                    preview={true}
                                />

                                {/* Content */}
                                <div className="flex-grow">
                                    {/* Service Title & Status Badge */}
                                    <div className="flex items-start justify-between mb-1">
                                        <div className="flex items-center gap-2">
                                            <span className="font-bold text-gray-800">
                                                {item.treatment.name} - {DisplayProcessTime(item.process_time)}
                                            </span>
                                            <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeStyle(item.status)}`}>
                                                {WashingStatusDisplay(item.status)}
                                            </span>
                                        </div>
                                        <div className="text-right">
                                            <span className="text-base font-bold">
                                                {formatNumber(parseInt(item.final_price))}
                                            </span>
                                        </div>
                                    </div>

                                    {/* Item Details */}
                                    <div className="grid grid-cols-2 gap-4  w-fulltext-sm">
                                        <div className="flex items-center gap-1 text-gray-600">
                                            <FaBox className="text-xs" />
                                            <span>{item?.item?.name} - {item?.item?.color}</span>
                                        </div>
                                        <div className={`flex items-center gap-1 ${item.due_date <= dateNow ? 'text-red-600 font-medium' : 'text-gray-600'
                                            }`}>
                                            <FaClock className="text-xs" />
                                            <span>{dayjs(item.due_date).format('DD MMM YYYY')}</span>
                                        </div>
                                    </div>

                                    {/* Notes Section */}
                                    {item.description && (
                                        <div className="mt-2 bg-yellow-50 rounded-md p-2 border border-yellow-200">
                                            <div className="flex items-start gap-2">
                                                <div className="mt-0.5">
                                                    <FaStickyNote className="text-yellow-500" />
                                                </div>
                                                <div className="flex-1">
                                                    <div className="text-xs font-medium text-yellow-700 mb-0.5">
                                                        Catatan Khusus:
                                                    </div>
                                                    <div className="text-sm text-yellow-800 break-words">
                                                        {item.description || '-'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Status Radio Group */}
                            <div className="mt-3 pt-2 border-t border-gray-200">
                                <Radio.Group
                                    onChange={(e) => handleStatusChange(e, item.id)}
                                    value={item.status}
                                    size="small"
                                    className="flex flex-wrap gap-1"
                                >
                                    {[
                                        { value: 'not_yet', label: 'Antrian', icon: FaListAlt },
                                        { value: 'on_progress', label: 'Proses', icon: FaSpinner },
                                        { value: 'pengeringan', label: 'Pengeringan', icon: FaSun },
                                        { value: 'siap', label: 'Siap', icon: FaCheckCircle },
                                        { value: 'done', label: 'Selesai', icon: FaFlag },
                                        { value: 'cancelled', label: 'Batal', icon: FaBan }
                                    ].map((status) => (
                                        <Radio
                                            key={status.value}
                                            value={status.value}
                                            className={`
                        !m-0 rounded-md hover:opacity-80 transition-opacity
                        ${item.status === status.value ? getStatusRadioStyle(status.value) : ''}
                    `}
                                        >
                                            <div className="flex items-center gap-1 px-2 py-1 text-xs">
                                                <status.icon className="text-xs" />
                                                {status.label}
                                            </div>
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            </div>
                        </Card>
                    )
                })}
            </div>
        </>
    )

}
