import { Tabs } from 'antd';
import LeadsList from './components/LeadsList';
import LeadsReport from './components/LeadsReport';
import FollowUpList from './components/FollowUpList';
import { LeadsProvider } from './hooks/useLeads';
import { useQuery } from "../../helper/useQuery";
import { useHistory } from 'react-router-dom';

const Index = () => {
  const query = useQuery();
  const history = useHistory();

  const activeTab = query.get('tab') || '1';

  const handleTabChange = (key) => {
    history.push(`?tab=${key}`);
  };


  const items = [
    {
      key: '1',
      label: 'Daftar Leads',
      children: <LeadsList />,
    },
    {
      key: '2',
      label: 'Follow Up Hari Ini',
      children: <FollowUpList />,
    },
    {
      key: '3',
      label: 'Laporan',
      children: <LeadsReport />,
    },
  ];


 


  return (
    <LeadsProvider>
      <div className="p-6">
        <h1 className="text-2xl font-bold mb-6">Leads Management</h1>
        <Tabs 
          activeKey={activeTab} 
          onChange={handleTabChange} 
          items={items} 
        />
      </div>
    </LeadsProvider>
  );
};

export default Index; 