import { add, parse, sub } from "date-fns";
import { formatCurrency } from "../../../helper/formatCurrency";

// -1 itu models, jadi ketika dikirim itu -1 itu berarti tidak ada data yang dikirim
// 0 itu mau di create
// > 0 itu mau di update, atau pake data yang udah ada

export const OrderDataStateModel = {
    id: -1,
    payment_id: -1,
    payment_data: {
        id: -1,
        code: '',
        status: '',
        method: '',

        discount_per_order_id: -1,
        discount_per_order_data: {
            id: -1,
            name: '',
            percentage: 0,
            description: '',
        },

        tax_id: -1,
        tax_data: {
            id: -1,
            name: '',
            percentage: 0,
        },

        sub_total_price_per_item: 0, // harga normal AMBIL DARI TREATMENT ARRAY (0)
        sub_total_processing_time_cost: 0, // biaya process time
        sub_total_discount_per_item: 0, // nominal diskon per item
        sub_total_additional_cost: 0, // biaya tambahan per item
        sub_total_discount_all_order: 0, // nominal diskon per order
        sub_total_shipping_cost: 0, // biaya pengiriman
        sub_total_final_price: 0, // HARGA AKHIR SETELAH DIKURANGIN DISKON, ADDITIONAL COST DAN PROCESS TIME COST
        sub_total_final_price_with_tax: 0,

    

        description: '',
        date: null,
        money: 0,
        change: 0,
        evidence_photo: null,
    },
    customer_id: -1,
    customer_data: {
        id: -1,
        name: '',
        phone: '',
        email: '',
        address: '',
        description: '',
    },
    entry_date: new Date(),
    quantity: 0, // qty item yang di masukin
    order_type: '', // offline / online
    order_details: [
        // {
        //     id: -1,
        //     order_id: -1, // HARUS ADA TAPI DI BACKEND AJA
        //     employee_id: '', // PAKE ID USER YANG LOGIN
        //     employee_data: { // INI DI PAKE UNTUK NAMPILIN NAMA USER
        //         id: -1,
        //         name: '',
        //         phone: '',
        //         email: '',
        //         address: '',
        //         description: '',
        //     },
        //     treatment_id: '1', // HARUS ADA, AMBIL DATA KETIKA MEMILIH VARIAN * DUE DATE
        //     treatment_data: { // INI DI PAKE UNTUK NAMPILIN NAMA TREATMENT
        //         id: 1,
        //         name: 'Shoes - Deep Clean',
        //         price: 40000.00,
        //         type_item: 'Shoes',
        //         type_service: 'Deep Clean',
        //         description: 'Mencuci keseluruhan bagian sepatu',
        //         laundry_type: 'satuan',
        //         process_time_name: 'Regular',
        //         process_time_type_cost: 'percent',
        //         process_time_value_cost: 10,
        //         process_time_value_nominal_cost: 4000,
        //         final_price_with_process_time: 44000,
        //     },
        //     item_id: '', // GA WAJIB ADA DIAWAL, KARENA INI AGAK LAMA INPUTNYA JADI, NULL AWALNYA, NANTI KETIKA SUDAH DI CEK, BARU INPUT ITEM INI, // INI DIPAKE DI NOTA BESAR, NOTA KECIL HANYA TREATMENT AJA
        //     item_data: { // INI DI PAKE UNTUK NAMPILIN NAMA ITEM
        //         id: -1,
        //         name: '',
        //         color: '',
        //         price: 0,
        //         description: '',
        //     },
        //     discount_id: '', // GAK WAJIB ADA
        //     discount_data: { // INI DI PAKE UNTUK NAMPILIN NAMA DISCOUNT
        //         id: -1,
        //         name: '',
        //         percentage: 0,
        //         description: '',
        //     },
        //     process_time: '', // regular, next_day, same_day
        //     process_time_cost: 0,
        //     due_date: new Date(),
        //     price: 0, // harga normal AMBIL DARI TREATMENT ARRAY (0)
        //     discount: 0, // nominal diskon
        //     final_price: 0, // HARGA AKHIR SETELAH DIKURANGIN DISKON DAN PROCESS TIME COST
        //     status: '',
        //     description: '',
        //     shoes_photos: [
        //         {
        //             id: -1,
        //             order_detail_id: -1,
        //             url_photo: '',
        //         },
        //     ],
        // },
    ],
}

export const OrderDetailStateModels = {
    id: -1,
    order_id: -1, // HARUS ADA TAPI DI BACKEND AJA
    employee_id: '', // PAKE ID USER YANG LOGIN
    employee_data: { // INI DI PAKE UNTUK NAMPILIN NAMA USER
        id: -1,
        name: '',
        phone: '',
        email: '',
        address: '',
        description: '',
    },
    treatment_id: '', // HARUS ADA, AMBIL DATA KETIKA MEMILIH VARIAN * DUE DATE
    treatment_data: { // INI DI PAKE UNTUK NAMPILIN NAMA TREATMENT
        id: -1,
        name: '',
        price: 0,
        type_item: '',
        type_service: '',
        description: '',
        laundry_type: '',
        process_time_name: '',
        process_time_type_cost: '',
        process_time_value_cost: 0,
        process_time_value_nominal_cost: 0,
        discount_nominal_for_item: 0,
        final_price_with_process_time: 0,
        final_price_with_process_time_and_discount: 0,
    },
    item_id: '', // GA WAJIB ADA DIAWAL, KARENA INI AGAK LAMA INPUTNYA JADI, NULL AWALNYA, NANTI KETIKA SUDAH DI CEK, BARU INPUT ITEM INI, // INI DIPAKE DI NOTA BESAR, NOTA KECIL HANYA TREATMENT AJA
    item_data: { // INI DI PAKE UNTUK NAMPILIN NAMA ITEM
        id: -1,
        name: '',
        brand: '',
        color: '',
        material: '',
        type: ''
    },
    discount_id: '', // GAK WAJIB ADA
    discount_data: { // INI DI PAKE UNTUK NAMPILIN NAMA DISCOUNT
        id: -1,
        name: '',
        percentage: 0,
        description: '',
    },
    due_date: new Date(),
    status: '',
    description: '',
    shoes_photos: [],

}

export const ProcessTimeCalculation = (process_time_data, varian_treatment) => {
    const selected_varian_price = varian_treatment?.find(item => item?.selected === true)?.price
    if (process_time_data?.type_cost === 'percent') {
        return parseInt(selected_varian_price * (parseInt(process_time_data?.value_cost) / 100))
    } else {
        return parseInt(process_time_data?.value_cost)
    }
}
export const ProcessTimeCalculationDisplay = (process_time_data, varian_treatment) => {
    const selected_varian_price = varian_treatment?.find(item => item?.selected === true)?.price
    if (process_time_data?.type_cost === 'percent') {
        return formatCurrency(parseInt(selected_varian_price * (parseInt(process_time_data?.value_cost) / 100)))
    } else {
        return formatCurrency(parseInt(process_time_data?.value_cost))
    }
}

export const ProcessTimeCalculationDataLaundry = (varian_treatment) => {
    if (varian_treatment?.process_time_type_cost === 'percent') {
        return parseInt(varian_treatment?.price * (parseInt(varian_treatment?.process_time_value_cost) / 100))
    } else {
        return parseInt(varian_treatment?.process_time_value_cost)
    }
}

export const PriceDisplayVarianWithProcessTime = (varian_treatment) => {
    const selected_varian_price = parseInt(varian_treatment?.price)
    const process_time_cost = ProcessTimeCalculationDataLaundry(varian_treatment)
    return formatCurrency(selected_varian_price + process_time_cost)
}