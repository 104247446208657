import React, { useEffect, useState } from 'react';
import { Radio } from 'antd';
import Overview from './Overview';
import Income from './Income';
import ProfitOverview from './ProfitOverview';
import Finance from './Finance';
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from "../../helper/useQuery";
import moment from 'moment';
import { Button, Popover } from 'antd';
import { fetchData } from '../../helper/api';
import OperasionalReport from './OperasionalReport';
import AiDashboard from './aiDashboard';
import { useGeneral } from '../../contexts/GeneralContext';


function Index(props) {
    const location = useLocation();
    const history = useHistory();
    const query = useQuery();
    const startDate = query.get('startDate') || moment().startOf('month').format('YYYY-MM-DD');
    const endDate = query.get('endDate') || moment().endOf('month').format('YYYY-MM-DD');

    const [radioButton, setRadioButton] = React.useState('overview');
    const { getProfile } = useGeneral();
    const [dataUser, setDataUser] = useState(getProfile);

    const optionRadioButton = [
        {
            label: 'Overview',
            value: 'overview',
        },
        {
            label: 'AI Dashboard',
            value: 'ai_dashboard',
        },
        {
            label: 'Finance Report',
            value: 'finance_report',
        },
        {
            label: 'Operasional Report',
            value: 'operasional_report',
        },
        {
            label: 'Profit Overview',
            value: 'profit_overview',
        },
    ]

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);

    useEffect(() => {
        if (location.search) {
            const start = query.get('startDate');
            const end = query.get('endDate');
            setState([
                {
                    startDate: new Date(start),
                    endDate: new Date(end),
                    key: 'selection'
                }
            ]);
        }
    }, [location.search]);


    useEffect(() => {
        setDataUser(getProfile);
    }, [getProfile]);


    const handleFilterTanggal = (item) => {
        setState([item.selection]);
        const startDateParam = moment(item.selection.startDate).format('YYYY-MM-DD');
        const endDateParam = moment(item.selection.endDate).format('YYYY-MM-DD');

        history.push({
            pathname: location.pathname,
            search: `?startDate=${startDateParam}&endDate=${endDateParam}`
        });

    };

    const content = (
        <DateRangePicker
            onChange={handleFilterTanggal} // Menggunakan fungsi handleFilterTanggal yang telah dibuat
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            direction="horizontal"
        />
    );

    return (
        <div>
            <div className='flex justify-between max-xl:flex-col mb-4'>
                <Radio.Group
                    className="mb-4"
                    options={dataUser?.role === 'superadmin' ? optionRadioButton : optionRadioButton.filter((item) => item.value !== 'profit_overview' && item.value !== 'finance_report')}
                    onChange={(e) => {
                        setRadioButton(e.target.value);
                    }}
                    value={radioButton}
                    optionType="button"
                    buttonStyle="solid"
                />
                {radioButton !== 'finance_report' && (
                    <Popover content={content} placement="leftTop" title="Filter Tanggal" trigger="click">
                        <Button>
                            Date Filter : {moment(startDate).format('DD MMM YYYY')} - {moment(endDate).format('DD MMM YYYY')}
                        </Button>
                    </Popover>
                )}
            </div>
            {radioButton === 'overview' && <Overview startDate={startDate} endDate={endDate} />}
            {radioButton === 'income' && <Income />}
            {radioButton === 'profit_overview' && <ProfitOverview startDate={startDate} endDate={endDate} />}
            {radioButton === 'finance_report' && <Finance />}
            {radioButton === 'operasional_report' && <OperasionalReport />}
            {radioButton === 'ai_dashboard' && <AiDashboard/>}
        </div>
    );
}

export default Index;