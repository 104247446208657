import React, { useEffect } from 'react';
import ReactApexChart from "react-apexcharts";
import { Typography, Card } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import lineChart from "../../../components/chart/configs/lineChart";
import { format } from 'date-fns';
import { formatCurrency } from '../../../helper/formatCurrency';


function Expense(props) {

    const { data } = props;
    const { Title, Paragraph } = Typography;

    const lineChart = {
        series: [
          {
            name: "Expense",
            data: data?.graph_expense?.data,
            offsetY: 0,
          }
        ],
      
        options: {
          chart: {
            width: "100%",
            height: 350,
            type: "area",
            toolbar: {
              show: false,
            },
          },
      
          legend: {
            show: false,
          },
      
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
      
          yaxis: {
            labels: {
              style: {
                fontSize: "14px",
                fontWeight: 600,
                colors: ["#8c8c8c"],
              },
              formatter: function (val) {
                return formatCurrency(parseInt(val));
            },
            },
          },
      
          xaxis: {
            labels: {
              style: {
                fontSize: "14px",
                fontWeight: 600,
                colors: "#8c8c8c",
              },
            },
            categories: data?.graph_expense?.labels,
          },
      
          tooltip: {
            y: {
              formatter: function (val) {
                return formatCurrency(parseInt(val));
            },
            },
          },
        },
      };

    return (
        <div>
            <Card>
                <div className="linechart">
                    <div>
                        <Title level={5}>Expense</Title>
                        <Paragraph className="lastweek">
                            than last week <span className="bnb2">-%</span>
                        </Paragraph>
                    </div>
                </div>

                <ReactApexChart
                    className="full-width"
                    options={lineChart.options}
                    series={lineChart.series}
                    type="area"
                    height={350}
                    width={"100%"}
                />

                <div className='grid grid-cols-2 gap-4 p-4'>
                    <div className='col-span-1 flex justify-center flex-col items-center'>
                        <h1 className="font-bold text-lg">{formatCurrency(parseInt(data?.average_overview?.averageExpensePerDay))}</h1>
                        <p className="text-sm">Avg. Expense/day</p>
                    </div>
                    <div className='col-span-1 flex justify-center flex-col items-center'>
                        <h1 className="font-bold text-lg">{formatCurrency(parseInt(data?.average_overview?.averageExpensePerWeek))}</h1>
                        <p className="text-sm">Avg. Expense/week</p>
                    </div>
                    {/* <div className='col-span-1'>
                        <h1 className="font-bold text-lg">Rp 1.000.000</h1>
                        <p className="text-sm">Avg. Expense/person</p>
                    </div>
                    <div className='col-span-1'>
                        <h1 className="font-bold text-lg">Rp 1.000.000</h1>
                        <p className="text-sm">Avg. Expense/month</p>
                    </div> */}
                </div>

            </Card>
        </div>
    );
}

export default Expense;