export const statusColors = {
  'Baru': 'blue',
  'Berhasil': 'green',
  'Tidak Berminat': 'red',
  'Tidak Respon': 'orange'
};

export const sourceOptions = [
  { label: 'Iklan', value: 'Iklan' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Google', value: 'Google' },
  { label: 'Tiktok', value: 'Tiktok' },
  { label: 'Teman', value: 'Teman' },
  { label: 'Lainnya', value: 'Lainnya' }
];

export const followUpStatuses = [
  { label: 'Baru', value: 'Baru' },
  { label: 'Berhasil', value: 'Berhasil' },
  { label: 'Tidak Berminat', value: 'Tidak Berminat' },
  { label: 'Tidak Respon', value: 'Tidak Respon' }
];

export const MAX_FOLLOW_UP = 7; 