import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, Modal } from 'antd';
import { API_URL, postData } from '../../helper/api';

const { Dragger } = Upload;

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const UploadPhotoEvidence = ({ value, onChange }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState(
        value ? [{
            uid: '-1',
            name: value.split('/').pop(),
            status: 'done',
            url: value,
        }] : []
    );

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleOnChange = (info) => {
        const { status } = info.file;
        setFileList(info.fileList);

        if (status === 'done') {
            const uploadedUrl = `${API_URL}/${info.file.response.data[0].url_photo}`;
            onChange(uploadedUrl);
            message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        } else if (status === 'removed') {
            onChange(null);
            setFileList([]);
        }
    };

    const props = {
        name: 'photos',
        listType: "picture-card",
        multiple: false,
        maxCount: 1,
        fileList: fileList,
        customRequest: async ({ file, onSuccess, onError }) => {
            try {
                const formData = new FormData();
                formData.append('photos', file);

                const response = await postData('/uploads-evidence', formData);
                onSuccess(response.data, file);
            } catch (error) {
                console.error('Upload error:', error);
                onError(error);
            }
        },
        onPreview: handlePreview,
        onChange: handleOnChange,
    };

    return (
        <>
            <Dragger {...props} style={{ marginBottom: "20px" }}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Dragger>
            <Modal 
                open={previewOpen} 
                title={previewTitle} 
                footer={null} 
                onCancel={handleCancel}
            >
                <img 
                    alt="example" 
                    style={{ width: '100%' }} 
                    src={previewImage} 
                />
            </Modal>
        </>
    );
};

export default UploadPhotoEvidence;
