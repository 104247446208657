import React from 'react';
import { Row, Col, Statistic, Card, List, Tag, Typography, Timeline, Empty } from 'antd';
import { UserOutlined, ShoppingOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Title, Text } = Typography;

const CustomerPattern = ({ analysis }) => {
    if (!analysis) return null;

    const { customerType, visitPattern, recommendations, aiInsights } = analysis;

    // Fungsi untuk menentukan warna tag berdasarkan tipe customer
    const getTypeColor = (type) => {
        const colors = {
            'REGULAR_MONTHLY': 'green',
            'REGULAR_BIMONTHLY': 'cyan',
            'DISCOUNT_DRIVEN': 'orange',
            'IRREGULAR': 'red',
            'ONE_TIME': 'purple',
            'NEW_CUSTOMER': 'blue',
            'SEASONAL': 'gold'
        };
        return colors[type] || 'default';
    };

    // Fungsi untuk format currency
    const formatCurrency = (value) => {
        if (!value) return 'Rp 0';
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(value);
    };

    // CSS untuk styling HTML dari AI
    const aiStyles = {
        container: {
            fontSize: '14px',
            lineHeight: '1.8',
            color: 'rgba(0, 0, 0, 0.85)',
            padding: '16px'
        },
        'ai-analysis': {
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            padding: '20px'
        },
        'analysis-header': {
            marginBottom: '32px',
            borderBottom: '2px solid #f0f0f0',
            paddingBottom: '16px'
        },
        'analysis-header h3': {
            color: '#1890ff',
            fontSize: '20px',
            fontWeight: '600',
            marginBottom: '12px'
        },
        'analysis-intro': {
            fontSize: '15px',
            color: '#4a4a4a',
            fontStyle: 'italic'
        },
        'analysis-section': {
            marginBottom: '28px',
            padding: '16px',
            backgroundColor: '#fafafa',
            borderRadius: '6px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
        },
        'analysis-section h4': {
            color: '#2c3e50',
            fontSize: '16px',
            fontWeight: '500',
            marginBottom: '16px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
        },
        'analysis-section h4:before': {
            content: '""',
            display: 'inline-block',
            width: '4px',
            height: '16px',
            backgroundColor: '#1890ff',
            borderRadius: '2px',
            marginRight: '8px'
        },
        'recommendations': {
            listStyle: 'none',
            padding: '0',
            margin: '16px 0'
        },
        'recommendations li': {
            marginBottom: '12px',
            paddingLeft: '24px',
            position: 'relative'
        },
        'recommendations li:before': {
            content: '""',
            position: 'absolute',
            left: '0',
            top: '8px',
            width: '6px',
            height: '6px',
            backgroundColor: '#1890ff',
            borderRadius: '50%'
        },
        'strategy-points': {
            backgroundColor: '#f0f7ff',
            padding: '16px',
            borderRadius: '6px',
            marginTop: '16px'
        },
        'strategy-points p': {
            marginBottom: '12px'
        },
        'strategy-points strong': {
            color: '#1890ff'
        }
    };

    // Konversi object styles ke string CSS
    const generateStyleTag = () => {
        let styleString = '';
        Object.entries(aiStyles).forEach(([selector, styles]) => {
            if (selector === 'container') return; // Skip container style karena digunakan inline
            
            styleString += `.${selector} {`;
            Object.entries(styles).forEach(([property, value]) => {
                // Convert camelCase to kebab-case
                const cssProperty = property.replace(/([A-Z])/g, '-$1').toLowerCase();
                styleString += `${cssProperty}: ${value};`;
            });
            styleString += '}';
        });
        return styleString;
    };

    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col  span={8}>
                    <Card className='m-2'>
                        <Statistic
                            className='m-2'
                            title="Tipe Customer"
                            value={customerType || 'N/A'}
                            prefix={<UserOutlined />}
                        />
                        <Tag className='m-2' color={getTypeColor(customerType)} style={{ marginTop: 8 }}>
                            {customerType ? customerType.replace('_', ' ') : 'N/A'}
                        </Tag>
                    </Card>
                </Col>
                
                <Col span={8}>
                    <Card className='m-2'>
                        <Statistic
                            className='m-2'
                            title="Total Orders"
                            value={visitPattern?.totalOrders || 0}
                            prefix={<ShoppingOutlined />}
                        />
                        <Text className='m-2' type="secondary" style={{ marginTop: 8, display: 'block' }}>
                            Last Visit: {visitPattern?.lastVisit ? 
                                moment(visitPattern.lastVisit).format('DD MMMM YYYY') : 
                                'N/A'}
                        </Text>
                    </Card>
                </Col>

                <Col span={8}>
                    <Card className='m-2'>
                        <Statistic
                            className='m-2'
                            title="Average Spending"
                            value={formatCurrency(visitPattern?.averageSpending)}
                        />
                    </Card>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                <Col span={12}>
                    <Card className='m-3' title="Pola Kunjungan">
                        <Timeline className='m-3'>
                            <Timeline.Item>
                                <Text strong>Interval Rata-rata:</Text> {visitPattern?.averageInterval || 0} hari
                            </Timeline.Item>
                            <Timeline.Item>
                                <Text strong>Metode Pembayaran:</Text> {visitPattern?.preferredPaymentMethods || 'N/A'}
                            </Timeline.Item>
                            <Timeline.Item>
                                <Text strong>Respon Diskon:</Text> {
                                    visitPattern?.discountResponse?.respondToDiscount ? 'Positif' : 'Netral'
                                }
                            </Timeline.Item>
                        </Timeline>
                    </Card>
                </Col>

                <Col span={12}>
                    <Card className='m-3' title="Pola Musiman">
                        {visitPattern?.seasonalPattern ? (
                            <>
                                <div className='m-3' style={{ marginBottom: 16 }}>
                                    <Text strong>Status: </Text>
                                    <Tag color={visitPattern.seasonalPattern.hasSeasonalPattern ? 'green' : 'orange'}>
                                        {visitPattern.seasonalPattern.hasSeasonalPattern ? 
                                            'Memiliki Pola Musiman' : 
                                            'Tidak Ada Pola Musiman'}
                                    </Tag>
                                </div>
                                {visitPattern.seasonalPattern.hasSeasonalPattern && (
                                    <div className='m-3'>
                                        <Text strong>Bulan-bulan Ramai:</Text>
                                        <div style={{ marginTop: 8 }}>
                                            {visitPattern.seasonalPattern.highSeasonMonths?.map(month => (
                                                <Tag key={month} color="blue" style={{ margin: 4 }}>
                                                    {moment().month(parseInt(month) - 1).format('MMMM')}
                                                </Tag>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <Empty description="Data musiman tidak tersedia" />
                        )}
                    </Card>
                </Col>
            </Row>

            {recommendations && (
                <Card className='m-3' title="Rekomendasi" style={{ marginTop: 16 }}>
                    <Row className='m-3' gutter={[16, 16]}>
                        <Col span={12}>
                            <Title className='m-3' level={5}>Promosi yang Disarankan</Title>
                            <List
                            className='m-3'
                                size="small"
                                bordered
                                dataSource={recommendations.promotions || []}
                                renderItem={item => (
                                    <List.Item>
                                        <Text>{item}</Text>
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col span={12}>
                            <Title level={5}>Strategi</Title>
                            <Timeline>
                                <Timeline.Item>
                                    <Text strong>Retensi:</Text> {recommendations.retentionStrategy || 'N/A'}
                                </Timeline.Item>
                                <Timeline.Item>
                                    <Text strong>Komunikasi:</Text> {recommendations.communicationStrategy || 'N/A'}
                                </Timeline.Item>
                            </Timeline>
                        </Col>
                    </Row>
                </Card>
            )}

            {aiInsights && (
                <Card 
                    title="AI Insights" 
                    style={{ marginTop: 16 }}
                    bodyStyle={{ padding: 0 }}
                >
                    <style>{generateStyleTag()}</style>
                    <div style={aiStyles.container}>
                        <div dangerouslySetInnerHTML={{ __html: aiInsights }} />
                    </div>
                </Card>
            )}
        </div>
    );
};

export default CustomerPattern; 