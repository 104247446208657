import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { formatCurrency } from "../../helper/formatCurrency";
import { FaMoneyBillWave } from "react-icons/fa";
import { BsBank2 } from "react-icons/bs";
import { SiContactlesspayment } from "react-icons/si";
import moment from "moment";
import { fetchData } from "../../helper/api";
import { toast } from "react-toastify";
import { Card, Typography, Button, Modal, Input, Tabs, List, Avatar, Tag, Statistic, Spin } from "antd";
import Expenses from "./Expenses";
const { Title, Text } = Typography;

function ShiftDetail() {
    const { id } = useParams();
    const [dataShift, setDataShift] = useState(null);
    const [loading, setLoading] = useState(true);
    
    const PaymentMethodCard = ({ title, icon, amount, count, color }) => (
        <Card className="mb-4 hover:shadow-lg transition-shadow">
            <Statistic
                title={
                    <div className="flex items-center gap-2">
                        {icon}
                        <span>{title}</span>
                    </div>
                }
                value={formatCurrency(parseInt(amount))}
                suffix={<Tag color={color}>{count} transactions</Tag>}
            />
        </Card>
    );

    useEffect(() => {
        const fetchShiftData = async () => {
            try {
                setLoading(true);
                const response = await fetchData(`shifts/${id}`);
                setDataShift(response.data.data);
            } catch (error) {
                toast.error("Failed to fetch shift data");
                console.error("Error fetching shift data:", error);
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchShiftData();
        }
    }, [id]);

    const getAllTransactions = () => {
        if (!dataShift?.payment_methods) return [];
        
        const cashTransactions = dataShift.payment_methods.cash?.transactions || [];
        const bankTransactions = dataShift.payment_methods.bank_transfer?.transactions || [];
        const qrisTransactions = dataShift.payment_methods.qris?.transactions || [];
        
        return [...cashTransactions, ...bankTransactions, ...qrisTransactions]
            .sort((a, b) => new Date(b.time) - new Date(a.time));
    };

    const items = [
        {
            key: 'payments',
            label: 'Payments',
            children: (
                <div className="space-y-4">
                    <PaymentMethodCard
                        title="Cash"
                        icon={<FaMoneyBillWave className="text-green-500" />}
                        amount={dataShift?.payment_methods?.cash?.amount || 0}
                        count={dataShift?.payment_methods?.cash?.count || 0}
                        color="green"
                    />
                    <PaymentMethodCard
                        title="Bank Transfer"
                        icon={<BsBank2 className="text-blue-500" />}
                        amount={dataShift?.payment_methods?.bank_transfer?.amount || 0}
                        count={dataShift?.payment_methods?.bank_transfer?.count || 0}
                        color="blue"
                    />
                    <PaymentMethodCard
                        title="QRIS"
                        icon={<SiContactlesspayment className="text-purple-500" />}
                        amount={dataShift?.payment_methods?.qris?.amount || 0}
                        count={dataShift?.payment_methods?.qris?.count || 0}
                        color="purple"
                    />

                    <Card title="Transaction Details" className="mt-4">
                        <List
                            dataSource={getAllTransactions()}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar icon={
                                                item.method === 'cash' ? <FaMoneyBillWave /> :
                                                item.method === 'bank_transfer' ? <BsBank2 /> :
                                                <SiContactlesspayment />
                                            } />
                                        }
                                        title={item.customer}
                                        description={moment(item.time).format('HH:mm:ss')}
                                    />
                                    <div>
                                        <Tag color={
                                            item.method === 'cash' ? 'green' :
                                            item.method === 'bank_transfer' ? 'blue' :
                                            'purple'
                                        }>
                                            {item.method}
                                        </Tag>
                                        <Text strong>{formatCurrency(parseInt(item.amount))}</Text>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </Card>
                </div>
            )
        },
        {
            key: 'summary',
            label: 'Summary',
            children: (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Card>
                        <Statistic title="Total Revenue" value={formatCurrency(parseInt(dataShift?.total_revenue))} />
                    </Card>
                    <Card>
                        <Statistic title="Total Expenses" value={formatCurrency(parseInt(dataShift?.total_expenses))} />
                    </Card>
                    <Card>
                        <Statistic title="Total Services" value={parseInt(dataShift?.service)} />
                    </Card>
                    <Card>
                        <Statistic title="Total Items" value={parseInt(dataShift?.item)} />
                    </Card>
                </div>
            )
        }
    ];

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Spin size="large" />
            </div>
        );
    }

    if (!dataShift) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Text>Shift data not found</Text>
            </div>
        );
    }

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-6">
                <div>
                    <Title level={4}>
                        Shift Detail - {moment(dataShift?.start_time).format('DD MMMM YYYY')}
                    </Title>
                    <div className="space-y-1">
                        <Text type="secondary" className="block">
                            Cashier: {dataShift?.employee_shift?.name}
                        </Text>
                        <Text type="secondary" className="block">
                            Time: {moment(dataShift?.start_time).format('HH:mm')} - 
                            {dataShift?.end_time ? moment(dataShift?.end_time).format('HH:mm') : 'Active'}
                        </Text>
                        <div className="flex gap-2 mt-2">
                            <Tag color={dataShift?.end_time ? 'green' : 'blue'}>
                                {dataShift?.end_time ? 'Completed' : 'Active'}
                            </Tag>
                            {dataShift?.difference !== 0 && (
                                <Tag color={dataShift?.difference > 0 ? 'green' : 'red'}>
                                    Difference: {formatCurrency(parseInt(dataShift?.difference))}
                                </Tag>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Tabs items={items} />

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
                <Card>
                    <Statistic 
                        title="Initial Cash" 
                        value={formatCurrency(parseInt(dataShift?.cash_initial))}
                        precision={0}
                    />
                </Card>
                <Card>
                    <Statistic 
                        title="Expected Cash" 
                        value={formatCurrency(parseInt(dataShift?.cash_expected + dataShift?.cash_initial))}
                        precision={0}
                    />
                </Card>
                {dataShift?.end_time && (
                    <Card>
                        <Statistic 
                            title="Actual Cash" 
                            value={formatCurrency(parseInt(dataShift?.cash_actual ))}
                            precision={0}
                            valueStyle={{ 
                                color: dataShift?.difference >= 0 ? '#3f8600' : '#cf1322'
                            }}
                        />
                    </Card>
                )}
            </div>
        </div>
    );
}

export default ShiftDetail; 