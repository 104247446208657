import React, { useState } from 'react';
import { FaRegCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';

const CopyButton = ({ textToCopy }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    // Membuat elemen textarea untuk menyimpan teks yang akan disalin
    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;

    // Menyembunyikan elemen textarea dari tampilan
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';

    // Menambahkan elemen textarea ke dalam DOM
    document.body.appendChild(textarea);

    // Memilih dan menyalin teks dari elemen textarea
    textarea.select();
    document.execCommand('copy');

    // Menghapus elemen textarea setelah penyalinan
    document.body.removeChild(textarea);

    // Mengatur status notifikasi dan mengembalikan ke false setelah beberapa detik
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 200);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FaRegCopy
        style={{ height: '20px', width: '40px', padding: '0px', cursor: 'pointer', scale: '0.8' }}
        onClick={handleCopyClick}
      />
      {isCopied && toast.info('Copied to clipboard')}
    </div>
  );
};

export default CopyButton;
