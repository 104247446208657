import React, { useState, useEffect } from 'react';
import { Card, Form, Select, Input, Button, Table, message, Empty, DatePicker } from 'antd';
import { fetchData, postData } from '../../helper/api';
import moment from 'moment';

const { TextArea } = Input;

function Permission() {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState([]);

    const fetchPermissions = () => {
        fetchData('/my-permissions')
            .then((res) => {
                setPermissions(res.data.data);
            })
            .catch((err) => {
                console.log(err);
                message.error('Gagal mengambil data izin');
            });
    };

    useEffect(() => {
        fetchPermissions();
    }, []);

    const handleSubmit = (values) => {
        setLoading(true);
        const payload = {
            ...values,
            date: moment().format('YYYY-MM-DD'),
            clock_in: moment().format('HH:mm:ss'),
            status: values.status
        };

        postData('/permissions', payload)
            .then((res) => {
                message.success('Berhasil mengajukan izin');
                form.resetFields();
                fetchPermissions();
            })
            .catch((err) => {
                console.log(err.response.data.error.message);
                const errorMessage = err.response.data.error.message || 'Gagal mengajukan izin';
                message.error(errorMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text) => moment(text).format('DD MMMM YYYY')
        },
        {
            title: 'Type Permission',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        }
    ];

    return (
        <div className="">
            <Card title="Submit Permission" className="mb-4">
                <Form
                    className='p-2'
                    form={form}
                    onFinish={handleSubmit}
                    layout="vertical"
                >
                    <Form.Item
                        name="status"
                        label="Type Permission"
                        rules={[{ required: true, message: 'Choose type permission!' }]}
                    >
                        <Select>
                            <Select.Option value="Sick">Sakit</Select.Option>
                            <Select.Option value="DayOffPersonal">Ambil Libur Mingguan</Select.Option>
                            <Select.Option value="DayOffGeneral">Cuti Bersama</Select.Option>
                            <Select.Option value="PersonalLeave">Izin Pribadi</Select.Option>
                            <Select.Option value="EmergencyLeave">Izin Darurat</Select.Option>
                            <Select.Option value="AnnualLeave">Cuti Tahunan</Select.Option>
                        </Select>
                    </Form.Item>

                    {/* Add Date */}
                    <Form.Item
                        name="date"
                        label="Date"
                        rules={[{ required: true, message: 'Choose date!' }]}
                    >
                        <DatePicker
                            value={moment()}
                            style={{ width: '100%' }}
                            format="DD MMMM YYYY"
                            defaultValue={moment()}
                            onChange={(date) => form.setFieldValue('date', date)}
                            disabledDate={(current) => current && current < moment().startOf('day')}
                        />
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label="Description"
                        rules={[{ required: true, message: 'Enter description!' }]}
                    >
                        <TextArea rows={4} placeholder="Enter description..." />
                    </Form.Item>

                    <Form.Item>
                        <Button className='bg-blue-400 text-white' htmlType="submit" loading={loading}>
                            Submit Permission
                        </Button>
                    </Form.Item>
                </Form>
            </Card>

            <Card title="History Permission">
                <Table
                    columns={columns}
                    dataSource={permissions}
                    rowKey="id"
                    locale={{
                        emptyText: <Empty description="No data permission" />
                    }}
                />
            </Card>
        </div>
    );
}

export default Permission;