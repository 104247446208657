import { formatCurrency } from "../../helper/formatCurrency";
import ActionDelivery from "./ActionDelivery";
import CardOrderList from "./CardOrderList";
import moment from "moment";

export const columnsDelivery = [
    {
        title: "Id",
        dataIndex: "id",
        key: "id",
    },
    {
        title: "Courier",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "Orders",
        dataIndex: "delivery_details",
        key: "delivery_details",
        render: (text, record) => (
            <div style={{ width: 'auto' }}>

            <CardOrderList record={record} />
            </div>
        ),
    },
    {
        title: "Delivery Date",
        dataIndex: "date",
        key: "date",
        render: (text, record) => (
            <div>{moment(record.date).format('DD MMM YYYY')}</div>
        ),
    },
    {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
    },
    {
        title: "Total Cost",
        dataIndex: "total_cost",
        key: "total_cost",
        render: (text, record) => (
            <div>{formatCurrency(parseInt(record.total_cost))}</div>
        ),
    },
    {
        title: "Total Distance",
        dataIndex: "total_distance",
        key: "total_distance",
    },
    {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (text, record) => (
            <ActionDelivery record={record} />
        ),  
    },
];


export const deliveryDataToDataSourceList = (data) => {
    return data.map((item, index) => {
        return {
            key: index + 1,
            id: item.id,
            name: item.user.name,
            delivery_details: item.delivery_details,
            date: item.date,
            quantity: item.quantity,
            total_cost: item.total_cost,
            total_distance: item.total_distance,
            users_id: item.users_id,
        };
    });
}

export const SELECT_DELIVERY_OPTION = {
    DELIVERY: 'Delivery',
    DELIVERY_DETAIL: 'Delivery_Detail',
}
export const SELECT_DELIVERY_DETAIL_OPTION = {
    INDEX: 'Index',
    EDIT: 'Edit',
    ADD: 'Add',
}

export const DeliveryTypeOption = [
    {value: 'Delivery', label: 'Delivery'},
    {value: 'Pickup', label: 'Pickup'},
]