import { Button, Link } from "antd";
import ActionSalesOrder from "./ActionSalesOrder";
import * as Yup from "yup";
import { PaymentMethodDisplay, PaymentStatusDisplay } from "../../models/orderData";
import dayjs from "dayjs";
import { formatCurrency } from "../../helper/formatCurrency";
import CardItemService from "./CardItemService";
import CustomerInfo from "../component/CustomerInfo";


export const TRANSACTION_RADIO_OPTION = {
    TRANSACTION: "transaction",
    TRANSACTION_DETAIL: "transaction_detail",
    DELIVERY: "delivery",
    PAYMENT: "payment",
}

export const TRANSACTION_DETAIL_OPTION = {
    ITEMS: "items",
    ADD_ITEM: "add_item",
    EDIT_ITEM: "edit_item",
}

export const TRANSACTION_PAYMENT_OPTION = {
    PAID: "paid",
    DP: "dp",
    UNPAID: "unpaid",
}

// Helper function untuk menentukan warna progress bar
export const getProgressColor = (percentage) => {
    if (percentage === 0) return 'bg-blue-500 w-[5%] opacity-40'; // Minimum width dengan opacity
    if (percentage < 25) return 'bg-blue-500';
    if (percentage < 50) return 'bg-blue-500';
    if (percentage < 75) return 'bg-blue-500';
    if (percentage < 100) return 'bg-blue-500';
    return 'bg-green-500'; // 100% complete
};

export const getStatusBackgroundColor = (status, isOverdue) => {
    if (isOverdue && status !== 'cancelled' && status !== 'done') return 'bg-red-200';
    switch (status) {
        case 'done':
            return 'bg-green-200';
        case 'siap':
            return 'bg-yellow-50';
        case 'on_progress':
            return 'bg-blue-50';
        case 'pengeringan':
            return 'bg-orange-50';
        case 'cancelled':
            return 'bg-gray-50';
        default:
            return 'bg-white';
    }
};

export const getStatusBadgeStyle = (status) => {
    switch (status) {
        case 'done':
            return 'bg-green-100 text-green-700';
        case 'siap':
            return 'bg-yellow-100 text-yellow-700';
        case 'on_progress':
            return 'bg-blue-100 text-blue-700';
        case 'pengeringan':
            return 'bg-orange-100 text-orange-700';
        case 'cancelled':
            return 'bg-gray-100 text-gray-700';
        default:
            return 'bg-gray-100 text-gray-700';
    }
};

export const getStatusRadioStyle = (status) => {
    switch (status) {
        case 'done':
            return 'bg-green-100 text-green-700';
        case 'siap':
            return 'bg-yellow-100 text-yellow-700';
        case 'on_progress':
            return 'bg-blue-100 text-blue-700';
        case 'pengeringan':
            return 'bg-orange-100 text-orange-700';
        case 'cancelled':
            return 'bg-red-100 text-red-700';
        default:
            return 'bg-gray-100 text-gray-700';
    }
};

// Helper function untuk style status
export const getStatusStyle = (status) => {
    const styles = {
        'not_yet': {
            default: 'border border-gray-200 hover:bg-gray-50 text-gray-700',
            selected: 'bg-gray-100 border-2 border-gray-400 text-gray-800'
        },
        'on_progress': {
            default: 'border border-yellow-200 hover:bg-yellow-50 text-yellow-700',
            selected: 'bg-yellow-100 border-2 border-yellow-400 text-yellow-800'
        },
        'done': {
            default: 'border border-green-200 hover:bg-green-50 text-green-700',
            selected: 'bg-green-100 border-2 border-green-400 text-green-800'
        }
    };
    return styles[status] || styles['not_yet'];
};

// Helper function untuk icon status
export const getStatusIcon = (status) => {
    switch (status) {
        case 'not_yet':
            return <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>;
        case 'on_progress':
            return <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>;
        case 'done':
            return <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>;
        default:
            return null;
    }
};


export const salesOrderData = [];

export const columnsSalesOrder = [
    {
        title: "Id",
        dataIndex: "id",
        key: "id",
    },

    {
        title: "Invoice",
        dataIndex: "code",
        key: "code",
        render: (text, record) => (
            <>
                <p className="font-bold text-lg">{text}</p>
                <p className="text-xs text-black">Created by {record.order_details[0]?.employee?.name}</p>
            </>
        ),
    },
    {
        title: "Customer",
        dataIndex: "customer_name",
        key: "customer_name",
        render: (text, record) => (
            <>
                <CustomerInfo record={record} />
            </>
        ),
    },
    {
        title: "Item",
        dataIndex: "service",
        key: "service",
        render: (text, record) => (
            <div style={{ width: 'auto', minWidth: '440px' }}>
                <CardItemService record={record} />
            </div>
        ),
    },
    {
        title: "Entry Date",
        dataIndex: "entry_date",
        key: "entry_date",
        render: (text, record) => (
            <label>{dayjs(record.entry_date).format("DD MMM YYYY")}</label>
        ),
    },
    {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
    },
    {
        title: "Total Final Price",
        dataIndex: "final_price",
        key: "final_price",
        render: (text, record) => (
            <label>{formatCurrency(parseInt(record.payment.final_price))}</label>
        ),
    },
    {
        title: "Paid Amount",
        dataIndex: "paid_amount",
        key: "paid_amount",
        render: (text, record) => (
            <label>{formatCurrency(parseInt(record.payment.paid_amount))}</label>
        ),
    },
    {
        title: "Payment Status",
        dataIndex: "payment_status",
        key: "payment_status",
        render: (text, record) => (
            <label>{PaymentStatusDisplay(record.payment.status)}</label>
        ),
    },
    {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (text, record) => (
            <ActionSalesOrder text={text} record={record} />
        ),
    },
];

export const orderDataToDataSourcesSalesOrder = (data) => {
    return data.map((item, index) => {
        return {
            ...item,
            key: index + 1,
            id: item.id,
            code: item.payment.code,
            customer_name: item.customer.name,
            service: item.order_details,
            entry_date: item.entry_date,
            due_date: item.due_date,
            quantity: item.quantity,
            total_price: parseInt(item.payment.total_price) + parseInt(item.payment.total_discount),
            paid_amount: item.payment.paid_amount,
            payment_status: item.payment.status,
            // total_price: formatCurrency(parseInt(item.payment.total_price)),
            // payment_method: PaymentMethodDisplay(item.payment.method),
            // payment_status: PaymentStatusDisplay(item.payment.status),
        };
    });
}

export const AddOrderDetailColumns = [
    {
        title: "No",
        dataIndex: "id",
        key: "id",
    },
    {
        title: "Item",
        dataIndex: "item",
        key: "item",
    },
    {
        title: "Treatment",
        dataIndex: "treatment",
        key: "treatment",
    },
    {
        title: "Employee",
        dataIndex: "employee",
        key: "employee",
    },
    {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (text, record) => (
            <ActionSalesOrder text={text} record={record} />
        ),
    },
];

export const optionsInvoice = {
    // default is `save`
    method: 'open',
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: 3,
    page: {
        // margin is in MM, default is Margin.NONE = 0
        margin: 0,
        // default is 'A4'
        format: 'A4',
        // default is 'portrait'
        orientation: 'potrait',
    },
    canvas: {
        // default is 'image/jpeg' for better size performance
        mimeType: 'image/png',
        qualityRatio: 1
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break, 
    // so use with caution.
    overrides: {
        // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
        pdf: {
            compress: true
        },
        // see https://html2canvas.hertzen.com/configuration for more options
        canvas: {
            useCORS: true
        }
    },
};

// Skema validasi Yup
export const validationSchema = Yup.object().shape({
    customer_id: Yup.string().required('Customer is required'),
    // branch_id: Yup.string().required('Branch is required'),
    entry_date: Yup.date().required('Entry Date is required'),
    quantity: Yup.number().required('Quantity is required'),
    payment: Yup.object().shape({
        code: Yup.string().required('Payment Code is required'),
        status: Yup.string().required('Payment Status is required'),
        total_discount: Yup.number().required('Total Discount is required'),
        total_price: Yup.number().required('Total Price is required'),
        date: Yup.date().when('status', {
            is: 'paid',
            then: Yup.date().required('Payment Date is required'),
        }),
        method: Yup.string().when('status', {
            is: 'paid',
            then: Yup.string().required('Payment Method is required'),
        }),
        money: Yup.number().when('status', {
            is: 'paid',
            then: Yup.number().required('Money is required'),
        }),
        change: Yup.number().when('status', {
            is: 'paid',
            then: Yup.number().required('Change is required'),
        }),
    }),
    order_details: Yup.array().of(
        Yup.object().shape({
            id: Yup.string().required('Order Detail ID is required'),
            employee_id: Yup.string().required('Employee ID is required'),
            treatment_id: Yup.string().required('Treatment ID is required'),
            item_id: Yup.string().required('Item ID is required'),
            discount_id: Yup.string().required('Discount ID is required'),
            price: Yup.number().required('Price is required'),
            final_price: Yup.number().required('Final Price is required'),
            due_date: Yup.date().required('Due Date is required'),
            status: Yup.string().required('Status is required'),
            description: Yup.string().required('Description is required'),
            shoes_photos: Yup.array().of(
                Yup.object().shape({
                    url_photo: Yup.string().required('Photo is required'),
                })
            ),
        })
    ),
});


export const DisplayProcessTime = (time) => {
    switch (time) {
        case 'regular':
            return 'Regular'
        case 'next_day':
            return 'Next Day'
        case 'same_day':
            return 'Same Day'
        default:
            return 'Regular'
    }
}



export const updatePayment = (formikValuesPayment, formikValuesOrderDetail) => {
    // Hitung total discount
    const calculateDiscount = formikValuesOrderDetail.reduce((total, item) => {
        const discount = item.discount?.discount || 0;
        if (item.discount?.type === 'percent') {
            return total + ((item.price || 0) * discount / 100);
        }
        return total + discount;
    }, 0);

    // Hitung total final price dari order details
    const calculateFinalPriceOrderDetail = formikValuesOrderDetail.reduce((total, item) => {
        return total + (parseFloat(item.final_price) || 0);
    }, 0);

    // Hitung total price dari order details
    const calculatePriceOrderDetail = formikValuesOrderDetail.reduce((total, item) => {
        return total + (parseFloat(item.price) || 0);
    }, 0);

    // Tambahkan logic untuk handle data lama yang belum memiliki payment_details
    let paymentDetails = (formikValuesPayment.payment_details || []);

    const money = parseFloat(formikValuesPayment.money)
    const final_price = parseFloat(formikValuesPayment.final_price)
    const amount = money >= final_price ? final_price : money
    
    // Jika payment details kosong tapi ada pembayaran (data lama)
    if (paymentDetails.length === 0 && formikValuesPayment.money > 0) {
        const oldPayment = {
            id: 0,
            transaction_id: formikValuesPayment.transaction_id || 0,
            payment_id: formikValuesPayment.id || 0,
            method: formikValuesPayment.method || 'cash',
            amount: amount,
            fee: 0,
            interest: 0,
            final_amount: parseFloat(formikValuesPayment.final_price) || 0,
            evidence_photo: formikValuesPayment.evidence_photo || null,
            account_id: formikValuesPayment.method === 'cash' ? 2 : // Office Cash
                       formikValuesPayment.method === 'qris' ? 3 : // QRIS Gateway
                       1, // BCA Account (default untuk bank_transfer)
            createdAt: formikValuesPayment.createdAt || new Date(),
            updatedAt: new Date(),
        };

        // Jika money >= final_price, status paid
        if (parseFloat(formikValuesPayment.money) >= parseFloat(formikValuesPayment.final_price)) {
            paymentDetails = [oldPayment];
        }
        // Jika 0 < money < final_price, status dp
        else if (parseFloat(formikValuesPayment.money) > 0) {
            paymentDetails = [oldPayment];
        }
        // Jika money = 0, tidak perlu tambah payment detail
    }

    // Format payment details dengan validasi
    const formattedPaymentDetails = paymentDetails.map(item => ({
        id: item.id || 0,
        transaction_id: item.transaction_id || 0,
        payment_id: item.payment_id || 0,
        method: item.method === 'bank' ? 'bank_transfer' : item.method,
        amount: parseFloat(item.amount) || 0,
        fee: parseFloat(item.fee) || 0,
        interest: parseFloat(item.interest) || 0,
        final_amount: parseFloat(item.amount || 0) + parseFloat(item.fee || 0) + parseFloat(item.interest || 0),
        evidence_photo: item.evidence_photo || null,
        account_id: item.account_id || null,
        createdAt: item.createdAt || null,
        updatedAt: item.updatedAt || null,
    }));

    // Hitung ulang total pembayaran setelah menambahkan data lama
    const recalculateTotalPaymentDetail = formattedPaymentDetails.reduce((total, item) => {
        return total + (parseFloat(item.final_amount) || 0);
    }, 0);

    // Update status pembayaran berdasarkan perhitungan ulang
    const statusPayment = recalculateTotalPaymentDetail >= calculateFinalPriceOrderDetail 
        ? 'paid' 
        : recalculateTotalPaymentDetail === 0 
            ? 'unpaid' 
            : 'dp';


    // Hitung total process time cost
    const totalProcessTimeCost = formikValuesOrderDetail.reduce((total, item) => {
        return total + (parseFloat(item.process_time_cost) || 0);
    }, 0);

    // Update payment object
    const updatedPayment = {
        ...formikValuesPayment,
        id: formikValuesPayment.id,
        code: formikValuesPayment.code || '',
        status: statusPayment || 'unpaid',
        total_process_time_cost: totalProcessTimeCost,
        total_discount: parseFloat(calculateDiscount),
        total_service: formikValuesOrderDetail.length,
        paid_amount: recalculateTotalPaymentDetail,
        remaining_amount: Math.max(0, calculateFinalPriceOrderDetail - recalculateTotalPaymentDetail),
        additional_cost: parseFloat(formikValuesPayment.additional_cost) || 0,
        due_date: formikValuesPayment.due_date || null,
        notes: formikValuesPayment.notes || null,
        final_price: calculateFinalPriceOrderDetail,
        payment_details: formattedPaymentDetails,
        total_price: calculatePriceOrderDetail,
        date: formikValuesPayment.date || null,
        method: formikValuesPayment.method || 'unpaid',
        money: parseFloat(formikValuesPayment.money) || 0,
        change: parseFloat(formikValuesPayment.change) || 0,
    };

    return updatedPayment;
};
