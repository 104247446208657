
export const TRANSACTION_PAYMENT_OPTION = {
    PAID: "paid",
    UNPAID: "unpaid",
}

export const PostMappingProductTransaction = (data) => {
    return {
        customer_id: data.customer_id,
        branch_id: data.branch_id,
        payment_id: 0,
        discount_id: data.discount_id,
        date: data.date,
        total_price: data.total_price,
        payment: {
            id: 0,
            code: "",
            status: data.payment.status,
            method: data.payment.method,
            date: data.payment.date,
            total_process_time_cost: 0,
            total_price: data.payment.total_price,
            total_discount: data.payment.total_discount,
            final_price: data.payment.final_price,
            change: data.payment.change,
            money: data.payment.money,
            evidence_photo: data.payment.evidence_photo,
        },
        order_product_details: data.order_product_details.map((item) => {
            return {
                id: 0,
                order_product_id: 0,
                product_id: item.product_id,
                discount_id: null,
                quantity: item.quantity,
                total_price_per_category: item.total_price_per_category,
                date: data.date
            }
        })
    }
}

export const UpdateMappingProductTransaction = (data) => {
    return {
        id: data.id,
        customer_id: data.customer_id,
        branch_id: data.branch_id,
        payment_id: data.payment_id,
        discount_id: data.discount_id,
        date: data.date,
        total_price: data.total_price,
        payment: {
            id: data.payment.id,
            code: data.payment.code,
            status: data.payment.status,
            method: data.payment.method,
            date: data.payment.date,
            total_process_time_cost: 0,
            total_price: data.payment.total_price,
            total_discount: data.payment.total_discount,
            final_price: data.payment.final_price,
            change: data.payment.change,
            money: data.payment.money,
            evidence_photo: data.payment.evidence_photo,
        },
        order_product_details: data.order_product_details.map((item) => {
            return {
                id: item.id,
                order_product_id: item.order_product_id,
                product_id: item.product_id,
                discount_id: null,
                quantity: item.quantity,
                total_price_per_category: item.total_price_per_category,
                date: data.date
            }
        })
    }
}
