import Routes from "./route";
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { GeneralProvider } from './contexts/GeneralContext';


function App() {

  return (
    <div className="App">
      <ToastContainer limit={3} autoClose={3000} />
      <GeneralProvider>
        <Routes />
      </GeneralProvider>
    </div>
  );
}

export default App;