import React, { useEffect } from 'react';
import { Form, Input, Button, Select, Upload, DatePicker } from 'antd';
import { getStatusIcon, getStatusStyle, TRANSACTION_DETAIL_OPTION } from './helper';
import ModalAddItem from '../Item/ModalAddItem';
import { WashingStatusOption, fileList } from '../../models/orderData';
import { UploadOutlined } from '@ant-design/icons';
import UploadItem from './UploadItem';
import { fetchData } from '../../helper/api';
import { formatCurrency, formatNumber } from '../../helper/formatCurrency';
import { calculateDiscount, calculateProcessTimeCost, calculateTotalPrice } from '../../helper/Calculation';
import UploadDraggerItem from './UploadDraggerItem';
import moment from 'moment';
import { PROCESS_TIME_OPTIONS } from '../../models/itemData';
import { FaExclamationCircle, FaInfoCircle, FaPencilAlt, FaStickyNote, FaUserTie } from 'react-icons/fa';
import { useTransaction } from './contexts/TransactionContext';

const { Option } = Select;

function AddItem(props) {
    const { formik, index, selectTransactionDetailOption, setSelectTransactionDetailOption, initialData } = props;

    const {
        itemData,
        OptionTreatment,
        OptionItem,
        OptionDiscount,
    } = useTransaction();

    const [ModalOpenItem, setModalOpenItem] = React.useState(false);
    const [ModalOpenDelivery, setModalOpenDelivery] = React.useState(false);


    // Treatment
    const [treatmentDataOption, setTreatmentDataOption] = React.useState(OptionTreatment);

    // Item
    const [itemDataOption, setItemDataOption] = React.useState(OptionItem);
    const [listDataItem, setListDataItem] = React.useState(itemData);

    // Discount
    const [discountDataOption, setDiscountDataOption] = React.useState(OptionDiscount);
    const [discount, setDiscount] = React.useState(0);
    const [price, setPrice] = React.useState(0);

    // Process Time
    const [processTimeDataOption, setProcessTimeDataOption] = React.useState(PROCESS_TIME_OPTIONS);
    const [selectProcessTime, setSelectProcessTime] = React.useState(PROCESS_TIME_OPTIONS[0]);
    const [finalPrice, setFinalPrice] = React.useState(0);


    useEffect(() => {
        setItemDataOption(OptionItem);
    }, [OptionItem]);


    useEffect(() => {
        formik.setFieldValue(`order_details[${index}].price`, parseInt(formik.values.order_details[index].treatment?.price) || 0);
    }, [formik.values.order_details[index].treatment_id, formik.values.order_details[index].discount_id]);


    const FinalPriceCalculation = () => {
        const price = parseInt(formik.values.order_details[index].treatment?.price) || 0;
        const processTimeCost = calculateProcessTimeCost(price, formik.values.order_details[index]?.process_time)
        const discount = calculateDiscount(formik.values.order_details[index], processTimeCost) || 0;
        const finalPrice = price - discount + processTimeCost;

        formik.setFieldValue(`order_details[${index}].final_price`, finalPrice);
        formik.setFieldValue(`order_details[${index}].process_time_cost`, processTimeCost);
    }

    useEffect(() => {
        setFinalPrice(formik.values.order_details[index].final_price);
        FinalPriceCalculation();
    }, [formik.values.order_details[index].process_time, formik.values.order_details[index].discount_id, formik.values.order_details[index].treatment_id]);

    const handleDelete = (index) => {
        if (selectTransactionDetailOption === TRANSACTION_DETAIL_OPTION.EDIT_ITEM) {
            setSelectTransactionDetailOption(TRANSACTION_DETAIL_OPTION.ITEMS);
            return;
        }
        const values = [...formik.values.order_details];
        values.splice(index, 1);
        formik.setFieldValue('order_details', values);
        setSelectTransactionDetailOption(TRANSACTION_DETAIL_OPTION.ITEMS);
    }

    const updatePayment = () => {
        const finalPrice = formik.values.order_details.reduce((total, item) => {
            return total + parseFloat(item.final_price);
        }, 0);
        const paidAmount = formik.values.payment.payment_details.reduce((total, detail) => {
            return total + parseFloat(detail.final_amount);
        }, 0);
        const remainingAmount = finalPrice - paidAmount;
        const status = remainingAmount === 0 ? 'paid' : remainingAmount > 0 && remainingAmount < finalPrice ? 'dp' : 'unpaid';
        const method = formik.values.payment.payment_details.map(detail => detail.method).join(', ');

        formik.setFieldValue('payment.final_price', finalPrice);
        formik.setFieldValue('payment.remaining_amount', remainingAmount);
        formik.setFieldValue('payment.status', status);
        formik.setFieldValue('payment.method', method);
    }

    return (
        <div>
            <Form>
                <div style={{ marginBottom: '10px' }}>
                    <label style={{ fontWeight: 'bold' }}>Item {index + 1}</label>
                </div>

                {formik.values.id > 1 && (
                    <>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].url_photo && 'error'}>
                            <div><label>Upload Photo : </label></div>
                            <UploadDraggerItem formik={formik} index={index} />
                        </Form.Item>
                        <Form.Item
                            validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].description && 'error'}
                            className="space-y-2"
                        >
                            <div className="flex items-center gap-2 mb-1">
                                <FaStickyNote className="text-yellow-500" />
                                <label className="font-medium text-gray-700">Special Notes</label>
                            </div>

                            <div className="relative">
                                <Input.TextArea
                                    placeholder="Contoh: Fokus bagian sol, ada noda di bagian depan, dll."
                                    value={formik.values.order_details[index].description}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        formik.setFieldValue(`order_details[${index}].description`, value);
                                    }}
                                    className="pl-10 min-h-[80px] text-gray-700 border-2 hover:border-yellow-300 focus:border-yellow-400 rounded-lg"
                                    maxLength={200}
                                    showCount
                                    autoSize={{ minRows: 4, maxRows: 4 }}
                                />
                                <div className="absolute left-3 top-3 text-yellow-500 opacity-50">
                                    <FaPencilAlt size={16} />
                                </div>
                            </div>

                            {/* Quick Notes Suggestions */}
                            <div className="flex flex-wrap gap-2 mt-2 mb-2">
                                {[
                                    'Kotor',
                                    'Sepatu Kondisi Aman',
                                    'Ada lem yang lepas',
                                    'Hilangkan noda',
                                    'Hilangkan bau',
                                    'Minta Selesai Besok',
                                ].map((suggestion) => (
                                    <button
                                        key={suggestion}
                                        type="button"
                                        onClick={() => {
                                            const currentValue = formik.values.order_details[index].description || '';
                                            const newValue = currentValue ? `${currentValue}, ${suggestion}` : suggestion;
                                            formik.setFieldValue(`order_details[${index}].description`, newValue);
                                        }}
                                        className="px-3 py-1 text-xs rounded-full bg-yellow-50 text-yellow-700 hover:bg-yellow-100 border border-yellow-200 transition-colors duration-200"
                                    >
                                        + {suggestion}
                                    </button>
                                ))}
                            </div>

                            {/* Error Message */}
                            {formik.errors.order_details &&
                                formik.errors.order_details[index] &&
                                formik.errors.order_details[index].description && (
                                    <div className="flex items-center gap-1 text-red-500 text-sm mt-1">
                                        <FaExclamationCircle className="text-xs" />
                                        <span>{formik.errors.order_details[index].description}</span>
                                    </div>
                                )}

                            {/* Helper Text */}
                            <div className="flex items-center gap-1 text-gray-400 text-xs mt-1">
                                <FaInfoCircle className="text-xs" />
                                <span>Tambahkan catatan khusus untuk treatment ini</span>
                            </div>
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].treatment_id && 'error'}>
                            <div><label>Treatment : </label></div>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a treatment"
                                optionFilterProp="children"
                                value={formik.values.order_details[index].treatment_id}
                                onChange={(value, option) => {
                                    formik.setFieldValue(`order_details[${index}].treatment_id`, value)
                                    formik.setFieldValue(`order_details[${index}].treatment`, option.item)
                                }}
                            >
                                {treatmentDataOption.map((item) => (
                                    <Option key={item.value} item={item} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].item_id && 'error'}>
                            <div><label>Item : </label></div>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a item"
                                optionFilterProp="children"
                                value={formik.values.order_details[index].item_id}
                                onChange={(value, option) => {
                                    formik.setFieldValue(`order_details[${index}].item_id`, value)
                                    formik.setFieldValue(`order_details[${index}].item`, option.item)
                                }}
                            >
                                {itemDataOption.map((item) => (
                                    <Option key={item.value} item={item} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>

                            <Button type="link" onClick={() => setModalOpenItem(true)}>
                                New Item
                            </Button>
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].process_time && 'error'}>
                            <div className="mb-2"><label className="text-gray-700 font-medium">Process Time</label></div>
                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
                                {processTimeDataOption.map((item) => (
                                    <div
                                        key={item.value}
                                        onClick={() => {
                                            formik.setFieldValue(`order_details[${index}].process_time`, item.value);
                                            formik.setFieldValue(`order_details[${index}].process_time_cost`, item.percent * formik.values.order_details[index]?.treatment?.price);
                                        }}
                                        className={`cursor-pointer rounded-xl p-3 border-2 transition-all duration-200
                    ${formik.values.order_details[index].process_time === item.value
                                                ? 'border-blue-500 bg-blue-50'
                                                : 'border-gray-200 hover:border-blue-200 hover:bg-gray-50'
                                            }`}
                                    >
                                        <div className="flex items-center justify-between mb-1">
                                            <span className="text-sm font-medium text-gray-800">{item.label}</span>
                                            {formik.values.order_details[index].process_time === item.value && (
                                                <div className="w-4 h-4 rounded-full bg-blue-500 flex items-center justify-center">
                                                    <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                    </svg>
                                                </div>
                                            )}
                                        </div>
                                        <div className="text-xs text-gray-500">
                                            {item.percent * 100}% additional cost
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Form.Item>

                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].washing_status && 'error'}>
                            <div className="mb-2"><label className="text-gray-700 font-medium">Status</label></div>
                            <div className="flex flex-wrap gap-2">
                                {WashingStatusOption.map((item) => (
                                    <div
                                        key={item.value}
                                        onClick={() => formik.setFieldValue(`order_details[${index}].status`, item.value)}
                                        className={`cursor-pointer rounded-lg px-4 py-2 flex items-center gap-2 transition-all duration-200
                    ${formik.values.order_details[index].status === item.value
                                                ? getStatusStyle(item.value).selected
                                                : getStatusStyle(item.value).default
                                            }`}
                                    >
                                        {getStatusIcon(item.value)}
                                        <span className="text-sm font-medium">{item.label}</span>
                                    </div>
                                ))}
                            </div>
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].due_date && 'error'}>
                            <div><label>Due Date : </label></div>
                            <DatePicker
                                style={{ width: '100%' }}
                                format={"dddd, DD MMMM YYYY"}
                                value={formik.values.order_details[index].due_date ? moment(formik.values.order_details[index].due_date) : null}
                                onChange={(value) => { formik.setFieldValue(`order_details[${index}].due_date`, value) }}
                            />
                        </Form.Item>

                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].discount_id && 'error'}>
                            <div><label>Discount : </label></div>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a discount"
                                optionFilterProp="children"
                                value={formik.values.order_details[index].discount_id}
                                onChange={(value, option) => {
                                    formik.setFieldValue(`order_details[${index}].discount_id`, value || null)
                                    formik.setFieldValue(`order_details[${index}].discount`, option.item)
                                }}
                            >
                                {discountDataOption.map((item) => (
                                    <Option key={item.value} item={item} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].price && 'error'}>
                                <div className="bg-gray-50 rounded-xl p-4 border border-gray-200">
                                    <div className="flex items-center justify-between mb-1">
                                        <label className="text-sm text-gray-500">Base Price</label>
                                        <div className="flex items-center gap-1 text-xs bg-blue-50 text-blue-600 px-2 py-1 rounded-full">
                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                    d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                                            </svg>
                                            <span>Treatment Price</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <span className="text-2xl font-bold text-gray-800">
                                            {formatCurrency(parseInt(formik.values.order_details[index].treatment?.price || 0))}
                                        </span>
                                        <div className={`w-2 h-2 rounded-full ${formik.values.order_details[index].treatment?.price ? 'bg-green-500' : 'bg-gray-300'}`} />
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].final_price && 'error'}>
                                <div className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl p-4 border border-blue-200">
                                    <div className="flex items-center justify-between mb-1">
                                        <label className="text-sm text-gray-500">Final Price</label>
                                        <div className="flex items-center gap-1 text-xs bg-green-50 text-green-600 px-2 py-1 rounded-full">
                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <span>Total Amount</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <span className="text-2xl font-bold text-blue-800">
                                            {formatCurrency(parseInt(formik.values.order_details[index].final_price || 0))}
                                        </span>
                                        {formik.values.order_details[index].final_price > formik.values.order_details[index].treatment?.price && (
                                            <div className="flex items-center gap-1 text-xs text-green-600">
                                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                        d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                                                </svg>
                                                <span>Includes Process Time</span>
                                            </div>
                                        )}
                                    </div>
                                    {formik.values.order_details[index].process_time_cost > 0 && (
                                        <div className="mt-2 text-xs text-gray-500 flex items-center gap-1">
                                            <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            Additional: {formatCurrency(parseInt(formik.values.order_details[index].process_time_cost || 0))}
                                        </div>
                                    )}
                                </div>
                            </Form.Item>
                        </div>



                    </>
                )}

                {formik.values.id < 1 && (
                    <>
                        <p className='italic mb-3 text-red-600'>Wajib mengisi Treatment, Process Time, Discount</p>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].treatment_id && 'error'}>
                            <div><label>*Treatment : </label></div>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a treatment"
                                optionFilterProp="children"
                                value={formik.values.order_details[index].treatment_id}
                                onChange={(value, option) => {
                                    formik.setFieldValue(`order_details[${index}].treatment_id`, value)
                                    formik.setFieldValue(`order_details[${index}].treatment`, option.item)
                                }}
                            >
                                {treatmentDataOption.map((item) => (
                                    <Option key={item.value} item={item} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].process_time && 'error'}>
                            <div className="mb-2">
                                <label className="text-gray-700 font-medium flex items-center gap-1">
                                    Process Time
                                    <span className="text-red-500">*</span>
                                </label>
                            </div>
                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
                                {processTimeDataOption.map((item) => (
                                    <div
                                        key={item.value}
                                        onClick={() => {
                                            formik.setFieldValue(`order_details[${index}].process_time`, item.value);
                                            formik.setFieldValue(`order_details[${index}].process_time_cost`, item.percent * formik.values.order_details[index]?.treatment?.price);
                                        }}
                                        className={`cursor-pointer rounded-xl transition-all duration-200
                    ${formik.values.order_details[index].process_time === item.value
                                                ? 'bg-gradient-to-br from-blue-50 to-blue-100 border-2 border-blue-500'
                                                : 'bg-white border-2 border-gray-200 hover:border-blue-200 hover:bg-blue-50'
                                            }
                    ${!formik.values.order_details[index]?.treatment?.price ? 'opacity-50 cursor-not-allowed' : ''}
                `}
                                    >
                                        <div className="p-3">
                                            <div className="flex items-center justify-between mb-2">
                                                <span className="text-sm font-medium text-gray-800">{item.label}</span>
                                                {formik.values.order_details[index].process_time === item.value && (
                                                    <div className="w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center">
                                                        <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                        </svg>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex flex-col gap-1">
                                                <div className="text-xs text-gray-500">
                                                    Additional Cost: {item.percent * 100}%
                                                </div>
                                                {formik.values.order_details[index]?.treatment?.price && (
                                                    <div className="text-xs font-medium text-blue-600">
                                                        + Rp {formatNumber(item.percent * formik.values.order_details[index]?.treatment?.price)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {!formik.values.order_details[index]?.treatment?.price && (
                                            <div className="text-xs text-red-500 px-3 pb-2">
                                                Please select treatment first
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            {formik.errors.order_details && formik.errors.order_details[index]?.process_time && (
                                <div className="mt-1 text-xs text-red-500">
                                    {formik.errors.order_details[index].process_time}
                                </div>
                            )}
                        </Form.Item>
                        <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].discount_id && 'error'}>
                            <div><label>*Discount : </label></div>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select a discount"
                                optionFilterProp="children"
                                value={formik.values.order_details[index].discount_id}
                                onChange={(value, option) => {
                                    formik.setFieldValue(`order_details[${index}].discount_id`, value || null)
                                    formik.setFieldValue(`order_details[${index}].discount`, option.item)
                                }}
                            >
                                {discountDataOption.map((item) => (
                                    <Option key={item.value} item={item} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {/* <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].description && 'error'}>
                            <div><label>Notes (<span className='italic text-red-600'>Wajib diisi jika ada kerusakan</span>) : </label></div>
                            <Input.TextArea
                                type='text'
                                maxLength={200}
                                value={formik.values.order_details[index].description}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    formik.setFieldValue(`order_details[${index}].description`, value);
                                }}
                            />
                        </Form.Item> */}

                        <Form.Item
                            validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].description && 'error'}
                            className="space-y-2"
                        >
                            <div className="flex items-center gap-2 mb-1">
                                <FaStickyNote className="text-yellow-500" />
                                <label className="font-medium text-gray-700">Special Notes</label>
                            </div>

                            <div className="relative">
                                <Input.TextArea
                                    placeholder="Contoh: Fokus bagian sol, ada noda di bagian depan, dll."
                                    value={formik.values.order_details[index].description}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        formik.setFieldValue(`order_details[${index}].description`, value);
                                    }}
                                    className="pl-10 min-h-[80px] text-gray-700 border-2 hover:border-yellow-300 focus:border-yellow-400 rounded-lg"
                                    maxLength={200}
                                    showCount
                                    autoSize={{ minRows: 4, maxRows: 4 }}
                                />
                                <div className="absolute left-3 top-3 text-yellow-500 opacity-50">
                                    <FaPencilAlt size={16} />
                                </div>
                            </div>

                            {/* Quick Notes Suggestions */}
                            <div className="flex flex-wrap gap-2 mt-2 mb-2">
                                {[
                                    'Kotor',
                                    'Sepatu Kondisi Aman',
                                    'Ada lem yang lepas',
                                    'Hilangkan noda',
                                    'Hilangkan bau',
                                    'Minta Selesai Besok',
                                ].map((suggestion) => (
                                    <button
                                        key={suggestion}
                                        type="button"
                                        onClick={() => {
                                            const currentValue = formik.values.order_details[index].description || '';
                                            const newValue = currentValue ? `${currentValue}, ${suggestion}` : suggestion;
                                            formik.setFieldValue(`order_details[${index}].description`, newValue);
                                        }}
                                        className="px-3 py-1 text-xs rounded-full bg-yellow-50 text-yellow-700 hover:bg-yellow-100 border border-yellow-200 transition-colors duration-200"
                                    >
                                        + {suggestion}
                                    </button>
                                ))}
                            </div>

                            {/* Error Message */}
                            {formik.errors.order_details &&
                                formik.errors.order_details[index] &&
                                formik.errors.order_details[index].description && (
                                    <div className="flex items-center gap-1 text-red-500 text-sm mt-1">
                                        <FaExclamationCircle className="text-xs" />
                                        <span>{formik.errors.order_details[index].description}</span>
                                    </div>
                                )}

                            {/* Helper Text */}
                            <div className="flex items-center gap-1 text-gray-400 text-xs mt-1">
                                <FaInfoCircle className="text-xs" />
                                <span>Tambahkan catatan khusus untuk treatment ini</span>
                            </div>
                        </Form.Item>


                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].price && 'error'}>
                                <div className="bg-gray-50 rounded-xl p-4 border border-gray-200">
                                    <div className="flex items-center justify-between mb-1">
                                        <label className="text-sm text-gray-500">Base Price</label>
                                        <div className="flex items-center gap-1 text-xs bg-blue-50 text-blue-600 px-2 py-1 rounded-full">
                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                    d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                                            </svg>
                                            <span>Treatment Price</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <span className="text-2xl font-bold text-gray-800">
                                            {formatCurrency(parseInt(formik.values.order_details[index].treatment?.price || 0))}
                                        </span>
                                        <div className={`w-2 h-2 rounded-full ${formik.values.order_details[index].treatment?.price ? 'bg-green-500' : 'bg-gray-300'}`} />
                                    </div>
                                </div>
                            </Form.Item>

                            <Form.Item validateStatus={formik.errors.order_details && formik.errors.order_details[index] && formik.errors.order_details[index].final_price && 'error'}>
                                <div className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl p-4 border border-blue-200">
                                    <div className="flex items-center justify-between mb-1">
                                        <label className="text-sm text-gray-500">Final Price</label>
                                        <div className="flex items-center gap-1 text-xs bg-green-50 text-green-600 px-2 py-1 rounded-full">
                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <span>Total Amount</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <span className="text-2xl font-bold text-blue-800">
                                            {formatCurrency(parseInt(formik.values.order_details[index].final_price || 0))}
                                        </span>
                                        {formik.values.order_details[index].final_price > formik.values.order_details[index].treatment?.price && (
                                            <div className="flex items-center gap-1 text-xs text-green-600">
                                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                        d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                                                </svg>
                                                <span>Includes Process Time</span>
                                            </div>
                                        )}
                                    </div>
                                    {formik.values.order_details[index].process_time_cost > 0 && (
                                        <div className="mt-2 text-xs text-gray-500 flex items-center gap-1">
                                            <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            Additional: {formatCurrency(parseInt(formik.values.order_details[index].process_time_cost || 0))}
                                        </div>
                                    )}
                                </div>
                            </Form.Item>
                        </div>

                    </>
                )}
                <div style={{ marginBottom: '10px', display: 'flex', gap: "10px", justifyContent: "flex-end" }}>
                    <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => {
                        setSelectTransactionDetailOption(TRANSACTION_DETAIL_OPTION.ITEMS)
                        updatePayment()
                    }}>
                        Submit
                    </Button>
                    <Button type="dash" htmlType="button" className="login-form-button" onClick={() => handleDelete()}>
                        Cancel
                    </Button>
                </div>
            </Form >
            <ModalAddItem listData={listDataItem} open={ModalOpenItem} setOpen={setModalOpenItem} />
        </div >
    );
}

export default AddItem;