import React, { useEffect, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, Modal } from 'antd';
import { API_URL } from '../../helper/api'; // Sesuaikan dengan logika pengiriman data di aplikasi Anda
import { postData } from '../../helper/api'; // Sesuaikan dengan logika pengiriman data di aplikasi Anda

const { Dragger } = Upload;

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const UploadPhoto = ({ formik }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [FileList, setFileList] = useState(
        formik.values?.receipt ? [{
            uid: formik.values?.id,
            name: formik.values?.receipt,
            status: 'done',
            url: formik.values?.receipt,
        }] : []
    );
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleOnChange = (info) => {
        const { status } = info.file;

        if (status === 'done') {
            formik.setFieldValue(`receipt`,
                [
                    {
                        id: '0',
                        order_detail_id: '0',
                        url_photo: `${API_URL}/${info.file.response.data[0].url_photo}`,
                        uid: info.file.uid,
                        name: info.file.name,
                        status: info.file.status,
                        url: `${API_URL}/${info.file.response.data[0].url_photo}`
                    }
                ]);
            message.success(`${info.file.name} file uploaded successfully.`);

        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        } else if (status === 'removed') {
            formik.setFieldValue(`receipt`,
                ''
            );
        }
    };

    const props = {
        name: 'photos',
        listType: "picture-card",
        multiple: false,
        maxCount: 1, // Hanya satu file yang diizinkan
        defaultFileList: FileList,
        customRequest: async ({ file, onSuccess, onError }) => {
            try {
                // Menggunakan FormData untuk mengirim file dan informasi tambahan
                const formData = new FormData();
                formData.append('photos', file);

                // Kirim request ke server
                await postData('/uploads-expenses', formData)
                    .then((res) => {
                        // Panggil onSuccess jika request berhasil
                        onSuccess(res.data, file);
                    })
                    .catch((err) => {
                        // Panggil onError jika request gagal
                        onError(err);
                    });
            } catch (error) {
                console.error(error);
                onError(error); // Panggil onError jika terjadi kesalahan
            }
        },
        onPreview: handlePreview,
        onChange: handleOnChange,
        onDrop(e) {

        },
    };


    return (
        <>
            <Dragger {...props} style={{ marginBottom: "20px" }}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Dragger>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel} >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </>
    );
};

export default UploadPhoto;
